import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LawyerSearchRoutingModule } from './lawyer-search-routing.module';
import { LawyerSearchComponent } from './lawyer-search.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModuleModule } from '../shared-module/shared-module.module'

@NgModule({
  declarations: [LawyerSearchComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule, ReactiveFormsModule,
    NgxPaginationModule,
    LawyerSearchRoutingModule,
    SharedModuleModule
  ]
})
export class LawyerSearchModule { }
