import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../services/seo.service';
@Component({
  selector: 'lawyer-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.css']
})
export class BlogListComponent implements OnInit {

  constructor(private dataService: DataService, private title: Title, private meta: Meta, private seoService: SeoService) { }
  blogs;
  ngOnInit() {
    this.seoService.createLinkForCanonicalURL();
    this.title.setTitle("Blogs and Articles");
    this.meta.updateTag({ name: 'description', content: 'Keep your legal knowledge updated with the articles and blogs of Jurislex. Get all all the information and think wisely before making any legal decisions.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });
    this.dataService.getAllBlogs().subscribe((response: HttpResponse<any>) => {

      this.blogs = response.body.data;
      let blogByUser = [];
      for (var i in this.blogs) {
        if (this.blogs[i].status == 1) {
          blogByUser.push(this.blogs[i])
        }
      }
      this.blogs = blogByUser
    })
  }

}
