import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SeoService } from '../services/seo.service';
declare var hljs: any;

@Component({
  selector: 'lawyer-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
    public readonly siteKey = '6LfGxPEUAAAAAAHTrNk8UsRtdrGVzRY6AAIgKrNW';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  public useGlobalDomain: boolean = false;

  @ViewChild('captchaElem', { static: false }) captchaElem: any;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;
  constructor(private dataService: DataService, private toastrService: ToastrService, private currentRoute: ActivatedRoute, private router: Router, private title: Title, private meta: Meta, private seoService: SeoService) {
    this.currentRoute.params.subscribe(params => {

      if ((params['type']) && (params['type'] == 'Nominate a firm')) {
        this.con_type = 'Nominate a firm';
      }
      else {
        this.con_type = 'General enquiry';
      }

    });
  }
  // myContent;
  con_type = 'General enquiry';
  contactUsForm: FormGroup;
  submitted = false;
  user = null;
  create_form = true;
  localdata = null;
  countries = null;
  states = null;
  cities = null;
  contact_types = [
      { 'value': 'General enquiry' },
      { 'value': 'Legal problem' },
    { 'value': 'Nominate a firm' },
    { 'value': 'Looking for lawyer' },
    { 'value': 'Suggesitions' },
    { 'value': 'Complains' },
  ]
  ngOnInit() {
    this.createLinkForCanonicalURL();
    this.dataService.spinnerShow();
    this.title.setTitle("Contact Us | Jurislex");
    this.meta.updateTag({ name: 'description', content: 'Share your legal problems with us by filling up the form. Jurislex is here to help you with all your legal issues across countries. Contact now!' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal, Contact Form' });
    this.dataService.getCountries().subscribe((response: HttpResponse<any>) => {
      this.dataService.spinnerHide();
      this.countries = response.body.data;
    });

    this.localdata = JSON.parse(localStorage.getItem('websiteCurrentUser')) ? JSON.parse(localStorage.getItem('websiteCurrentUser')).data : null

    if (this.localdata != null) {
      this.create_form = false;
      this.dataService.getUser(this.localdata.id).subscribe((response: HttpResponse<any>) => {
        this.user = response.body.data[0];
        this.dataService.spinnerHide();
        this.create_form = true;
        this.createform()
      });
    }
    else {
      this.dataService.spinnerHide();
      this.createform()
    }

    // this.dataService.getContent().subscribe((response: HttpResponse<any>) => {
    //   console.log(response.body.data);
    //   this.myContent = response.body.data[3].description;      
    // });   

  }
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  } 

  createform() {

    this.contactUsForm = new FormGroup({

      fname: new FormControl(this.user ? this.user.name : null, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z]{3,20}")
      ])),
      lname: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z]{3,20}")
      ])),
      business_email: new FormControl(this.user ? this.user.email : null, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")
      ])),
      job_title: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
      company_name: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
        
        countrycode: new FormControl(null, Validators.compose([
            Validators.required,
            
        ])),
      phone: new FormControl(null, Validators.compose([
        Validators.required,
        
      ])),

      contact_type: new FormControl(this.con_type, Validators.compose([
        Validators.required
      ])),

      country: new FormControl(this.user ? this.user.country : null, Validators.compose([
        Validators.required
      ])),
      state: new FormControl(null, Validators.compose([])),
      city: new FormControl(null, Validators.compose([])),

      subject: new FormControl(null),

      recaptcha: new FormControl('', [Validators.required])


    });
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

  changeSize(size: 'compact' | 'normal'): void {
    this.size = size;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  setLanguage(): void {
    this.lang = this.langInput.nativeElement.value;
  }

  setUseGlobalDomain(use: boolean): void {
    this.useGlobalDomain = use;
  }

  getCurrentResponse(): void {
    const currentResponse = this.captchaElem.getCurrentResponse();
    if (!currentResponse) {
      alert('There is no current response - have you submitted captcha?');
    } else {
      alert(currentResponse);
    }
  }
  ngAfterViewInit(): void {
    this.highlight();
  }

  getResponse(): void {
    const response = this.captchaElem.getResponse();
    if (!response) {
      alert('There is no response - have you submitted captcha?');
    } else {
      alert(response);
    }
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }

  private highlight(): void {
    const highlightBlocks = document.getElementsByTagName('code');
    for (let i = 0; i < highlightBlocks.length; i++) {
      const block = highlightBlocks[i];
      hljs.highlightBlock(block);
    }
  }

  addcontactUs() {
    this.submitted = true;
    if (this.contactUsForm.invalid) {
      return;
    }

      this.dataService.spinnerShow();
      console.log('1');
    this.dataService.contactUs(this.contactUsForm.value).subscribe(
        (response: HttpResponse<any>) => {
            console.log('2');
        this.dataService.spinnerHide();
            if (response.body.status_code === 0) {
                console.log('3');
          // this.toastrService.success(response.body.message);
          swal.fire('Success!', response.body.message, 'success');
                this.router.navigate([``])
                console.log('4');
            } else {
                console.log('5');
          // this.toastrService.error(response.body.message);
          swal.fire('Error', response.body.message, 'error');
        }
        }, error => {
            console.log('6');
        // this.toastrService.error(error.error['message']); 
            this.dataService.spinnerHide();
        swal.fire('Error', error.error['message'], 'error');
      }
    );
  }

  onCountryChange(event, data) {
    console.log(data)
    this.dataService.spinnerShow();
    this.states = null;
    this.dataService
      .getStates(this.contactUsForm.value.country)
      .subscribe((response: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.states = response.body.data;
      });
  }

  onStateChange(event) {
    this.dataService.spinnerShow();
    this.cities = null;
    this.dataService
      .getCities(this.contactUsForm.value.state)
      .subscribe((response: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.cities = response.body.data;
      });
  }
}
