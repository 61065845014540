import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddCollegueComponent } from './add-collegue.component';

const routes: Routes = [
  { path: '', component: AddCollegueComponent }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AddCollegueRoutingModule { }
