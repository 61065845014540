import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'lawyer-add-collegue',
  templateUrl: './add-collegue.component.html',
  styleUrls: ['./add-collegue.component.css']
})
export class AddCollegueComponent implements OnInit {

  addOfficeForm: FormGroup;
  offices = null;
  submitted = false;
  specializations;
  areaRequired = false;
  imgRequired = false;
  user = null;
  img = null;
  max_lawyers: any;
  total_collegue: any;
  constructor(private dataService: DataService, private toastrService: ToastrService, private router: Router, private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.dataService.spinnerShow();
    this.createForm();
    const localData = JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data ? JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data : null;
    const user_id = localData.id;
    this.max_lawyers = localData.max_lawyers;

    this.dataService.getSpecializations().subscribe((response: HttpResponse<any>) => {
      this.specializations = response.body.data;
    });
    this.dataService.getOffices(user_id).subscribe(
      (response: HttpResponse<any>) => {
        this.offices = response.body.data;
      });

    this.dataService.getCollegueList(user_id).subscribe(
      (response1: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.total_collegue = response1.body.data.length;
        if (this.total_collegue >= this.max_lawyers) {
          this.router.navigate(['/'])
        }
      });

    this.title.setTitle("Jurislex - Add Collegue");
    this.meta.updateTag({ name: 'description', content: 'This page is for adding collegue to your firm.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal, add collegue' });


  }

  createForm() {
    this.addOfficeForm = new FormGroup({

      // user_id : new FormControl(null, Validators.compose([
      //                                           Validators.required,
      //                                         ])),
      pic_title: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
      pic_fname: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z]{3,20}")
      ])),
      pic_mname: new FormControl(null, Validators.compose([
        Validators.pattern("^[a-zA-Z]+")
      ])),
      pic_lname: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z]{3,20}")
      ])),
      phone: new FormControl(null, Validators.compose([
        Validators.required])),
        fax: new FormControl(null, Validators.compose([])),

        countrycode: new FormControl(null, Validators.compose([
            Validators.required])),
        faxcountrycode: new FormControl(null, Validators.compose([])),

        firm_website: new FormControl(null, Validators.compose([])),

      main_firm_email: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")
      ])
      ),

      

      pic_position: new FormControl(null, Validators.compose([
        
      ])
      ),
      office_id: new FormControl(null, Validators.compose([Validators.required,])),

      profile_pic: new FormControl(null),

      profile: new FormControl(null, Validators.compose([
        Validators.required,
      ])
      ),

      languages: new FormControl(null, Validators.compose([
        Validators.required,
      ])),

      year_joined: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern("^[0-9]{4}")
      ])),

      professional_experience: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.max(6000),
      ])),


      affiliations: new FormControl(null, Validators.compose([
        
        Validators.max(1500),
      ])),

      publications: new FormControl(null, Validators.compose([
        
        Validators.max(1500),
      ])),

      education: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.max(2500),
      ])),

      seminars: new FormControl(null, Validators.compose([
       
        Validators.max(1500),
      ])),

        facebook: new FormControl(null, Validators.compose([
            Validators.max(250),
        ])),

        linkedin: new FormControl(null, Validators.compose([
            Validators.max(250),
        ])),

        twitter: new FormControl(null, Validators.compose([
            Validators.max(200),
        ])),

        instagram: new FormControl(null, Validators.compose([

            Validators.max(250),
        ])),

        youtube: new FormControl(null, Validators.compose([

            Validators.max(250),
        ])),

    });
  }

  addProfile() {

    this.submitted = true;
    if (this.addOfficeForm.invalid) {
      return;
    }

    this.user = JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id ? JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id : null;

    let practice_area = '';
    //$('.practice_area .row .check-box').each(function () {
    //  if (
    //    $(this)
    //      .find('input')
    //      .is(':checked')
    //  ) {
    //    var text = $(this).find('p').context.innerText;
    //    practice_area += text + ',';
    //  }
    //});

    Object.assign(this.addOfficeForm.value, {
      user_id: this.user,
      profile_pic: this.img,
      areas_of_practice: practice_area
    })

    //if (this.addOfficeForm.value.areas_of_practice.length == 0) {
    //  this.areaRequired = true;
    //  return;
    //}


    //if (this.addOfficeForm.value.profile_pic == null) {
    //  this.imgRequired = true;
    //  return;
    //}
    this.dataService.spinnerShow();
    this.dataService.addProfileStep1(this.addOfficeForm.value).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body.status_code === 0) {
          const step2 = {
            "id": response.body.data[0].id,
              "areas_of_practice": this.addOfficeForm.value.areas_of_practice,
              user_id: this.user,
          }
          this.dataService.addProfileStep2(step2).subscribe(
            (response2: HttpResponse<any>) => {
              this.dataService.spinnerHide();
              if (response2.body.status_code === 0) {
                this.toastrService.success(response.body.message);
              } else if (response2.body.status_code === 1) {
                this.toastrService.error(response.body.message);
              } else {
                this.toastrService.error(response.body.message);
              }
              this.router.navigate([`view-office-collegues`])
            }, error => {
              this.dataService.spinnerHide();
              this.toastrService.error(error.error['message']);
              this.router.navigate([`view-office-collegues`])
            }
          );

        } else if (response.body.status_code === 1) {
          this.dataService.spinnerHide();
          this.toastrService.error(response.body.message);
        } else {
          this.dataService.spinnerHide();
          this.toastrService.error(response.body.message);
        }
      }, error => {
        this.dataService.spinnerHide();
        this.toastrService.error(error.error['message']);
      }
    );

  }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    this.dataService.spinnerShow();
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      Object.assign(this.addOfficeForm.value, {
        profile_pic: myReader.result
      })
      this.img = myReader.result;
      this.dataService.spinnerHide();
    }
    myReader.readAsDataURL(file);
  }

}
