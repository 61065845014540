import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'lawyer-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private router: Router, private dataService: DataService, private title: Title, private meta: Meta) { }
  payments = null;
  user_id: string;
  user_name: string;
  offices: any;
  collegue: any;
  total_collegue: any;
  total_offices: any;
  plan_purchase: any;
    isBasic: Boolean = true
    isPlatinum: Boolean = false
  showArticleManagement: Boolean = false;
  benefits = []
  ngOnInit() {
    this.getPayment()
    this.title.setTitle("Jurislex - Dashboard");
    this.meta.updateTag({ name: 'description', content: 'View your profile and details on lawyer dashboard.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });

    let localData = JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data ? JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data : null;
    this.user_name = localData.name
    this.user_id = localData;
    this.benefits = localData.benefits;
    this.plan_purchase = localData.plan_purchase;
    if (this.benefits.lastIndexOf('13') != -1) {
      this.showArticleManagement = true;
    }
    this.getOfficeList();
    this.getColleagueList();
      this.isBasic = this.user_id['memberships'] === 'Basic' ? true : false;
      this.isPlatinum = this.user_id['memberships'] === 'Platinum' ? true : false;
    console.log(this.isBasic)

  }

  getOfficeList() {
    this.dataService.spinnerShow();
    this.dataService.getOffices(this.user_id['id']).subscribe(
      (response: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.offices = response.body.data;
        this.total_offices = this.offices.length;
        this.offices = this.offices.filter(element => {
          return element['is_main_office'] === "Y"
        })
        this.offices = this.offices[0];
        // console.log(this.offices)
      });
  }

  getColleagueList() {
    this.dataService.spinnerShow();
    this.dataService.getCollegueList(this.user_id['id']).subscribe(
      (response1: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.collegue = response1.body.data;
        this.total_collegue = this.collegue.length;
        // console.log(this.collegue.length)
      });
  }

  getPayment() {
    this.dataService.spinnerShow();

    let user_id = JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id ? JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id : null;

    this.dataService.getPaymentData(user_id).subscribe(
      (response1: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.payments = response1.body.data;
      });
  }
  getPDF(pdf_id) {
    this.router.navigate(['/invoice', pdf_id.toString()]);
  }

  onLogout() {
    this.router.navigate(['/signin-lawyer']);
    localStorage.removeItem('websiteCurrentUser');
    localStorage.removeItem('websiteToken');
  }
}


