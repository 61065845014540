import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, FormGroup, FormControl } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../services/data.service';
import { Meta, Title } from '@angular/platform-browser';

declare var stripe: any;
declare var elements: any;

@Component({
  selector: 'app-payment-checkout',
  templateUrl: './payment-checkout-dc.component.html',
  styleUrls: ['./payment-checkout-dc.component.css']
})
export class DC_PaymentCheckoutComponent implements AfterViewInit, OnDestroy {
  @ViewChild('cardInfo', { static: true }) cardInfo: ElementRef;

  headers: Headers = new Headers({ 'Content-Type': 'application/json' })

  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;

  apiToken: any;
  planId: any;
  planAmount: any;
    countries: [any];
    states: [any];
    cities: [any];
    payment: FormGroup;


  constructor(
    private dataService: DataService,
    private httpClient: HttpClient,
    private cd: ChangeDetectorRef,
    private currentRoute: ActivatedRoute,
    private tostrService: ToastrService,
    private router: Router,private title: Title, private meta: Meta
  ) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');

    this.currentRoute.params.subscribe((params) => {
      this.apiToken = 1;
      this.planId = 1;
    })

    // this.apiToken = "ZtpL1mQUV9beHmC454qypu5IJH6uzmhGPtp3xde3PWufyf9VflOYolc27U2Y";
    // this.planId = 2;
  }

  ngOnInit(): void {
      this.title.setTitle("D&C Legal Services - Payment Checkout");   
      this.createForm();
    
      console.log('11111111111');
      this.dataService.getCountries().subscribe((response: HttpResponse<any>) => {
          this.countries = response.body.data;
          console.log('22222222222');
          console.log(this.countries);
      });
  }

    createForm() {       

        this.payment = new FormGroup({
            coupon: new FormControl('', []),

            use_address: new FormControl('', []),
            address_country: new FormControl('', []),
            address_line1: new FormControl('', []),
            address_city: new FormControl('', []),
            address_state: new FormControl('', []),
            address_zip: new FormControl('', [])

        })

    }
  ngAfterViewInit() {
    const style = {
      base: {
        lineHeight: '24px',
        fontFamily: 'monospace',
        fontSmoothing: 'antialiased',
        fontSize: '19px',
        '::placeholder': {
          color: '#c6bebe'
        }
      }
    };

    this.card = elements.create('card', { style });
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit(form: NgForm) {
      var inputValue = document.getElementById('TotalAmount')["value"];
      this.planAmount = inputValue;
      //alert(inputValue);
      if (inputValue > 0) {
          const { token, error } = await stripe.createToken(this.card);

          if (error) {
              console.log('Something is wrong:', error);
          } else {

              console.log('Success!', token);
              let _stoken = token.id;
              console.log("----------->>>>>", _stoken)
              this.dataService.createChargesDC(this.planAmount, _stoken, this.apiToken).subscribe((response: HttpResponse<any>) => {
                  console.log("response------------------>>>>>>", response)
                  this.tostrService.success(response.body.message);
                  this.router.navigate(['/sucessfull_dc']);
                  localStorage.removeItem('websiteCurrentUser');
                  localStorage.removeItem('websiteToken');
              })

          }
      }
      else {
          this.tostrService.error('Check the Amount Added');
      }
      

  
  }

    
    onCountryChange(event) {
        
        this.states = null;
        this.cities = null;
        this.dataService
            .getStates(this.payment.value.address_country)
            .subscribe((response: HttpResponse<any>) => {
                this.states = response.body.data;
                console.log(this.states);
            });
    }

    onStateChange(event) {
        this.cities = null;
        // console.log(this.addLawyerStep2.value, '8888');
        this.dataService
            .getCities(this.payment.value.address_state)
            .subscribe((response: HttpResponse<any>) => {
                this.cities = response.body.data;
                console.log(this.cities);
            });
    }

    //nextPrevStep4(n) {
    //    var inputValue = document.getElementById('coupon')["value"];
    //    //alert(inputValue);
    //    if (inputValue == '123') {
    //        alert('1');
    //    }
    //    else {
    //        alert('2');
    //    }
    //    alert('3');
    //}

  // checkMembership() {
  //   const headers = new HttpHeaders({ 'Content-type': 'application/json' });
  //   return this.httpClient.get('http://40.121.65.234:83/ws/api/memberships', {
  //     observe: 'response',
  //     headers: headers
  //   });
  // }

  // createCharges(amount, _stoken, api_token) {
  //   const headers = new HttpHeaders({ 'Content-type': 'application/json' });
  //   return this.httpClient.post('http://40.121.65.234:83/ws/api/create-charges', { amount, _stoken, api_token }, {
  //     observe: 'response',
  //     headers: headers
  //   });
  // }

}