import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { Meta, Title } from '@angular/platform-browser';
declare var hljs: any;

@Component({
  selector: 'create-blog',
  templateUrl: './create-blog.component.html',
  styleUrls: ['./create-blog.component.css']
})
export class CreateBlogComponent implements OnInit {

  constructor(private dataService: DataService, private router: Router, private title: Title, private meta: Meta) {

  }
  // myContent;

  contactUsForm: FormGroup;
  submitted = false;
  create_form = true;
  localdata = null;
  showArticleManagement: boolean = false;
  categories = ['any']
  ngOnInit() {

    this.dataService.spinnerShow();
    this.title.setTitle("Jurislex - Add Blog");
    this.meta.updateTag({ name: 'description', content: 'Jusrislex blogs will be publish from here from premium member.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal, Contact Form' });

    this.localdata = JSON.parse(localStorage.getItem('websiteCurrentUser')) ? JSON.parse(localStorage.getItem('websiteCurrentUser')).data : null

    if (this.localdata.benefits.lastIndexOf('13') != -1) this.showArticleManagement = true;


    if (this.localdata != null && this.showArticleManagement == true) {
      this.dataService.getCategories().subscribe((response: HttpResponse<any>) => {
        this.categories = response.body.data;
        console.log(this.categories)
        this.dataService.spinnerHide();
      }, error => {
        if (error.status == 401) {
          this.dataService.spinnerHide();
          console.log("error---401", error);
          this.router.navigate(['/sign-in']);
          localStorage.removeItem('websiteCurrentUser');
          localStorage.removeItem('websiteToken');
        }
      });
      this.createform()
    }
    else {
      this.dataService.spinnerHide();
      this.router.navigate(['/']);

    }

  }

  createform() {

    this.contactUsForm = new FormGroup({
      user_id: new FormControl(null),
      title: new FormControl(null, Validators.compose([
        Validators.required
      ])),
      meta_description: new FormControl(null, Validators.compose([
        Validators.required
      ])),
      excerpt: new FormControl(null),
      slug: new FormControl(null),
      //category: new FormControl(null),
      status: new FormControl(0),
      body: new FormControl(null, Validators.compose([
        Validators.required
      ])),


    });
  }

  addBlog() {
    this.submitted = true;
    this.contactUsForm.value.slug = this.contactUsForm.value.title
    this.contactUsForm.value.user_id = this.localdata.id
    if (this.contactUsForm.invalid) {
      return;
    }
    let string = this.contactUsForm.value.body.replace(/\n|\r/g, "").split(" ")
      this.contactUsForm.value.body = this.contactUsForm.value.body.replace(/\n|\r/g, "")

      let stringexcerpt = "";

      if (string.length <= 200) stringexcerpt = this.contactUsForm.value.body;
    else {
      let shortString = [];
      for (let i = 0; i < 200; i++) { shortString.push(string[i]) }
          stringexcerpt = shortString.join(" ");
    }
    console.log('data--', this.contactUsForm.value)


      const data = {
          user_id: this.localdata.id,
          title: this.contactUsForm.value.title,
          slug: this.contactUsForm.value.title,
          meta_description: this.contactUsForm.value.meta_description,
          excerpt: stringexcerpt,
          body: this.contactUsForm.value.body,
          status: 0,
          //category: this.contactUsForm.value.category,
          
      };


    this.dataService.spinnerShow();
      this.dataService.addBlog(data).subscribe(
      (response: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        console.log(response)
        if (response.body.status_code === 0) {
          swal.fire('Success!', "Article added successfully!", 'success');
          this.router.navigate(['legal-article'])
        } else {
          swal.fire('Error', response.body.message, 'error');
        }
      }, error => {
        this.dataService.spinnerHide();
        swal.fire('Error', error.error['message'], 'error');
        if (error.status == 401) {
          console.log("error---401", error);
          //localStorage.removeItem('websiteCurrentUser');
          //localStorage.removeItem('websiteToken');
          //this.router.navigate(['/'])
        }

      }
    );
  }


}
