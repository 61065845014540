import { Component, OnInit, NgZone } from '@angular/core';
import { PaypalScriptService } from '../../services/paypal-script.service';
import { PayPalConfig } from '../../models/pay-pal-config';
import { HttpResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../../../services/data.service';
import { ToastrService } from 'ngx-toastr';


@Component({
    selector: 'app-paypal',
    templateUrl: './paypal.component.html',
    styleUrls: ['./paypal.component.css']
})
export class PaypalComponent implements OnInit {
    headers: Headers = new Headers({ 'Content-Type': 'application/json' })
    apiToken: any;
    planId: any;
    planAmount: any;
    planName: any;

    payPalButtonId: string = 'payPalButton';

    
    private _stoken : string;
//console.log("----------->>>>>", _stoken)
    charge_id: '';
    paid_amount: 0;
    paid_amount_currency: 'USD';
    transaction_id: '';
    seller_message: '';
    paid: 0;
    receipt_email: '';
    receipt_number: '';
    receipt_url: '';
    card_id: '';
    card_country: '';
    payment_created: '';

    private payPalLoaded: boolean;
    private paypalConfiguration: PayPalConfig = {
        components: ['buttons', 'funding-eligibility'],
        currency: 'USD',
    };

    constructor(
        private dataService: DataService,
        private paypalScriptService: PaypalScriptService,
        private currentRoute: ActivatedRoute,
        private ngZone: NgZone,
        private tostrService: ToastrService,
        private router: Router
    ) {
        this.headers = new Headers();
        this.headers.append('Content-Type', 'application/json');

        this.currentRoute.params.subscribe((params) => {
            this.apiToken = params['apiToken'];
            this.planId = params['planId'];
        })


    }

    ngOnInit(): void {
                
        this.dataService.getPlans().subscribe((response: HttpResponse<any>) => {
            let data = response.body.data;
            data.forEach(element => {
                if (element.id == this.planId) {
                    this.planAmount = element.price;
                    this.planName = element.title;
                }
            });
        });

        if (!this.payPalLoaded) {
            this.paypalScriptService.registerScript(this.paypalConfiguration, (payPal: any) => {
                this.payPalLoaded = true;
                this.init(payPal);
            });
        }

        //window.open('PaypalPayment.html?data=123&charge_id=333', '_self');
    }

    init(payPal: any): void {
        const self = this;
        this.ngZone.runOutsideAngular(() => {
            payPal
                .Buttons({
                    fundingSource: payPal.FUNDING.PAYPAL,
                    createOrder: (data: any, actions: any) => {
                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: 'USD',
                                        value: this.planAmount
                                    }
                                }
                            ]
                        });
                    },
                    onApprove: (data: any, actions: any) => {

                        console.log('Success!', actions.order.capture());
                        console.log('idddd!', data.orderID);
                        console.log('data!', data.payerID);
                        let _stoken = 'paypal'
                        this.dataService.createChargesPaypal(this.planAmount, _stoken, this.apiToken, data.orderID, data.payerID).subscribe((response: HttpResponse<any>) => {
                            console.log("response------------------>>>>>>", response)
                            this.tostrService.success(response.body.message);
                            this.router.navigate(['/sucessfull_member']);
                            localStorage.removeItem('websiteCurrentUser');
                            localStorage.removeItem('websiteToken');
                        })

                        //this.charge_id=
                        // This function captures the funds from the transaction.
                        //return actions.order.capture().then(function (details) {
                        //    // This function shows a transaction success message to your buyer.
                        //    self.ngZone.run(() => {
                              
                        //       // alert('Transaction completed by ' + details.payer.name.given_name);
                        //       // window.open('PaypalPayment.html?data=' + details.payer.name.given_name + '&charge_id=' + details.id, '_self');
                        //        //this._stoken= 'paypal'
                        //       // this.charge_id = details.id;
                                
                        //        //this.paid_amount = details.purchase_units[0].amount.value;
                        //        //this.paid_amount_currency = details.purchase_units[0].amount.currency_code
                        //        //this.transaction_id = details.purchase_units[0].payments.captures[0].id;
                        //        //this.seller_message = details.purchase_units[0].payments.captures[0].status;
                        //        //this.paid = details.purchase_units[0].amount.value;
                        //        //this.receipt_email = details.purchase_units[0].payee.email_address;
                        //        //this.receipt_number = details.purchase_units[0].payee.merchant_id;
                        //        //this.receipt_url = '';
                        //        //this.card_id = details.payer.payer_id;
                        //        //this.card_country = details.payer.address.country_code;
                        //        //this.payment_created = details.purchase_units[0].payments.captures[0].create_time;

                        //        //console.log('charge_id', this.charge_id);
                        //        //console.log('paid_amount', this.paid_amount);
                        //        //console.log('paid_amount_currency', this.paid_amount_currency);
                        //        //console.log('transaction_id', this.transaction_id);
                        //        //console.log('seller_message', this.seller_message);
                        //        //console.log('paid', this.paid);
                        //        //console.log('receipt_email', this.receipt_email);
                        //        //console.log('receipt_number', this.receipt_number);
                        //        //console.log('receipt_url', this.receipt_url);
                        //        //console.log('card_id', this.card_id);
                        //        //console.log('card_country', this.card_country);
                        //        //console.log('payment_created', this.payment_created);

                                     
                              

                        //    });
                        //});


                        //console.log('charge_id', charge_id);
                        //console.log('paid_amount', paid_amount);
                        //console.log('paid_amount_currency', paid_amount_currency);
                        //console.log('transaction_id', transaction_id);
                        //console.log('seller_message', seller_message);
                        //console.log('paid', paid);
                        //console.log('receipt_email', receipt_email);
                        //console.log('receipt_number', receipt_number);
                        //console.log('receipt_url', receipt_url);
                        //console.log('card_id', card_id);
                        //console.log('card_country', card_country);
                        //console.log('payment_created', payment_created);
                        



                        //this.dataService.createChargesPaypal(this.planAmount, _stoken, this.apiToken, charge_id, paid_amount, paid_amount_currency, transaction_id, seller_message, paid, receipt_email, receipt_number, receipt_url, card_id, card_country, payment_created).subscribe((response: HttpResponse<any>) => {
                        //    console.log("response------------------>>>>>>", response)
                        //    this.tostrService.success(response.body.message);
                        //    this.router.navigate(['/sucessfull_member']);
                        //    localStorage.removeItem('websiteCurrentUser');
                        //    localStorage.removeItem('websiteToken');
                        //})
                    }
                })
                .render(`#${this.payPalButtonId}`);

            //this.router.navigate(['/sucessfull_member']);
            //localStorage.removeItem('websiteCurrentUser');
            //localStorage.removeItem('websiteToken');
        });
    }


}
