import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  @Output() getLanguageSignal: EventEmitter<any> = new EventEmitter();
  constructor(private httpClient: HttpClient, private spinner: NgxSpinnerService) { }

  spinnerShow() {
    this.spinner.show();
  }
  spinnerHide() {
    this.spinner.hide();
  }
  getCountries() {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/countries`, {
      observe: 'response',
      headers: headers
    });
  }

  getStates(id) {

    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/states/${id}`, {
      observe: 'response',
      headers: headers
    });
  }

  getStatesForSearch(id) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/get-state?country_id=${id}`, {
      observe: 'response',
      headers: headers
    });
  }

  getSubCategory(id) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/get_practice_area?id=${id}`, {
      observe: 'response',
      headers: headers
    });
  }

  getCities(id) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/cities/${id}`, {
      observe: 'response',
      headers: headers
    });
  }

  getPlans() {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/memberships`, {
      observe: 'response',
      headers: headers
    });
  }

  getPracticeArea() {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/practice-area`, {
      observe: 'response',
      headers: headers
    });
  }

  createCharges(amount, _stoken, api_token) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/create-charges`, { amount, _stoken, api_token }, {
      observe: 'response',
      headers: headers
    });
  }

  createChargesPaypal(amount, _stoken, api_token, orderID, payerID) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/create-charges-paypal`, { amount, _stoken, api_token, orderID, payerID }, {
      observe: 'response',
      headers: headers
    });
  }

  createChargesDC(amount, _stoken, api_token) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/create-charges-dc`, { amount, _stoken, api_token }, {
      observe: 'response',
      headers: headers
    });
  }

  getDropdownData() {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/advance-search-data`, {
      observe: 'response',
      headers: headers
    });
  }

  getAdvanceSearch(page_no, text) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/search?current_page=${page_no}&keyword=${text}`, {
      observe: 'response',
      headers: headers
    });
  }

  // getAdvanceSearchGold(page_no, text) {
  //   const headers = new HttpHeaders({ 'Content-type': 'application/json' });
  //   return this.httpClient.get(`${env.API_URL}/api/search-gold?current_page=${page_no}&keyword=${text}`, {
  //     observe: 'response',
  //     headers: headers
  //   });
  // }

  // getAdvanceSearchSilver(page_no, text) {
  //   const headers = new HttpHeaders({ 'Content-type': 'application/json' });
  //   return this.httpClient.get(`${env.API_URL}/api/search-silver?current_page=${page_no}&keyword=${text}`, {
  //     observe: 'response',
  //     headers: headers
  //   });
  // }

  getfilterSearch(data, keyword, page) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    if (data.areas_of_practice_sub) { data.areas_of_practice = data.areas_of_practice_sub }
    return this.httpClient.get(`${env.API_URL}/api/advance-search?country=${data.country_id}&state=${data.state_id}&rate=${data.rating}&membership=${data.memberships}&practice_areas=${data.areas_of_practice}&no_of_offices=${data.total_office}&no_of_lawyers=${data.total_lawyers_wordwide}&founded_date=${data.firm_founded_year}&keyword=${keyword}&languages=${data.language}&current_page=${page}`, {
      observe: 'response',
      headers: headers
    });
  }

  addLawyer(data) {
    const token = localStorage.getItem('websiteToken');
    data.lawyer_cat = 0;
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/register-lawyer`, data, {
      observe: 'response',
      headers: headers
    });
  }

  getLawyer(id) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/lawyer/${id}`, {
      observe: 'response',
      headers: headers
    });
  }

  getUser(id) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/profile/${id}`, {
      observe: 'response',
      headers: headers
    });
  }

  getLawyerProfile(id) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/lawyer-profile/${id}`, {
      observe: 'response',
      headers: headers
    });
  }

  getSpecializations() {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/specializations-master`, {
      observe: 'response',
      headers: headers
    });
  }

  editCustomer(data) {
    const token = localStorage.getItem('websiteToken');
    data.api_token = token
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(
      `${env.API_URL}/api/customer-profile-edit`,
      data,
      {
        observe: 'response',
        headers: headers
      }
    );
  }

  lawyerSearchNewAll(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(
      `${env.API_URL}/api/lawyers-list-new-all?s=${data.search}&c=${data.city}&current_page=${data.current_page}`,
      {
        observe: 'response',
        headers: headers
      }
    );
  }

  lawyerSearch(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(
      `${env.API_URL}/api/lawyers-list?s=${data.search}&c=${data.city}`,
      {
        observe: 'response',
        headers: headers
      }
    );
  }

  lawyerSearchGold(data) {

    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(
      `${env.API_URL}/api/lawyers-list-Gold?s=${data.search}&c=${data.city}`,
      {
        observe: 'response',
        headers: headers
      }
    );
  }

  lawyerSearchSilver(data) {

    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(
      `${env.API_URL}/api/lawyers-list-Silver?s=${data.search}&c=${data.city}`,
      {
        observe: 'response',
        headers: headers
      }
    );
  }

  getTopLawyers() {
    const token = localStorage.getItem('websiteToken');

    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(
      `${env.API_URL}/api/lawyers-bycat-list?api_token=${token}&category=7`,
      {
        observe: 'response',
        headers: headers
      }
    );
  }

  getFeaturedLawyers(page) {
    const token = localStorage.getItem('websiteToken');

    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(
      `${env.API_URL}/api/lawyers-bycat-list?api_token=${token}&current_page=${page}`,
      {
        observe: 'response',
        headers: headers
      }
    );
  }

  getContent(lang) {
    const token = localStorage.getItem('websiteToken');

    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/pages-user-list?lang=${lang}`, {
      observe: 'response',
      headers: headers
    });
  }

  submitRating(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(
      `${env.API_URL}/api/rate-lawyer?lawyer_id=${data.lawyer_id}&rating=${data.rate}&user_id=${data.user_id}&comment=${data.comment}`, {}, {
      observe: 'response',
      headers: headers
    });
  }

  getLawyerRatings(id) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/lawyer-rating?lawyer_id=${id}`, {
      observe: 'response',
      headers: headers
    });
  }

  getParticularLawyerRatings(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/lawyer-rating-user?lawyer_id=${data.lawyer_id}&user_id=${data.user_id}`, {
      observe: 'response',
      headers: headers
    });
  }

  sendMailToLawyer(data) {

    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    // &message=${data.message}
    return this.httpClient.post(
      `${env.API_URL}/api/mailto-lawyer?lawyer_id=${data.lawyer_id}&name=${data.name}&address=${data.address}&country=${data.country}&city=${data.city}&state=${data.state}&email=${data.email}&phone=${data.phone}&message=${data.message}`, {
      attachement: data.attachement
    }, {
      observe: 'response',
      headers: headers
    });
  }



  addLawyerStep0(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/lawyer-signup-step0`, data, {
      observe: 'response',
      headers: headers
    });
  }
  addLawyerStep1(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/lawyer-signup-step1`, data, {
      observe: 'response',
      headers: headers
    });
  }

  addLawyerStep2(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/lawyer-signup-step2`, data, {
      observe: 'response',
      headers: headers
    });
  }

  addLawyerStep3(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/lawyer-signup-step3`, data, {
      observe: 'response',
      headers: headers
    });
  }
  addLawyerStep4(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/lawyer-signup-step4`, data, {
      observe: 'response',
      headers: headers
    });
  }
  addLawyerStep5(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/lawyer-signup-step5`, data, {
      observe: 'response',
      headers: headers
    });
  }

  addLawyerStep5Free(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/lawyer-signup-step5Free`, data, {
      observe: 'response',
      headers: headers
    });
  }

  getLawyerByFilters(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/lawyers-filter?${data}=true`, {
      observe: 'response',
      headers: headers
    });
  }

  getFeaturedLawyerByFilters(data) {
    console.log(data);
    
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/lawyers-filter-featured?${data}=true`, {
      observe: 'response',
      headers: headers
    });
  }

  getAllBlogs() {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/articles-list`, {
      observe: 'response',
      headers: headers
    });
  }

  getParticularBlog(id) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/articles/${id}`, {
      observe: 'response',
      headers: headers
    });
  }

  addOfficeStep1(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/add-office-step1`, data, {
      observe: 'response',
      headers: headers
    });
  }

  addOfficeStep2(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/add-office-step2`, data, {
      observe: 'response',
      headers: headers
    });
  }

  updateOfficeStep1(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/update-office-step1`, data, {
      observe: 'response',
      headers: headers
    });
  }

  updateOfficeStep2(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/update-office-step2`, data, {
      observe: 'response',
      headers: headers
    });
  }

  getOffices(id) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/office-list?user_id=${id}`, {
      observe: 'response',
      headers: headers
    });
  }

  getCollegueList(id) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/colleague-list?user_id=${id}`, {
      observe: 'response',
      headers: headers
    });
  }

  getLanguages() {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/web-languages-list`, {
      observe: 'response',
      headers: headers
    });
  }

  addProfileStep1(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/add-colleague-step1`, data, {
      observe: 'response',
      headers: headers
    });
  }

  addProfileStep2(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/add-colleague-step2`, data, {
      observe: 'response',
      headers: headers
    });
  }

  editProfileStep1(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/update-colleague-step1`, data, {
      observe: 'response',
      headers: headers
    });
  }

  editProfileStep2(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/update-colleague-step2`, data, {
      observe: 'response',
      headers: headers
    });
  }

  contactUs(data) {
    console.log(data);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/contactus-mail`, data, {
      observe: 'response',
      headers: headers
    });
  }
  DescribeLegalProblem(data) {
    console.log(data);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/api_describe_legal_problem_mail`, data, {
      observe: 'response',
      headers: headers
    });
  }

  getProfileList(id) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/colleague-list?user_id=${id}`, {
      observe: 'response',
      headers: headers
    });
  }
  deleteOffice(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/delete-office`, data, {
      observe: 'response',
      headers: headers
    });
  }

  deleteColleague(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/delete-colleague`, data, {
      observe: 'response',
      headers: headers
    });
  }

  getPaymentData(id) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/order-list?user_id=${id}`, {
      observe: 'response',
      headers: headers
    });
  }

  getLawyerFeedbacks(id) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/lawyer-feedback/${id}`, {
      observe: 'response',
      headers: headers
    });
  }
  subscribeNews(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/newsletter`, data, {
      observe: 'response',
      headers: headers
    });
  }

  getCategories() {
    const token = localStorage.getItem('websiteToken');

    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(
      `${env.API_URL}/api/lawyer-cats-list?id=1&api_token=${token}`,
      { observe: 'response', headers: headers },
    );
  }


  addBlog(data) {
    const token = localStorage.getItem('websiteToken');
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/articles-add?api_token=${token}`, data, {
      observe: 'response',
      headers: headers
    });


  }

  updateBlog(data) {
    const token = localStorage.getItem('websiteToken');
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/articles-update?api_token=${token}`, data, {
      observe: 'response',
      headers: headers
    });
  }

  deleteBlog(id) {
    const token = localStorage.getItem('websiteToken');
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/articles-delete/${id}?api_token=${token}`, {}, {
      observe: 'response',
      headers: headers
    });
  }

  changeLang(language) {
    return new Promise((resolve, reject) => {
      this.getLanguageSignal.emit(language);
      resolve();
    })
  }
}



