import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AuthServices } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  code: string;
  errors: Array<string>;
  resetPasswordForm: FormGroup;
  showForm: Boolean = false;
  email: String;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthServices,
    private toastrService: ToastrService, private title: Title, private meta: Meta
  ) { }

  ngOnInit() {
    this.title.setTitle("Jurislex - Reset Password");
    this.meta.updateTag({ name: 'description', content: 'Reset password page.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });
    this.createForm();
    this.route.params.subscribe(params => {


      this.code = params['token'];


      console.log(this.code);
      this.authService.checkToken(this.code).subscribe(
        (response: HttpResponse<any>) => {
          console.log('response', response);
          if (response.body.status_code == 0) {
            this.showForm = true;
            this.email = response.body.data.email;
          }
          else {
            console.log('error', response);
            this.toastrService.error(response.body.message);
            this.router.navigate(['/sign-in']);
          }
        },
        (error: any) => {
          console.log('error', error);
          this.toastrService.error(error.error.message);
          this.router.navigate(['/sign-in']);
        }
      );
    });
  }

  resetPassword(): void {
    const credentials = {
      password: this.resetPasswordForm.value.password,
      password_confirmation: this.resetPasswordForm.value.password_confirmation,
      token: this.code,
      email: this.email
    };

    if (this.resetPasswordForm.valid) {
      this.authService.resetPassword(credentials).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body.status_code == 0) {
            this.toastrService.success(response.body.message);
            if (response.body.data.role === 3)
              this.router.navigate(['/signin-lawyer']);
            else
              this.router.navigate(['/signin-user']);

          }
          this.router.navigate(['', {}]);
        },
        error => {

          this.errors = error.error.errors;
          this.toastrService.error(error.error.errors.email);
          this.router.navigate(['/sign-in']);
        }
      );
    }
  }

  createForm() {
    this.resetPasswordForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6)
      ]),
      password_confirmation: new FormControl('', [
        Validators.required,
        Validators.minLength(6)
      ])
    });
  }
}
