import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env } from '../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class LawyerSearchService {

  constructor(private httpClient: HttpClient, private spinner: NgxSpinnerService) { }

  spinnerShow() {
    this.spinner.show();
  }
  spinnerHide() {
    this.spinner.hide();
  }

  firmSearch(firmType, firmLocation) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/lawyers-list?s=${firmType}&c=${firmLocation}`, {
      observe: 'response',
      headers: headers
    });
  }

  searchLawyer(type, country, page) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });

    // console.log(`${env.API_URL}/api/lawyers-search?sp=${type}&country=${country}&cat=0`)
    return this.httpClient.get(`${env.API_URL}/api/lawyers-search?sp=${type}&country=${country}&cat=0&current_page=${page}`, {
      observe: 'response',
      headers: headers
    });
  }

  searchLawyerGold(type, country) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });

    // console.log(`${env.API_URL}/api/lawyers-search?sp=${type}&country=${country}&cat=0`)
    return this.httpClient.get(`${env.API_URL}/api/lawyers-search-gold?sp=${type}&country=${country}&cat=0`, {
      observe: 'response',
      headers: headers
    });
  }

  searchLawyerSilver(type, country) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });

    // console.log(`${env.API_URL}/api/lawyers-search?sp=${type}&country=${country}&cat=0`)
    return this.httpClient.get(`${env.API_URL}/api/lawyers-search-silver?sp=${type}&country=${country}&cat=0`, {
      observe: 'response',
      headers: headers
    });
  }
}
