import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestimonyComponent } from './testimony.component';
import { TestimonyRoutingModule } from './testimony-routing.module';

@NgModule({
  declarations: [TestimonyComponent],
  imports: [
    CommonModule,
    TestimonyRoutingModule
  ]
})
export class TestimonyModule { }
