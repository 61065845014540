import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
declare var hljs: any;

@Component({
  selector: 'edit-blog',
  templateUrl: './edit-blog.component.html',
  styleUrls: ['./edit-blog.component.css']
})
export class EditBlogComponent implements OnInit {

  constructor(private dataService: DataService, private currentRoute: ActivatedRoute, private router: Router, private title: Title, private meta: Meta) {
    this.currentRoute.params.subscribe(params => {
      this.id = params['id'];

    });
  }
  // myContent;

  contactUsForm: FormGroup;
  submitted = false;
  create_form = true;
  localdata = null;
  categories = ['any']
  showArticleManagement: boolean = false;
  blog;
  id;
  ngOnInit() {
    this.dataService.spinnerShow();
    this.title.setTitle("Jurislex - Edit Article");
    this.meta.updateTag({ name: 'description', content: 'Jusrislex blogs will be publish from here from premium member.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal, Contact Form' });

    this.localdata = JSON.parse(localStorage.getItem('websiteCurrentUser')) ? JSON.parse(localStorage.getItem('websiteCurrentUser')).data : null

    if (this.localdata.benefits.lastIndexOf('13') != -1) this.showArticleManagement = true;


    if (this.localdata != null && this.showArticleManagement == true) {
      this.dataService.getCategories().subscribe((response: HttpResponse<any>) => {
        this.categories = response.body.data;
        this.getBlog(this.id);
        this.dataService.spinnerHide();
      }, error => {
        if (error.status == 401) {
          this.dataService.spinnerHide();
          this.router.navigate(['/sign-in']);
          localStorage.removeItem('websiteCurrentUser');
          localStorage.removeItem('websiteToken');
        }
      });

    }
    else {
      this.dataService.spinnerHide();
      this.router.navigate(['/']);

    }

  }


  getBlog(id) {
    this.dataService.getParticularBlog(id).subscribe((response: HttpResponse<any>) => {
      console.log('----------', response)
      if (response.body.data.length > 0) {
        this.blog = response.body.data[0];
        this.meta.updateTag({ name: 'description', content: this.blog.title });
        this.createform()
      }
      else {
        this.router.navigate(['/']);
      }
    })

  }
  createform() {
    let val = this.blog

    this.contactUsForm = new FormGroup({
      id: new FormControl(val.id),
      user_id: new FormControl(val.user_id),
      title: new FormControl(val.title, Validators.compose([
        Validators.required
      ])),
      meta_description: new FormControl(val.meta_description, Validators.compose([
        Validators.required
      ])),
      excerpt: new FormControl(val.excerpt),
      slug: new FormControl(val.slug),
      //category: new FormControl(val.category),
      status: new FormControl(0),
      body: new FormControl(val.body, Validators.compose([
        Validators.required
      ])),


    });
  }

  updateBlog() {
    this.submitted = true;
    this.contactUsForm.value.slug = this.contactUsForm.value.title
    this.contactUsForm.value.user_id = this.localdata.id
    if (this.contactUsForm.invalid) {
      return;
    }
    let string = this.contactUsForm.value.body.replace(/\n|\r/g, "").split(" ")
    this.contactUsForm.value.body = this.contactUsForm.value.body.replace(/\n|\r/g, "")
    if (string.length <= 200) this.contactUsForm.value.excerpt = this.contactUsForm.value.body;
    else {
      let shortString = [];
      for (let i = 0; i < 200; i++) { shortString.push(string[i]) }
      this.contactUsForm.value.excerpt = shortString.join(" ");
    }
    console.log('data--', JSON.stringify(this.contactUsForm.value))

    this.dataService.spinnerShow();
    this.dataService.updateBlog(this.contactUsForm.value).subscribe(
      (response: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        if (response.status === 200) {
          if (response.body.status_code === 0) {
            swal.fire('Success!', "Article updated successfully!", 'success');
            this.router.navigate(['legal-article'])
          } else {
            swal.fire('Error', response.body.message, 'error');
          }

        }

      }, error => {
        this.dataService.spinnerHide();
        swal.fire('Error', error.error['message'], 'error');
        if (error.status == 401) {
          console.log("error---401", error);
          localStorage.removeItem('websiteCurrentUser');
          localStorage.removeItem('websiteToken');
          this.router.navigate(['/'])
        }

      }
    );
  }


}
