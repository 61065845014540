import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AddCollegueRoutingModule } from './add-collegue-routing.module';
import { AddCollegueComponent } from './add-collegue.component';
@NgModule({
  declarations: [AddCollegueComponent],
  imports: [
    TranslateModule,
    FormsModule, ReactiveFormsModule,
    CommonModule,
    AddCollegueRoutingModule
  ]
})
export class AddCollegueModule { }
