import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'lawyer-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {this.title.setTitle("Jurislex - SignIn");
    this.meta.updateTag({ name: 'description', content: 'Login as lawyer or Login as customer here.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });  
  }

}
