import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LawyerSearchComponent } from './lawyer-search.component';
const routes: Routes = [
  { path: '', component: LawyerSearchComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LawyerSearchRoutingModule { }
