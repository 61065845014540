import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'lawyer-disclaimer',
    templateUrl: './sucessfull.component.html',
    styleUrls: ['./sucessfull.component.css']
})
export class SucessfullComponent implements OnInit {
  constructor(private dataService: DataService, private title: Title, private meta: Meta) {
    this.dataService.getLanguageSignal.subscribe(response => {
      //console.log("Disc  ne sun liya malik")
      this.getContent(response);
    });
  }
  myContent;
  ngOnInit() {
    let lang = localStorage.getItem('prefered_language');
      this.title.setTitle("Jurislex - Activation");
      this.meta.updateTag({ name: 'description', content: 'You are now a Jurislex Member.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });
    this.getContent(lang);
  }

  getContent(lang) {
    this.dataService.getContent(lang).subscribe((response: HttpResponse<any>) => {
      this.myContent = response.body.data[3].description;
    });
  }
}
