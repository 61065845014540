import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthServices } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { PasswordValidation } from '../validators/password-validation';
import { Meta, Title } from '@angular/platform-browser';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  LinkedInLoginProvider
} from 'angularx-social-login';
declare var jquery: any;
declare var $: any;
declare var hljs: any;

@Component({
  selector: 'lawyer-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.css']
})
export class RegisterUserComponent implements OnInit {
  country;
    public readonly siteKey = '6LfGxPEUAAAAAAHTrNk8UsRtdrGVzRY6AAIgKrNW';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  public useGlobalDomain: boolean = false;

  @ViewChild('captchaElem', { static: true }) captchaElem: any;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;
  constructor(
    private authService: AuthServices,
    private tostrService: ToastrService,
    private router: Router,
    private socialLoginService: AuthService,
    private dataService: DataService, private title: Title, private meta: Meta
  ) { }
  registerForm: FormGroup;
  private socialUser: SocialUser;
  private loggedIn: boolean;
  countries: [any];
  states: [any];
  cities: [any];
  socialLoginOnPageLoadFlag = false;
  ngOnInit() {
    this.title.setTitle("Jurislex - SignUp");
    this.meta.updateTag({ name: 'description', content: 'This page is used for customer registration.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal, Register, SignUp' });
    this.createForm();
    this.dataService.getCountries().subscribe((response: HttpResponse<any>) => {
      this.countries = response.body.data;

    });
    this.socialLoginService.authState.subscribe((user: any) => {

      this.socialUser = user;
      if (user && this.socialLoginOnPageLoadFlag) {
        this.socialLogin(user);
      }
    });
    // $('select.select').each(function() {
    //   console.log('test', $(this));
    //   var title = $(this).attr('title');
    //   if ($('option:selected', this).val() != '')
    //     title = $('option:selected', this).text();
    //   $(this)
    //     .css({
    //       'z-index': 10,
    //       opacity: 0,
    //       '-khtml-appearance': 'none'
    //     })
    //     .after('<span class="select">' + title + '</span>')
    //     .change(function() {
    //       var val = $('option:selected', this).text();
    //       $(this)
    //         .next()
    //         .text(val);
    //     });
    // });
  }

  socialLogin(user: SocialUser) {

    const data = {
      _token: 'base64:VdVI6iQ51l9qsN0sc1Yhc0uQC4/IRDcHUte6B/Fp3fY=',
      social_provider: user.provider,
      social_provider_id: user.id,
      email: user.email,
      name: user.name
    };
    this.authService.socialLogin(data).subscribe(
      (response: HttpResponse<any>) => {

        if (response.status == 200 && response.body.status != 'failed') {
          // localStorage.clear();
          localStorage.removeItem('websiteCurrentUser');
          localStorage.removeItem('websiteToken');
          localStorage.setItem('websiteToken', response.body.data.api_token);
          localStorage.setItem('websiteCurrentUser', JSON.stringify(response.body));
          // this.tostrService.success('You have successfully Logged In', 'Successful',
          // { timeOut: 200000, positionClass: 'toast-top-center', closeButton: true, toastClass: 'toast' })
          this.tostrService.success('You have successfully Logged In');
          this.router.navigate(['/lawyer-search']);
        }
        else {
          this.tostrService.error(response.body.message);
        }

      },
      error => {

        if (error.status == 422) {
          this.tostrService.error(error.error.errors.email);
        }
      }
    );
  }

  onCountryChange(event) {
    this.states = null;
    this.cities = null;
    this.dataService
      .getStates(this.registerForm.value.country)
      .subscribe((response: HttpResponse<any>) => {
        this.states = response.body.data;
      });
  }

  onStateChange(event) {
    this.cities = null;
    this.dataService
      .getCities(this.registerForm.value.state)
      .subscribe((response: HttpResponse<any>) => {
        this.cities = response.body.data;
      });
  }
  onRegister() {
    if (this.registerForm.valid) {
      this.authService
        .registerUser(this.registerForm.value)
        .subscribe((response: HttpResponse<any>) => {
          console.log("Response--->", response)
          if (response.body.status_code == 0) {
            this.tostrService.success('You have successfully registered. Please verify your email now.');
            this.router.navigate(['/signin-user']);
          } else if (response.body.status_code == 1) {
            // this.tostrService.error(response.body.data.email);
            this.tostrService.error(response.body.message);
          } else {
            this.tostrService.error('Something went wrong');
          }
        });
    }
  }
  createForm() {
    this.registerForm = new FormGroup(
      {
        name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [
          Validators.required,
          // Validators.minLength(6),
          Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{5,}$"),
        ]),
        phone: new FormControl('', []),
        password_confirmation: new FormControl('', [
          Validators.required,
          // Validators.minLength(6)

        ]),
        address: new FormControl('', []),
        city: new FormControl('', []),
        country: new FormControl('', []),
        state: new FormControl('', []),
        zipcode: new FormControl('', []),
        recaptcha: new FormControl('', [Validators.required])

      },
      { validators: PasswordValidation.MatchPassword }
    );
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

  changeSize(size: 'compact' | 'normal'): void {
    this.size = size;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  setLanguage(): void {
    this.lang = this.langInput.nativeElement.value;
  }

  setUseGlobalDomain(use: boolean): void {
    this.useGlobalDomain = use;
  }

  getCurrentResponse(): void {
    const currentResponse = this.captchaElem.getCurrentResponse();
    if (!currentResponse) {
      alert('There is no current response - have you submitted captcha?');
    } else {
      alert(currentResponse);
    }
  }
  ngAfterViewInit(): void {
    this.highlight();
  }

  getResponse(): void {
    const response = this.captchaElem.getResponse();
    if (!response) {
      alert('There is no response - have you submitted captcha?');
    } else {
      alert(response);
    }
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }

  private highlight(): void {
    const highlightBlocks = document.getElementsByTagName('code');
    for (let i = 0; i < highlightBlocks.length; i++) {
      const block = highlightBlocks[i];
      hljs.highlightBlock(block);
    }
  }

  signInWithFB(event): void {
    event.stopPropagation();
    event.preventDefault();
    this.socialLoginOnPageLoadFlag = true;
    this.socialLoginService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signInWithLinkedIn(event): void {
    event.stopPropagation();
    event.preventDefault();
    // window.location.href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=789f7f14ahoa8m&redirect_uri=http://localhost:4200/signin-user&state=sfooobar&scope=r_liteprofile%20r_emailaddress%20w_member_social"
    // window.location.href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=789f7f14ahoa8m&redirect_uri=http://40.121.65.234:83/signin-user&state=sfooobar&scope=r_liteprofile%20r_emailaddress%20w_member_social"

    window.location.href = "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=789f7f14ahoa8m&redirect_uri=https://jurislex.org/signin-user&state=sfooobar&scope=r_liteprofile%20r_emailaddress%20w_member_social"

    // this.socialLoginOnPageLoadFlag = true;
    // this.socialLoginService.signIn(LinkedInLoginProvider.PROVIDER_ID);
  }

  signOut(): void {
    this.socialLoginService.signOut();
  }
}
