import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'lawyer-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css']
})
export class BlogDetailsComponent implements OnInit {

  constructor(private router: Router, private dataService: DataService, private currentRoute: ActivatedRoute, private title: Title, private meta: Meta
  ) {
    this.currentRoute.params.subscribe(params => {
      this.id = params['id'];
      this.getBlog(this.id);
    });
  }
  id;
  blog
  ngOnInit() {
    this.title.setTitle("Jurislex - Blog Details");

    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal, blog, blog detail' });
  }

  getBlog(id) {
    this.dataService.getParticularBlog(id).subscribe((response: HttpResponse<any>) => {
      this.blog = response.body.data[0];
      console.log(this.blog)
      if (this.blog.status == 0) {
        this.router.navigate(['not-found'])
      }
      this.meta.updateTag({ name: 'description', content: this.blog.title });
    })

  }

}
