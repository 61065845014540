
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'lawyer-edit-office',
  templateUrl: './edit-office.component.html',
  styleUrls: ['./edit-office.component.css']
})
export class EditOfficeComponent implements OnInit {

  editOfficeForm: FormGroup;
  constructor(private activatedRoute: ActivatedRoute, private dataService: DataService, private toastrService: ToastrService, private router: Router, private title: Title, private meta: Meta) { }
    img = null;
  countries = null;
  states = null;
  cities = null;
  curr_user = null;
  data = {};
  submitted = false;
  specializations;
  isMainOffice = false;
  // user=null;
  areaRequired = false;
  currId = null;
  offices = [];
  office = null;
  user_id = null;
  renderForm: boolean = false;
  areas = null;
    affiliation_networks = null;
  ngOnInit() {

    this.title.setTitle("Jurislex - Edit Office");
    this.meta.updateTag({ name: 'description', content: 'This page is for edit additional office of your firm.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });
    this.activatedRoute.params.subscribe(params => {
      this.currId = params[`id`]
    })
    this.user_id = JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id ? JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id : null;
    this.dataService.getSpecializations().subscribe((response: HttpResponse<any>) => {
      this.specializations = response.body.data;
    });
    this.dataService.getCountries().subscribe((response: HttpResponse<any>) => {
      this.countries = response.body.data;
    });
    this.getOfficeList();

  }

  getOfficeList() {

    this.dataService.spinnerShow();
    this.dataService.getOffices(this.user_id).subscribe(
      (response: HttpResponse<any>) => {
        this.offices = response.body.data;
        var index = this.offices.findIndex(obj => obj.id == this.currId);

            this.office = this.offices[index];
            console.log(this.office);
        this.createForm();

            if (this.office.is_main_office == 'Y') {
                this.isMainOffice = true;
            }
            else {
                this.isMainOffice = false;
            }

        this.dataService.spinnerHide();
        this.showNetwork();
      }, (error: any) => {
        console.log(error)
      });
  }


  createForm() {

      let official_name = this.office.official_name ? this.office.official_name : '';
      let google_map_url = this.office.google_map_url ? this.office.google_map_url : '';      
    let web_links = this.office.contact_web_link ? this.office.contact_web_link : '';
    let main_firm_email = this.office.main_firm_email ? this.office.main_firm_email : '';
    let main_contact_name = this.office.main_contact_name ? this.office.main_contact_name : '';
    let main_contact_email = this.office.main_contact_email ? this.office.main_contact_email : '';
    //let lead_receiver_name = this.office.lead_receiver_name ? this.office.lead_receiver_name : '';
    //let lead_receiver_email = this.office.lead_receiver_email ? this.office.lead_receiver_email : '';
    let address = this.office.address ? this.office.address : '';
    let country_id = this.office.country_id ? this.office.country_id : '';
    this.dataService.getStates(country_id).subscribe((response: HttpResponse<any>) => {
      this.states = response.body.data;
    });
    let state_id = this.office.state_id ? this.office.state_id : '';
    this.dataService.getCities(state_id).subscribe((response: HttpResponse<any>) => {
      this.cities = response.body.data;
    });
    let city_id = this.office.city_id ? this.office.city_id : '';
    //let zipcode = this.office.zipcode ? this.office.zipcode : '';
      let phone = this.office.phone ? this.office.phone : '';
      let countrycode = this.office.countrycode ? this.office.countrycode : '';
      let faxcountrycode = this.office.faxcountrycode ? this.office.faxcountrycode : '';
    
    let fax = this.office.fax ? this.office.fax : '';
    let total_lawyers = this.office.total_lawyers ? this.office.total_lawyers : '';
    let firm_founded_year = this.office.firm_founded_year ? this.office.firm_founded_year : '';
    let languages = this.office.languages ? this.office.languages : '';
    let firm_practice_description = this.office.firm_practice_description ? this.office.firm_practice_description : '';
    let firm_headline_title = this.office.firm_headline_title ? this.office.firm_headline_title : '';
    let firm_overview = this.office.firm_overview ? this.office.firm_overview : '';
    let free_consultation = this.office.free_consultation == "Y" ? true : false;
    let contingency_fee_cases = this.office.contingency_fee_cases == "Y" ? true : false;
    let accepts_creditcard = this.office.accepts_creditcard == "Y" ? true : false;
    let firm_website = this.office.firm_website ? this.office.firm_website : '';
    //let firm_website_links_detail = this.office.firm_website_links_detail ? this.office.firm_website_links_detail : '';
    let firm_affiliation_networks = this.office.firm_affiliation_networks ? this.office.firm_affiliation_networks : '';
      let facebook = this.office.facebook ? this.office.facebook : '';
      let linkedin = this.office.linkedin ? this.office.linkedin : '';
      let twitter = this.office.twitter ? this.office.twitter : '';
      let instagram = this.office.instagram ? this.office.instagram : '';
      let youtube = this.office.youtube ? this.office.youtube : '';

      let firm_logo = this.office.firm_logo ? this.office.firm_logo : '';
      
      this.img = this.office.firm_logo;
      let profile_pic_org = this.img;
      let firm_video = this.office.firm_video ? this.office.firm_video : '';
      let firm_video_desc = this.office.firm_video_desc ? this.office.firm_video_desc : '';

     

    this.areas = this.office.areas_of_practice ? this.office.areas_of_practice.split(',') : '';

      this.affiliation_networks = firm_affiliation_networks;
     
      
     
    this.editOfficeForm = new FormGroup({

      official_name: new FormControl({ value: official_name, disabled: true }, Validators.compose([
        Validators.required,
      ])),

        google_map_url: new FormControl(google_map_url, Validators.compose([])),
        
      contact_web_link: new FormControl(web_links, Validators.compose([
        Validators.required,
      ])),

      main_firm_email: new FormControl(main_firm_email, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")
      ])
      ),

      main_contact_name: new FormControl(main_contact_name, Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.pattern("^[a-zA-Z. ]{3,50}")
      ])
      ),

      main_contact_email: new FormControl(main_contact_email, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")
      ])
      ),
      //lead_receiver_name: new FormControl(lead_receiver_name, Validators.compose([
      //  Validators.required,
      //  Validators.minLength(3),
      //  Validators.pattern("^[a-zA-Z. ]{3,50}")
      //])
      //),


      //lead_receiver_email: new FormControl(lead_receiver_email, Validators.compose([
      //  Validators.required,
      //  Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")
      //])
      //),
      address_1: new FormControl(address, Validators.compose([
        Validators.required,
      ])),
      //address_2: new FormControl(""),

      country_id: new FormControl(country_id, Validators.compose([
        Validators.required,
      ])),

      state_id: new FormControl(state_id, Validators.compose([])),

      city_id: new FormControl(city_id, Validators.compose([])),
      //zipcode: new FormControl(zipcode, Validators.compose([
      //  Validators.required,
      //  Validators.pattern("^[0-9]{6}")
      //])),

      phone: new FormControl(phone, Validators.compose([
          Validators.required,])),

        countrycode: new FormControl(countrycode, Validators.compose([
            Validators.required,])),

        faxcountrycode: new FormControl(faxcountrycode, []),
        
        fax: new FormControl(fax, []),

        firm_logo: new FormControl(firm_logo, []),
        firm_video: new FormControl(firm_video, []),
        firm_video_desc: new FormControl(firm_video_desc, []),

        
    
      total_lawyers: new FormControl(total_lawyers, Validators.compose([
        Validators.required,
        Validators.min(1),
      ])),
      firm_founded_year: new FormControl(firm_founded_year, Validators.compose([
        Validators.required,
        Validators.pattern("^[0-9]{4}")
      ])),
      languages: new FormControl(languages, Validators.compose([
        Validators.required,
      ])),
      firm_practice_description: new FormControl(firm_practice_description, Validators.compose([
        Validators.required,
      ])),
      firm_headline_title: new FormControl(firm_headline_title, Validators.compose([
        Validators.required,
      ])),
      firm_overview: new FormControl(firm_overview, Validators.compose([
        Validators.required,
      ])),
      free_consultation: new FormControl(free_consultation, Validators.compose([
        Validators.required,
      ])),
      contingency_fee_cases: new FormControl(contingency_fee_cases, Validators.compose([
        Validators.required,
      ])),
      accepts_creditcard: new FormControl(accepts_creditcard, Validators.compose([
        Validators.required,
      ])),
      firm_website: new FormControl(firm_website, Validators.compose([
        Validators.required,
      ])),
      //firm_website_links_detail: new FormControl(firm_website_links_detail, Validators.compose([
      //  Validators.required,
      //])),
        firm_affiliation_networks: new FormArray([]),

        facebook: new FormControl(facebook, Validators.compose([
            Validators.max(200),
        ])),

        linkedin: new FormControl(linkedin, Validators.compose([
            Validators.max(200),
        ])),

        twitter: new FormControl(twitter, Validators.compose([
            Validators.max(200),
        ])),

        instagram: new FormControl(instagram, Validators.compose([

            Validators.max(200),
        ])),

        youtube: new FormControl(youtube, Validators.compose([

            Validators.max(200),
        ])),

    });
    this.renderForm = true;
  }

  editOffice() {
    this.submitted = true;
    //if (this.editOfficeForm.invalid) {
    //  return;
    //}

    let practice_area = '';
    $('.practice_area .row .check-box').each(function () {
      if (
        $(this)
          .find('input')
          .is(':checked')
      ) {
        var text = $(this).find('p').context.innerText;
        practice_area += text + ',';
      }
    });

    Object.assign(this.editOfficeForm.value, {
      areas_of_practice: practice_area
    })

    //if (this.editOfficeForm.value.areas_of_practice.length == 0) {
    //  this.areaRequired = true;
    //  return;
    //}

    this.data = {
      "id": this.currId,
        "official_name": this.editOfficeForm.value.official_name,
        "google_map_url": this.editOfficeForm.value.google_map_url,        
      "contact_web_link": this.editOfficeForm.value.contact_web_link,
      "main_firm_email": this.editOfficeForm.value.main_firm_email,
      "main_contact_name": this.editOfficeForm.value.main_contact_name,
      "main_contact_email": this.editOfficeForm.value.main_contact_email,
      //"lead_receiver_name": this.editOfficeForm.value.lead_receiver_name,
      //"lead_receiver_email": this.editOfficeForm.value.lead_receiver_email,
      "address": this.editOfficeForm.value.address_1, // + ", " + this.editOfficeForm.value.address_2,
      "country_id": this.editOfficeForm.value.country_id,
      "state_id": this.editOfficeForm.value.state_id,
      "city_id": this.editOfficeForm.value.city_id,
      //"zipcode": this.editOfficeForm.value.zipcode,
        "phone": this.editOfficeForm.value.phone,
        "countrycode": this.editOfficeForm.value.countrycode,
        "faxcountrycode": this.editOfficeForm.value.faxcountrycode,
      //"free_number": parseInt(this.editOfficeForm.value.free_number),
      "fax": this.editOfficeForm.value.fax,
      "firm_founded_year": parseInt(this.editOfficeForm.value.firm_founded_year),
      "languages": this.editOfficeForm.value.languages,
      "total_lawyers": parseInt(this.editOfficeForm.value.total_lawyers),
      "firm_headline_title": this.editOfficeForm.value.firm_headline_title,
      "firm_overview": this.editOfficeForm.value.firm_overview,
      "free_consultation": this.editOfficeForm.value.free_consultation,
      "contingency_fee_cases": this.editOfficeForm.value.contingency_fee_cases,
      "accepts_creditcard": this.editOfficeForm.value.accepts_creditcard,
      "firm_website": this.editOfficeForm.value.firm_website,
      //"firm_website_links_detail": this.editOfficeForm.value.firm_website_links_detail,
        "firm_affiliation_networks": this.editOfficeForm.value.firm_affiliation_networks,
        "facebook": this.editOfficeForm.value.facebook,
        "linkedin": this.editOfficeForm.value.linkedin,
        "twitter": this.editOfficeForm.value.twitter,
        "instagram": this.editOfficeForm.value.instagram,
        "youtube": this.editOfficeForm.value.youtube,
        //"firm_logo": this.editOfficeForm.value.firm_logo,
        "firm_video": this.editOfficeForm.value.firm_video,
        "firm_video_desc": this.editOfficeForm.value.firm_video_desc,
        "profile_pic_org": this.img,
    }
     console.log(this.data)
    this.dataService.spinnerShow();
    this.dataService.updateOfficeStep1(this.data).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body.status_code === 0) {
          console.log("if status=0", response.body.data)
          const step2 = {
            "id": this.currId,
            "firm_practice_description": this.editOfficeForm.value.firm_practice_description,
            "areas_of_practice": this.editOfficeForm.value.areas_of_practice,
          }
          this.dataService.updateOfficeStep2(step2).subscribe(
            (response2: HttpResponse<any>) => {
              this.dataService.spinnerHide();
              if (response2.body.status_code === 0) {
                this.toastrService.success(response.body.message);
              } else if (response2.body.status_code === 1) {
                this.toastrService.error(response.body.message);
              } else {
                this.toastrService.error(response.body.message);
              }
              this.router.navigate([`view-office-collegues`])
            }, error => {
              this.dataService.spinnerHide();
              this.toastrService.error(error.error['message']);
              this.router.navigate([`view-office-collegues`])
            }
          );

        } else if (response.body.status_code === 1) {
          this.dataService.spinnerHide();
          this.toastrService.error(response.body.message);
        } else {
          this.dataService.spinnerHide();
          this.toastrService.error(response.body.message);
        }
      }, error => {
        this.dataService.spinnerHide();
        this.toastrService.error(error.error['message']);
      }
    );
  }

  addAffilationItem() {
    const control = new FormControl(null);
    (<FormArray>this.editOfficeForm.get('firm_affiliation_networks')).push(control);

  }

  showNetwork() {
    if (this.affiliation_networks.length > 0) {
      for (let net of this.affiliation_networks) {
        if (net != null) {
          const control = new FormControl(net);
          (<FormArray>this.editOfficeForm.get('firm_affiliation_networks')).push(control);
        }
      }
    }
  }

  onCountryChange(event) {
    this.dataService.spinnerShow();
    this.states = null;
    this.dataService
      .getStates(this.editOfficeForm.value.country_id)
      .subscribe((response: HttpResponse<any>) => {
        this.states = response.body.data;
        this.dataService.spinnerHide();
      });
  }

  onStateChange(event) {
    this.dataService.spinnerShow();
    this.cities = null;
    this.dataService
      .getCities(this.editOfficeForm.value.state_id)
      .subscribe((response: HttpResponse<any>) => {
        this.cities = response.body.data;
        this.dataService.spinnerHide();
      });
  }

    changeListener($event): void {
        this.readThis($event.target);
    }

  

    readThis(inputValue: any): void {
        var file: File = inputValue.files[0];
        var myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
            Object.assign(this.editOfficeForm.value, {
                firm_logo: myReader.result
            })
            this.img = myReader.result;

        }
        myReader.readAsDataURL(file);
    }
}


