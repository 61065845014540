import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../services/seo.service';

declare var $: any;

@Component({
  selector: 'lawyer-featured-lawyers',
  templateUrl: './featured-lawyers.component.html',
  styleUrls: ['./featured-lawyers.component.css']
})
export class FeaturedLawyersComponent implements OnInit {
  constructor(private dataService: DataService, private router: Router, private title: Title, private meta: Meta, private seoService: SeoService) { }
  focusFlag: Boolean = false;
  searchResult = [];
  pagination_value_key:boolean;
  pagination_value:boolean;
  p: number = 1;
  pagination_data = null;
  pagination_data1: any;
  ngOnInit() {
    this.pagination_value_key=true;
    this.seoService.createLinkForCanonicalURL();
    this.title.setTitle("Best International Lawyers Directory Online | Jurislex");
    this.meta.updateTag({ name: 'description', content: 'Are you looking for the best lawyers to solve your legal problems? End your search with the featured list of best lawyers across countries here at Jurislex.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });
    this.dataService
      .getFeaturedLawyers(this.p)
      .subscribe((response: HttpResponse<any>) => {
        if (response.body.data) {
          this.searchResult = response.body.data;
          // console.log(this.searchResult);
          
          this.pagination_data1 = response.body.data.length;
          // console.log(this.pagination_data1);
          
        }

      });

    $(document).ready(function () {
      $('.filter_sec ul li span').on('click', function () {
        $('.filter_sec ul li span').removeClass('active');
        $(this).addClass('active');
      })
    })
  }

  viewProfile(event, lawyer) {
    // this.router.navigate(['/lawyer-profile', lawyer]);
    this.router.navigate([]).then((result) => {
      window.open('/lawyer-profile/' + lawyer, '_blank');
    });
  }

  onPageChange(page: number) {
    this.p = page;
    // console.log(this.p);
    
    this.dataService
      .getFeaturedLawyers(this.p)
      .subscribe((response: HttpResponse<any>) => {

        if (response.body.data) {
          this.searchResult = response.body.data;
          this.pagination_data1 = response.body.data.length
          // console.log(this.pagination_data);
          // console.log(this.searchResult);
          
          
        }

      });
    window.scrollTo({
      top: 300,
      behavior: 'smooth',
    });
  }

  onLawyerFilter(data) {
    this.pagination_value = true;
    this.pagination_value_key=false;
    console.log(data);
    
    this.dataService
      .getFeaturedLawyerByFilters(data)
      .subscribe((response: HttpResponse<any>) => {
        console.log(response.body.data)
        if (response.body.data) this.searchResult = response.body.data;
        this.pagination_data1 = response.body.data.length;
      });
  }

  onChange(event) {
    // console.log(event)
    this.onLawyerFilter(event);
  }
}
