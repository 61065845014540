import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, FormGroup, FormControl } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../services/data.service';
import { Meta, Title } from '@angular/platform-browser';


declare var stripe: any;
declare var elements: any;

@Component({
    selector: 'app-payment-checkout',
    templateUrl: './payment-checkout.component.html',
    styleUrls: ['./payment-checkout.component.css']
})
export class PaymentCheckoutComponent implements AfterViewInit, OnDestroy {
    @ViewChild('cardInfo', { static: true }) cardInfo: ElementRef;

    headers: Headers = new Headers({ 'Content-Type': 'application/json' })

    card: any;
    cardHandler = this.onChange.bind(this);
    error: string;

    apiToken: any;
    planId: any;
    planAmount: any;
    planName: any;
    currentDate = new Date();
    countries: [any];
    states: [any];
    cities: [any];
    payment: FormGroup;


    constructor(
        private dataService: DataService,
        private httpClient: HttpClient,
        private cd: ChangeDetectorRef,
        private currentRoute: ActivatedRoute,
        private tostrService: ToastrService,
        private router: Router, private title: Title, private meta: Meta
    ) {
        this.headers = new Headers();
        this.headers.append('Content-Type', 'application/json');

        this.currentRoute.params.subscribe((params) => {
            this.apiToken = params['apiToken'];
            this.planId = params['planId'];
        })

        // this.apiToken = "ZtpL1mQUV9beHmC454qypu5IJH6uzmhGPtp3xde3PWufyf9VflOYolc27U2Y";
        // this.planId = 2;
    }


    ngOnInit(): void {
        this.title.setTitle("Jurislex - Payment Checkout");
        this.createForm();
        this.dataService.getPlans().subscribe((response: HttpResponse<any>) => {
            let data = response.body.data;
            data.forEach(element => {
                if (element.id == this.planId) {
                    this.planAmount = element.price;
                    this.planName = element.title;
                }
            });
        });
        // console.log('11111111111');
        this.dataService.getCountries().subscribe((response: HttpResponse<any>) => {
            this.countries = response.body.data;
            // console.log('22222222222');
            // console.log(this.countries);
        });


    }

    createForm() {

        this.payment = new FormGroup({
            coupon: new FormControl('', []),

            use_address: new FormControl('', []),
            address_country: new FormControl('', []),
            address_line1: new FormControl('', []),
            address_city: new FormControl('', []),
            address_state: new FormControl('', []),
            address_zip: new FormControl('', [])

        })

    }
    ngAfterViewInit() {
        const style = {
            base: {
                lineHeight: '24px',
                fontFamily: 'monospace',
                fontSmoothing: 'antialiased',
                fontSize: '19px',
                '::placeholder': {
                    color: '#c6bebe'
                }
            }
        };

        this.card = elements.create('card', { style });
        this.card.mount(this.cardInfo.nativeElement);

        this.card.addEventListener('change', this.cardHandler);
    }

    ngOnDestroy() {
        this.card.removeEventListener('change', this.cardHandler);
        this.card.destroy();
    }

    onChange({ error }) {
        if (error) {
            this.error = error.message;
        } else {
            this.error = null;
        }
        this.cd.detectChanges();
    }

    async onSubmit(form: NgForm) {
        var inputValue = document.getElementById('coupon')["value"];
        //alert(inputValue);
        if (inputValue == 'free') {
            //alert('1');
            //free
            console.log('Success!', 'free');
            let _stoken = 'free';
            // console.log("----------->>>>>", _stoken)
            this.dataService.createCharges(this.planAmount, _stoken, this.apiToken).subscribe((response: HttpResponse<any>) => {

                this.tostrService.success(response.body.message);
                this.router.navigate(['/sucessfull_member']);
                localStorage.removeItem('websiteCurrentUser');
                localStorage.removeItem('websiteToken');
            })
        }
        else if (inputValue == 'Jurislex2020' && this.planId == '7') {
            //alert('1');
            //free
            // console.log('Success!', 'Jurislex2020');
            let _stoken = 'Jurislex2020';
            // console.log("----------->>>>>", _stoken)
            this.dataService.createCharges(this.planAmount, _stoken, this.apiToken).subscribe((response: HttpResponse<any>) => {
                // console.log("response------------------>>>>>>", response)
                this.tostrService.success(response.body.message);
                this.router.navigate(['/sucessfull_member']);
                localStorage.removeItem('websiteCurrentUser');
                localStorage.removeItem('websiteToken');
            })
        }
        else if (inputValue == 'Jurislex_2020' && this.planId == '8') {
            //alert('1');
            //free
            console.log('Success!', 'Jurislex_2020');
            let _stoken = 'Jurislex_2020';
            // console.log("----------->>>>>", _stoken)
            this.dataService.createCharges(this.planAmount, _stoken, this.apiToken).subscribe((response: HttpResponse<any>) => {
                console.log("response------------------>>>>>>", response)
                this.tostrService.success(response.body.message);
                this.router.navigate(['/sucessfull_member']);
                localStorage.removeItem('websiteCurrentUser');
                localStorage.removeItem('websiteToken');
            })
        }
        else if (inputValue == 'Jurislex@2020' && this.planId == '9') {
            //alert('1');
            //free
            // console.log('Success!', 'Jurislex@2020');
            let _stoken = 'Jurislex@2020';
            // console.log("----------->>>>>", _stoken)
            this.dataService.createCharges(this.planAmount, _stoken, this.apiToken).subscribe((response: HttpResponse<any>) => {
                // console.log("response------------------>>>>>>", response)
                this.tostrService.success(response.body.message);
                this.router.navigate(['/sucessfull_member']);
                localStorage.removeItem('websiteCurrentUser');
                localStorage.removeItem('websiteToken');
            })
        }
        else if (inputValue == 'Jurislex@@2021' && this.planId == '9') {
            const { token, error } = await stripe.createToken(this.card);

            if (error) {
                console.log('Something is wrong:', error);
            } else {

                // console.log('Success!', token);
                let _stoken = token.id;
                // console.log("----------->>>>>", _stoken)
                this.dataService.createCharges(1, _stoken, this.apiToken).subscribe((response: HttpResponse<any>) => {
                    // console.log("response------------------>>>>>>", response)
                    if (response.body.status_code == 1) {
                        let errors = response.body.data;
                        let errorMessage = '';
                        for (let error in errors) {
                            errorMessage += errors[error][0] + '\n';
                        }
                        this.tostrService.error(errorMessage);
                        return;
                    }
                    else {

                        this.tostrService.success(response.body.message);
                        this.router.navigate(['/sucessfull']);
                        localStorage.removeItem('websiteCurrentUser');
                        localStorage.removeItem('websiteToken');
                    }

                })

            }
        }
        else {

            const { token, error } = await stripe.createToken(this.card);

            if (error) {
                console.log('Something is wrong:', error);
            } else {

                // console.log('Success!', token);
                let _stoken = token.id;
                // console.log("----------->>>>>", _stoken)
                this.dataService.createCharges(this.planAmount, _stoken, this.apiToken).subscribe((response: HttpResponse<any>) => {
                    // console.log("response------------------>>>>>>", response)
                    if (response.body.status_code == 1) {
                        let errors = response.body.data;
                        let errorMessage = '';
                        for (let error in errors) {
                            errorMessage += errors[error][0] + '\n';
                        }
                        this.tostrService.error(errorMessage);
                        return;
                    }
                    else {

                        this.tostrService.success(response.body.message);
                        this.router.navigate(['/sucessfull']);
                        localStorage.removeItem('websiteCurrentUser');
                        localStorage.removeItem('websiteToken');
                    }
                })

            }
        }


    }

    pay(n) {

        if (n == '1') {

            document.getElementById('div1').style.display = 'block';
            document.getElementById('div2').style.display = 'none';
            var element = <HTMLInputElement>document.getElementById("check1");
            element.checked = true;
            var element2 = <HTMLInputElement>document.getElementById("check2");
            element2.checked = false;

        }
        else {

            document.getElementById('div1').style.display = 'none';
            document.getElementById('div2').style.display = 'block';
            var element = <HTMLInputElement>document.getElementById("check1");
            element.checked = false;
            var element2 = <HTMLInputElement>document.getElementById("check2");
            element2.checked = true;
        }
    }

    CouponChange() {
        var element = <HTMLInputElement>document.getElementById("coupon");
        var elementB = <HTMLInputElement>document.getElementById("btnS");
        var btnpaypal = <HTMLInputElement>document.getElementById("btnpaypal");

        // var txt = elementB.innerText;
        if (element.value == 'free' || element.value == 'Jurislex2020' || element.value == 'Jurislex_2020' || element.value == 'Jurislex@2020') {
            elementB.innerText = "Apply Coupon";
            btnpaypal.hidden = true;
        }
        else if (element.value == 'Jurislex@@2021' && this.planId == '9') {
            elementB.innerText = "Pay $1";
            btnpaypal.hidden = true;
        }
        else {
            elementB.innerText = "Pay $" + this.planAmount;
            btnpaypal.hidden = false;
        }

    }

    onCountryChange(event) {

        this.states = null;
        this.cities = null;
        this.dataService
            .getStates(this.payment.value.address_country)
            .subscribe((response: HttpResponse<any>) => {
                this.states = response.body.data;
                console.log(this.states);
            });
    }

    onStateChange(event) {
        this.cities = null;
        // console.log(this.addLawyerStep2.value, '8888');
        this.dataService
            .getCities(this.payment.value.address_state)
            .subscribe((response: HttpResponse<any>) => {
                this.cities = response.body.data;
                console.log(this.cities);
            });
    }

    //nextPrevStep4(n) {
    //    var inputValue = document.getElementById('coupon')["value"];
    //    //alert(inputValue);
    //    if (inputValue == '123') {
    //        alert('1');
    //    }
    //    else {
    //        alert('2');
    //    }
    //    alert('3');
    //}

    // checkMembership() {
    //   const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    //   return this.httpClient.get('http://40.121.65.234:83/ws/api/memberships', {
    //     observe: 'response',
    //     headers: headers
    //   });
    // }

    // createCharges(amount, _stoken, api_token) {
    //   const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    //   return this.httpClient.post('http://40.121.65.234:83/ws/api/create-charges', { amount, _stoken, api_token }, {
    //     observe: 'response',
    //     headers: headers
    //   });
    // }

}