import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'lawyer-top-lawyers',
  templateUrl: './top-lawyers.component.html',
  styleUrls: ['./top-lawyers.component.css']
})
export class TopLawyersComponent implements OnInit {
  constructor(private dataService: DataService, private title: Title, private meta: Meta) { }
  focusFlag: Boolean = false;
  p;
  searchResult = [];
  array: any = [
    {
      name: 'Rajkish',
      imagePath: '',
      description: ''
    },
    {
      name: 'Rajkish',
      imagePath: '',
      description: ''
    },
    {
      name: 'Rajkish',
      imagePath: '',
      description: ''
    },
    {
      name: 'Rajkish',
      imagePath: '',
      description: ''
    },
    {
      name: 'Rajkish',
      imagePath: '',
      description: ''
    }
  ];
  ngOnInit() {
    this.title.setTitle("Jurislex - Top Lawyer");
    this.meta.updateTag({ name: 'description', content: 'This page is about featured lawyers list.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });
    this.dataService
      .getTopLawyers()
      .subscribe((response: HttpResponse<any>) => {

        if (response.body.data) this.searchResult = response.body.data;
      });
  }
}
