import { Component, OnInit } from '@angular/core';
import {  Title } from '@angular/platform-browser';
@Component({
  selector: 'lawyer-mobile-success-page',
  templateUrl: './mobile-success-page.component.html',
  styleUrls: ['./mobile-success-page.component.css']
})
export class MobileSuccessPageComponent implements OnInit {

  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Jurislex - Success");    
  }

}
