
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

declare var $: any;

@Component({
  selector: 'lawyer-edit-collegue',
  templateUrl: './edit-collegue.component.html',
  styleUrls: ['./edit-collegue.component.css']
})
export class EditCollegueComponent implements OnInit {

  editCollegueForm: FormGroup;
  offices = null;
  submitted = false;
  specializations;
  areaRequired = false;
  show_pic = null;
  // imgRequired=false;
  user = null;
  img = null;
  currId = null;
  user_id = null;
  collegues = [];
  collegue = null;
  membership = null;

  constructor(private activatedRoute: ActivatedRoute, private dataService: DataService, private toastrService: ToastrService, private router: Router, private title: Title, private meta: Meta) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.currId = params[`id`]
    })
    this.title.setTitle("Jurislex - Edit-Collegue");
    this.meta.updateTag({ name: 'description', content: 'This page is for edit collegue of your firm.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });
    this.user_id = JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id ? JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id : null;
      //this.membership = JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.m ? JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id : null;

    this.getColleagueList();

    this.dataService.getSpecializations().subscribe((response: HttpResponse<any>) => {
      this.specializations = response.body.data;
    });
    this.dataService.getOffices(this.user_id).subscribe(
      (response: HttpResponse<any>) => {
        this.offices = response.body.data;
      });

  }

  getColleagueList() {
    this.dataService.spinnerShow();
    this.dataService.getCollegueList(this.user_id).subscribe(
      (response1: HttpResponse<any>) => {
        this.collegues = response1.body.data;
        var index = this.collegues.findIndex(obj => obj.id == this.currId);
        this.collegue = this.collegues[index];
        this.createForm();
        this.dataService.spinnerHide();
      });
  }

  createForm() {

    let pic_title = this.collegue.pic_title ? this.collegue.pic_title : '';
    let pic_fname = this.collegue.pic_fname ? this.collegue.pic_fname : '';
    let pic_mname = this.collegue.pic_mname ? this.collegue.pic_mname : '';
    let pic_lname = this.collegue.pic_lname ? this.collegue.pic_lname : '';
    let phone = this.collegue.phone ? this.collegue.phone : '';
      let fax = this.collegue.fax ? this.collegue.fax : '';
      let countrycode = this.collegue.countrycode ? this.collegue.countrycode : '';
      let faxcountrycode = this.collegue.faxcountrycode ? this.collegue.faxcountrycode : '';
    let main_firm_email = this.collegue.main_firm_email ? this.collegue.main_firm_email : '';
    let firm_website = this.collegue.firm_website ? this.collegue.firm_website : '';
    let pic_position = this.collegue.pic_position ? this.collegue.pic_position : '';
    let office_id = this.collegue.office_id ? this.collegue.office_id : '';
    let profile = this.collegue.profile ? this.collegue.profile : '';
    let languages = this.collegue.languages ? this.collegue.languages : '';
    let year_joined = this.collegue.year_joined ? this.collegue.year_joined : '';
    let professional_experience = this.collegue.professional_experience ? this.collegue.professional_experience : '';
    let affiliations = this.collegue.affiliations ? this.collegue.affiliations : '';
    let publications = this.collegue.publications ? this.collegue.publications : '';
    let education = this.collegue.education ? this.collegue.education : '';
      let seminars = this.collegue.seminars ? this.collegue.seminars : '';

      let facebook = this.collegue.facebook ? this.collegue.facebook : '';
      let linkedin = this.collegue.linkedin ? this.collegue.linkedin : '';
      let twitter = this.collegue.twitter ? this.collegue.twitter : '';
      let instagram = this.collegue.instagram ? this.collegue.instagram : '';
      let youtube = this.collegue.youtube ? this.collegue.youtube : '';
      
      
    this.show_pic = this.collegue.profile_pic ? this.collegue.profile_pic : null;
      this.img = this.collegue.profile_pic;
    

    this.editCollegueForm = new FormGroup({

      pic_title: new FormControl(pic_title, Validators.compose([
        Validators.required,
      ])),
      pic_fname: new FormControl(pic_fname, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z]{3,20}")
      ])),
      pic_mname: new FormControl(pic_mname, Validators.compose([
        Validators.pattern("^[a-zA-Z]+")
      ])),
      pic_lname: new FormControl(pic_lname, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z]{3,20}")
      ])),
        countrycode: new FormControl(countrycode, Validators.compose([
            Validators.required])),
      phone: new FormControl(phone, Validators.compose([
          Validators.required])),
        faxcountrycode: new FormControl(faxcountrycode, Validators.compose([])),
      fax: new FormControl(fax, Validators.compose([])),

      main_firm_email: new FormControl(main_firm_email, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")
      ])
      ),

      firm_website: new FormControl(firm_website, Validators.compose([
        
      ])),

      pic_position: new FormControl(pic_position, Validators.compose([
        
      ])
      ),
      office_id: new FormControl(office_id.toString(), Validators.compose([Validators.required,])),

      profile_pic: new FormControl(null),

      profile: new FormControl(profile, Validators.compose([
        Validators.required,
      ])
      ),

      languages: new FormControl(languages, Validators.compose([
        Validators.required,
      ])),

      year_joined: new FormControl(year_joined, Validators.compose([
        Validators.required,
        Validators.pattern("^[0-9]{4}")
      ])),

      professional_experience: new FormControl(professional_experience, Validators.compose([
        Validators.required,
          Validators.max(6000),
      ])),


      affiliations: new FormControl(affiliations, Validators.compose([
       
        Validators.max(50),
      ])),

      publications: new FormControl(publications, Validators.compose([
       
        Validators.max(1500),
      ])),

      education: new FormControl(education, Validators.compose([
        Validators.required,
        Validators.max(1500),
      ])),

      seminars: new FormControl(seminars, Validators.compose([
       
        Validators.max(1500),
      ])),

        facebook: new FormControl(facebook, Validators.compose([          
            Validators.max(250),
        ])),

        linkedin: new FormControl(linkedin, Validators.compose([
            Validators.max(250),
        ])),

        twitter: new FormControl(twitter, Validators.compose([
            Validators.max(250),
        ])),

        instagram: new FormControl(instagram, Validators.compose([
            
            Validators.max(250),
        ])),

        youtube: new FormControl(youtube, Validators.compose([
          
            Validators.max(250),
        ])),

    
        
    });
  }

  editProfile() {

    this.submitted = true;
    if (this.editCollegueForm.invalid) {
      return;
    }

    this.user = JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id ? JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id : null;

    let practice_area = '';
    //$('.practice_area .row .check-box').each(function () {
    //  if (
    //    $(this)
    //      .find('input')
    //      .is(':checked')
    //  ) {
    //    var text = $(this).find('p').context.innerText;
    //    practice_area += text + ',';
    //  }
    //});

    Object.assign(this.editCollegueForm.value, {
      id: parseInt(this.currId),
      user_id: this.user,
     // profile_pic: this.img,
      
      office_id: parseInt(this.editCollegueForm.value.office_id),
      fax: this.editCollegueForm.value.fax,
        phone: this.editCollegueForm.value.phone,
        countrycode: this.editCollegueForm.value.countrycode,
        faxcountrycode: this.editCollegueForm.value.faxcountrycode,

        facebook: this.editCollegueForm.value.facebook,
        linkedin: this.editCollegueForm.value.linkedin,
        twitter: this.editCollegueForm.value.twitter,
        instagram: this.editCollegueForm.value.instagram,
        youtube: this.editCollegueForm.value.youtube,
        profile_pic_org: this.img
    })

    //if (this.editCollegueForm.value.areas_of_practice.length == 0) {
    //  this.areaRequired = true;
    //  return;
    //}


    // if (this.editCollegueForm.value.profile_pic==null) {
    //   this.imgRequired=true;
    //   return;
    // }

    this.dataService.spinnerShow();
    this.dataService.editProfileStep1(this.editCollegueForm.value).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body.status_code === 0) {

          const step2 = {
            "id": parseInt(this.currId),
              user_id: this.user,
          }
          this.dataService.editProfileStep2(step2).subscribe(
            (response2: HttpResponse<any>) => {
                  this.dataService.spinnerHide();
                  console.log(response2.body.status_code);
              if (response2.body.status_code === 0) {
                  this.toastrService.success(response2.body.message);
              } else if (response2.body.status_code === 1) {
                  this.toastrService.error(response2.body.message);
              } else {
                  this.toastrService.error(response2.body.message);
              }
              this.router.navigate([`view-office-collegues`])
            }, error => {
              this.dataService.spinnerHide();
              this.toastrService.error(error.error['message']);
              this.router.navigate([`view-office-collegues`])
            }
          );

        } else if (response.body.status_code === 1) {
          this.dataService.spinnerHide();
          this.toastrService.error(response.body.message);
        } else {
          this.dataService.spinnerHide();
          this.toastrService.error(response.body.message);
        }
      }, error => {
        this.dataService.spinnerHide();
        this.toastrService.error(error.error['message']);
      }
    );

  }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {
      Object.assign(this.editCollegueForm.value, {
        profile_pic: myReader.result
      })
        this.img = myReader.result;

    }
    myReader.readAsDataURL(file);
    }




}
