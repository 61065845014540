import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { Event } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthServices } from './services/auth.service';
import { ErrorService } from './services/error.service';
// import { ChangeDetectionStrategy } from '@angular/compiler/src/core';
import { TranslateService } from '@ngx-translate/core';

declare let $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  slider_01 = 'https://jurislex.org/assets/images/slider-01.jpg';
  slider_02 = 'https://jurislex.org/assets/images/slider-02.jpg';
  slider_03 = 'https://jurislex.org/assets/images/slider-03.jpg';
  slider_04 = 'https://jurislex.org/assets/images/slider-04.jpg';

  currentPath: string;
  showSlider = false;
  showPage = false;
  isLoggedIn = false;
  onMobile = true
  errorPage: boolean = true;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private spinner: NgxSpinnerService,
    private authService: AuthServices,
    private errorService: ErrorService,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) {
    if (localStorage.getItem('prefered_language')) {
      let prefered_lng = localStorage.getItem('prefered_language');
      translate.setDefaultLang(prefered_lng);
    }
    else {
      translate.setDefaultLang('en');
    }
  }

  ngOnInit() {

    // $(window).load(function () {

    //   const buttons = document.getElementsByClassName('sharethis-inline-share-buttons')
    //   buttons[0].setAttribute('data-url', window.location.href)

    // });

    setTimeout(() => {
      this.errorService.getErrorpage().subscribe((data) => {
        if (data && data.error)
          this.errorPage = false;
        else
          this.errorPage = true;
      })

    }, 2000)

    // $('.bxslider').bxSlider({
    //   mode: 'fade',
    //   captions: true,
    //   auto: true
    // });
    // console.log('route inform', this.route['_routerState'].snapshot.url);
    this.router.events.subscribe((event: Event) => {

      // if (event['url'] && event['url'].match(/\/not-found.*/g)) {
      //   this.errorPage = true;
      //   return;
      // }
      // else {
      //   this.errorPage = false;
      // }
      // console.log((<NavigationEnd>event).url);
      // if ((<NavigationEnd>event).url.match(/\/not-found.*/g)) {
      //   this.errorPage = false;
      // }



      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0)

        $('select.select').each(function () {
          var title = $(this).attr('title');
          if ($('option:selected', this).val() != '')
            title = $('option:selected', this).text();
          $(this)
            .css({
              'z-index': 10,
              opacity: 0,
              '-khtml-appearance': 'none'
            })
            .after('<span class="select">' + title + '</span>')
            .change(function () {
              var val = $('option:selected', this).text();
              $(this)
                .next()
                .text(val);
            });
        });
        this.isLoggedIn = this.authService.isLoggedIn;

        if ((<NavigationEnd>event).url.match(/\/register-lawyer-mobile.*/g) || (<NavigationEnd>event).url.match(/\/payment-checkout-mobile.*/g)) {
          this.onMobile = false
        }


        if ((<NavigationEnd>event).url.match(/\/verify.*/g) || (<NavigationEnd>event).url.match(/\/register-lawyer-mobile.*/g) || (<NavigationEnd>event).url.match(/\/payment-checkout-mobile.*/g)

        ) {
          this.showPage = false;
          this.spinner.show();
        }
        else {
          this.showPage = true;
          this.spinner.hide();
        }
      }
    });
    this.currentPath = this.route['_routerState'].snapshot.url;
    if (this.currentPath == '') {
      this.showSlider = true;
    }
  }
  onActivate(event) {
    window.scroll(0, 0);
    // $(document).ready(function () {
    //   const buttons = document.getElementsByClassName('sharethis-inline-share-buttons')
    //   buttons[0].setAttribute('data-url', window.location.href)
    // });
  }



  //or document.body.scrollTop = 0;
  //or document.querySelector('body').scrollTo(0,0)

}


