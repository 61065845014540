// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   API_URL: 'https://jurislex.org/ws',
   //API_URL: 'http://40.121.65.234:8084/ws', //shared with client
   //API_URL: 'http://104.211.53.22:8084/ws', //new IP staging ignore it
  socialLoginApIKeys: {
    // facebookAppId: '1840537799363469',
    facebookAppId: '2396421283750703',  // provided by client
    linkedInAppId: '789f7f14ahoa8m'
  },
  payment: {
    paypal: {
      clientId: 'ASX-eJ-NYjNhS-xGRpIeiOAAnXkz8YmN3AuzagGBpODn4ztLQ2L3a6FTW9bgMLXLpEzveuoVY-Gk3nTY'
    }
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
