import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AboutUsComponent } from './about-us.component';
import { AboutUsRoutingModule } from './about-us-route-routing.module';

@NgModule({
  declarations: [AboutUsComponent],
  imports: [
    TranslateModule,
    CommonModule,
    AboutUsRoutingModule
  ]
})
export class AboutUsRouteModule { }
