import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ErrorService } from '../services/error.service';

@Component({
  selector: 'lawyer-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {

  constructor(private errorService: ErrorService, private router: Router, private title: Title, private meta: Meta) { }
  ngOnInit() {
    this.errorService.setErrorPage();

    this.title.setTitle("Error 404 | No page found");
    this.meta.updateTag({ name: 'description', content: 'Error 404 No page found regarding URL' });
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.errorService.clearErrorPage()
  }

}
