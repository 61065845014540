import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthServices } from '../services/auth.service';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'lawyer-view-office',
  templateUrl: './view-office.component.html',
  styleUrls: ['./view-office.component.css']
})
export class ViewOfficeComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private dataService: DataService, private title: Title, private meta: Meta) {
    this.activatedRoute.params.subscribe(params => {
      this.currId = params[`id`];
      this.lawyer_id = params[`lawyer_id`];
    })
    this.dataService.spinnerShow();
      this.getOffice();
      this.getLawyer(this.lawyer_id);
    }

    ShowAll = false;
    mapType = 'roadmap';
    showmap: Boolean = false;
  currId = null;
  offices = [];
  benefits = [];
  office = null;
  // user_id=null;
    collegues = [];
    gamesFormatted2 = [];
    lawyer_id = null;
    latitude = null;
    longitude = null;
    facebook = null;
    linkedin = null;
    twitter = null;
    instagram = null;
    youtube = null;
    practice = null;
    lawyer;

  ngOnInit() {
    this.title.setTitle("Jurislex - Office Detail");
    this.meta.updateTag({ name: 'description', content: 'This page is for view additional office of your firm.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });

  }

  getOffice() {
    this.dataService.getOffices(this.lawyer_id).subscribe(
      (response: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.benefits = response.body.benefits
        // console.log(this.benefits)
        this.offices = response.body.data;
        var index = this.offices.findIndex(obj => obj.id == this.currId);
        this.office = this.offices[index];
        this.getColleagueList();
      });
  }

    getColleagueList() {
        this.dataService.getCollegueList(this.lawyer_id).subscribe(
            (response1: HttpResponse<any>) => {
                this.dataService.spinnerHide();
                this.collegues = response1.body.data;

                this.gamesFormatted2 = [];
                var j = -1;
                for (var i = 0; i < this.collegues.length; i++) {
                    if (i % 3 == 0) {
                        j++;
                        this.gamesFormatted2[j] = [];
                        this.gamesFormatted2[j].push(this.collegues[i]);
                    }
                    else {
                        this.gamesFormatted2[j].push(this.collegues[i]);
                    }
                }
                console.log('this.collegues----?', response1.body)
            });
    }

  viewOffice(id) {
      this.router.navigate([`/view-office`, id.toString(), this.lawyer_id.toString()]);
      
      this.activatedRoute.params.subscribe(params => {
          this.currId = params[`id`];
          this.lawyer_id = params[`lawyer_id`];
      })
      this.dataService.spinnerShow();
      this.getOffice();
     
  }
  

  viewCollegue(id) {
    this.router.navigate([`../view-collegue`, id.toString(), this.lawyer_id.toString()]);
    }

    getLawyer(id) {
        this.dataService.getLawyer(id).subscribe((response: HttpResponse<any>) => {
            if (response.body.data.length > 0) {
                this.lawyer = response.body.data[0];
                console.log('lawyer--', this.lawyer)
                

                this.facebook = this.lawyer.facebook;
                this.linkedin = this.lawyer.linkedin;
                this.twitter = this.lawyer.twitter;
                this.instagram = this.lawyer.instagram;
                this.youtube = this.lawyer.youtube;
                this.practice = this.lawyer.areas_of_practice

                var map_url = this.lawyer.google_map_url;

                if (map_url) {
                    // console.log("map_url", map_url)
                    this.showmap = true;
                    var substring = map_url.substring(map_url.indexOf("/@") + 2, map_url.indexOf("/data=", map_url.indexOf("/@")))
                    var lat_lag_val = substring.split(",")
                    this.latitude = parseFloat(lat_lag_val[0]);
                    this.longitude = parseFloat(lat_lag_val[1]);


                }
                else { this.showmap = false; }
                console.log(this.practice);





            }
            else {
                this.router.navigate(['/lawyer-search']);
            }

        }, (error: any) => {
            this.router.navigate(['/lawyer-search']);
        });
    }

    ChangeDivHeight() {


        if (this.ShowAll == true) {
            this.ShowAll = false;
            document.getElementById('btnAll').hidden = false;
        }
        else {
            this.ShowAll = true;
            document.getElementById('btnAll').hidden = true;

        }


    }
}
