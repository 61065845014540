import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';
import { Meta, Title } from '@angular/platform-browser';
import { HttpResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { FormGroup, FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'lawyer-advance-search-new',
  templateUrl: './advance-search-new.component.html',
  styleUrls: ['./advance-search-new.component.css']
})
export class AdvanceSearchNewComponent implements OnInit {
  search = null;

  currPage = 1
  searchResult = null
  searchResult2 = null;
  searchResult3 = null;
  pagination_data = null;
  dropdownData = null;
  countries = null;
  states = null;
  subcategory = null;
  memberships = null;
  areas_of_practices = null;
  total_lawyers_wordwides = null;
  firm_founded_years = null;
  total_offices = null;
  languages = null;
  searchForm = null;
  isSelected: boolean = true;
  filter: boolean = false;

  constructor(private location: Location, private currentRoute: ActivatedRoute, private dataService: DataService, private router: Router, private title: Title, private meta: Meta) {
    this.currentRoute.params.subscribe(params => {
      this.search = params['text'];
      this.currPage = 1;
      this.ngOnInit();
    });
  }

  ngOnInit() {
    this.searchData(this.currPage);
    this.getDropdownData()
    this.createForm()
    this.title.setTitle("Jurislex - Advance Search");
    this.meta.updateTag({ name: 'description', content: 'This page is for advance search in jurislex' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal, advance search' });
  }

  createForm() {
    this.searchForm = new FormGroup({
      country_id: new FormControl(null),
      state_id: new FormControl(null),
      rating: new FormControl(null),
      memberships: new FormControl(null),
      areas_of_practice: new FormControl(null),
      areas_of_practice_sub: new FormControl(null),
      total_lawyers_wordwide: new FormControl(null),
      firm_founded_year: new FormControl(null),
      total_office: new FormControl(null),
      language: new FormControl(null),
    })

  }

  getDropdownData() {
    this.dataService.getDropdownData().subscribe(
      (response: HttpResponse<any>) => {
        this.dropdownData = response.body.data;
        this.countries = this.dropdownData.countries;
        this.states = this.dropdownData.states;
        this.memberships = this.dropdownData.memberships;
        this.areas_of_practices = this.dropdownData.practice_areas;
        this.total_lawyers_wordwides = this.dropdownData.total_lawyers;
        this.firm_founded_years = [
          { year: 1950 }, { year: 1951 }
          , { year: 1952 }, { year: 1953 }
          , { year: 1954 }, { year: 1955 }
          , { year: 1956 }, { year: 1957 }
          , { year: 1958 }, { year: 1959 }
          , { year: 1960 }, { year: 1961 }
          , { year: 1962 }, { year: 1963 }
          , { year: 1964 }, { year: 1965 }
          , { year: 1966 }, { year: 1967 }
          , { year: 1968 }, { year: 1969 }
          , { year: 1970 }, { year: 1971 }
          , { year: 1972 }, { year: 1973 }
          , { year: 1974 }, { year: 1975 }
          , { year: 1976 }, { year: 1977 }
          , { year: 1978 }, { year: 1979 }
          , { year: 1980 }, { year: 1981 }
          , { year: 1982 }, { year: 1983 }
          , { year: 1984 }, { year: 1985 }
          , { year: 1986 }, { year: 1987 }
          , { year: 1988 }, { year: 1989 }
          , { year: 1990 }, { year: 1991 }
          , { year: 1992 }, { year: 1993 }
          , { year: 1994 }, { year: 1995 }
          , { year: 1996 }, { year: 1997 }
          , { year: 1998 }, { year: 1999 }
          , { year: 2000 }, { year: 2001 }
          , { year: 2002 }, { year: 2003 }
          , { year: 2004 }, { year: 2005 }
          , { year: 2006 }, { year: 2007 }
          , { year: 2008 }, { year: 2009 }
          , { year: 2010 }, { year: 2011 }
          , { year: 2012 }, { year: 2013 }
          , { year: 2014 }, { year: 2015 }
          , { year: 2016 }, { year: 2017 }
          , { year: 2018 }, { year: 2019 }, { year: 2020 }
        ];
        this.total_offices = this.dropdownData.total_office;
        this.languages = this.dropdownData.languages;
        // console.log(this.memberships)        
      }
      , (error: any) => {
      });
  }

  viewProfile(event, lawyer) {
    // this.router.navigate(['/lawyer-profile', lawyer]);
    this.router.navigate([]).then((result) => {
      window.open('lawyer-profile/' + lawyer, '_blank');
    });
  }

  onCountryChange(event) {
    this.dataService.spinnerShow();
    this.states = null;
    this.dataService
      .getStatesForSearch(this.searchForm.value.country_id)
      .subscribe((response: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.states = response.body.data.states;
        console.log(response.body.data.states)
      });
  }

  onCategoryChange(event) {
    this.dataService.spinnerShow();
    this.subcategory = null;
    this.dataService
      .getSubCategory(this.searchForm.value.areas_of_practice)
      .subscribe((response: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.subcategory = response.body.data.states;
        console.log(this.subcategory)
      });
  }



  searchData(pagess) {
    this.filter = false;
    this.subcategory = null;
    this.dataService.spinnerShow();
    // console.log("this.search",this.search)
    this.dataService.getAdvanceSearch(pagess, this.search).subscribe(
      (response: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.searchResult = response.body.data;
        this.pagination_data = response.body.pagination_data
        // console.log("---", this.searchResult)
      }
      , (error: any) => {
        console.log("error", error)
        this.dataService.spinnerHide();
      });

    // this.dataService.getAdvanceSearchGold(pagess, this.search).subscribe(
    //     (response: HttpResponse<any>) => {
    //         this.dataService.spinnerHide();
    //         this.searchResult2 = response.body.data;
    //         this.pagination_data = response.body.pagination_data
    //         // console.log("---", this.searchResult)
    //     }
    //     , (error: any) => {
    //         console.log("error", error)
    //         this.dataService.spinnerHide();
    //     });

    // this.dataService.getAdvanceSearchSilver(pagess, this.search).subscribe(
    //     (response: HttpResponse<any>) => {
    //         this.dataService.spinnerHide();
    //         this.searchResult3 = response.body.data;
    //         this.pagination_data = response.body.pagination_data
    //         // console.log("---", this.searchResult)
    //     }
    //     , (error: any) => {
    //         console.log("error", error)
    //         this.dataService.spinnerHide();
    //     });

    // this.dataService.getAdvanceSearch(pagess, this.search).subscribe(
    //     (response: HttpResponse<any>) => {
    //         this.dataService.spinnerHide();
    //         this.searchResult = response.body.data;
    //         this.pagination_data = response.body.pagination_data
    //         // console.log("---", this.searchResult)
    //     }
    //     , (error: any) => {
    //         console.log("error", error)
    //         this.dataService.spinnerHide();
    //     });
  }


  searchFilter(page) {
    this.filter = true;
    this.dataService.spinnerShow();
    if (!this.search) { this.search = null }
    this.dataService.getfilterSearch(this.searchForm.value, this.search, page).subscribe(
      (response: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.searchResult = response.body.data;
        this.pagination_data = response.body.pagination_data
        console.log(this.searchResult)
      }
      , (error: any) => {
        this.dataService.spinnerHide();
      });
  }

  changePage(page) {
    this.currPage = page;
    if (this.filter == false) {
      // console.log("Filter False")
      this.searchData(page)
    }
    if (this.filter == true) {
      // console.log("filter True")
      this.searchFilter(page)
    }
    window.scrollTo({
      top: 200,
      behavior: 'smooth',
    });
  }
}
