import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddLawyerComponent } from './add-lawyer.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { LawyerSignupRoutingModule } from './lawyer-signup-routing.module';

@NgModule({
  declarations: [AddLawyerComponent],
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule, ReactiveFormsModule,
    NgMultiSelectDropDownModule,
    LawyerSignupRoutingModule
  ]
})
export class LawyerSignupModule { }
