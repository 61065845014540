import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
declare let $: any;
@Component({
  selector: 'lawyer-membership-upgrade',
  templateUrl: './membership-upgrade.component.html',
  styleUrls: ['./membership-upgrade.component.css']
})
export class MembershipUpgradeComponent implements OnInit {

  constructor(private dataService: DataService, private cd: ChangeDetectorRef, private title: Title, private meta: Meta) { }
  plans;
  apiToken;
  error: string;
  // max_lawyers: string;
  // max_offices: string;
  benifits = {
    1: 'Basic Information',
    2: 'Listing Title',
    3: 'Address',
    4: 'Phone Number',
    5: 'Unlimited Firm Description',
    6: 'Full Contact Information',
    7: 'All Practice Areas',
    8: 'Logo and Pictures',
    9: 'Brief Firm Overview',
    10: 'Allow Contact us',
    11: 'Access to Your Website',
    12: 'Social Network Links',
    13: 'Article Submissions',
      14: 'Add Your News',
      16: 'Featured Listing',
      17: 'Top Featured Listing',
      18: 'Jurislex verification',
      19: 'Jurislex Certification',
      20: 'Lead Generate from Jurislex',
      21: 'Video Submission',
      22: 'Tender Notification'
  }
  ngOnInit() {
    this.title.setTitle("Jurislex - Upgrade Plan");
    this.meta.updateTag({ name: 'description', content: 'Please purchase your membership via Basic free plan, premium plan, gold plan.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });
    this.dataService.getPlans().subscribe((response: HttpResponse<any>) => {

      if (response.body.status_code == 0) {
        this.plans = response.body.data;
        for (var i = 0; i < this.plans.length; i++) {
          let max_offices = this.plans[i].max_offices
          let max_lawyers = this.plans[i].max_lawyers

          let benefitsArray = this.plans[i].benefits.split(',')
          let arr = []
          for (var j = 0; j < benefitsArray.length; j++) {
            arr.push(this.benifits[benefitsArray[j]])
          }
          this.plans[i].benefits = arr
          this.plans[i].benefits.push(`${max_offices} Offices and ${max_lawyers} Lawyers`)
        }


      }
    });

    this.apiToken = JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.api_token ? JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.api_token : null;

    $(window).load(function () {
      setTimeout(function () {
        var windWdth = $(window).width()
        if (windWdth > 767) {
          var liLength = $('.membership_container ul li').length;
          var proBoxWidth = 300 * liLength;

          if (liLength > 4) {
            $('.membership_container').addClass('extra-product');
            $('.membership_container ul li').css({ 'width': 300 });
            $('.membership_container ul').css({ 'width': proBoxWidth + 10 });
          }
        }
      }, 400)
    })

  }



}
