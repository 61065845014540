import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForgotUserComponent } from './forgot-user.component';
import { ForgotUserRoutingModule } from './forgot-user-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxCaptchaModule } from 'ngx-captcha';
@NgModule({
  declarations: [ForgotUserComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule, ReactiveFormsModule,
    NgxCaptchaModule,
    ForgotUserRoutingModule
  ]
})
export class ForgotUserModule { }
