import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthServices } from '../services/auth.service';
import { Meta, Title } from '@angular/platform-browser';
//import { NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';

declare let $: any;
declare var hljs: any;

@Component({
  selector: 'view-office-2',
  templateUrl: './view-office-2.component.html',
  styleUrls: ['./view-office-2.component.css']
})
export class ViewOffice2Component implements OnInit {
    public readonly siteKey = '6LfGxPEUAAAAAAHTrNk8UsRtdrGVzRY6AAIgKrNW';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
    public captchaResponse?: string;
   
    
    ShowAll = false;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  public useGlobalDomain: boolean = false;
  submitted = false;
  @ViewChild('captchaElem', { static: false }) captchaElem: any;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;
  constructor(
    private dataService: DataService,
    private currentRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private authService: AuthServices,
    private router: Router, private title: Title, private meta: Meta
  ) {
    this.currentRoute.params.subscribe(params => {
        this.currId = params[`id`];
        this.lawyer_id = params[`lawyer_id`];
        //this.getOffice();
        this.getLawyer(this.lawyer_id);
      


    });
  }
    facebook = null;
    linkedin = null;
    twitter = null;
    instagram = null;
    youtube = null;


  latitude = null;
  longitude = null;
  mapType = 'roadmap';
  showmap: Boolean = false;
    currId = null;
    lawyer_id = null;
  inputName: string;
  id;
  sendMailLawyer: FormGroup;
  // lawyerProfile: FormGroup;
  lawyer
  countries;
  states;
  comment;
    cities;
    
  public ctrl = 0;
  readonly: Boolean = false;
  isLoggedIn;
  alreadyRated = false;
  isUser: Boolean = false;
    offices = [];
    gamesFormatted = [];
    

    office = null;
    collegues = [];
    gamesFormatted2 = [];
  create_form = true;
  user = null;
  user_id
  profile_pic = '../assets/images/no-image-found.jpeg';
  // mail: any = {
  // }
  attachement;
  let;
  lng;
  recaptcha;
  ngOnInit() {


    this.isLoggedIn = this.authService.isLoggedIn;
    this.user_id = (localStorage.getItem(`websiteCurrentUser`)) ? JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data : null;

    if (this.user_id) {
      this.create_form = false;
      this.dataService.getUser(this.user_id.id).subscribe((response: HttpResponse<any>) => {
        if (response.body.data.length > 0) {
          this.user = response.body.data[0];
          this.create_form = true;
          // console.log("user-->", this.user)
          this.createForm()
          this.isUser = this.user_id['role'] === 2
        }
      });

    }
    else {
      this.createForm()
    }

    this.dataService.spinnerShow();
    this.dataService.getCountries().subscribe((response: HttpResponse<any>) => {
      this.countries = response.body.data;
        this.getOffice();
       
    });


      $(document).ready(function () {
         
      })
    // $(document).ready(function() {
    //   $('select.select').each(function() {
    //     var title = $(this).attr('title');
    //     if ($('option:selected', this).val() != '')
    //       title = $('option:selected', this).text();
    //     $(this)
    //       .css({ 'z-index': 10, opacity: 0, '-khtml-appearance': 'none' })
    //       .after('<span class="select">' + title + '</span>')
    //       .change(function() {
    //         let val = $('option:selected', this).text();
    //         $(this)
    //           .next()
    //           .text(val);
    //       });
    //   });
    // });



  }



  getOffice() {
    this.dataService.getOffices(this.lawyer_id).subscribe(
      (response: HttpResponse<any>) => {
        this.dataService.spinnerHide();
            this.offices = response.body.data;
            console.log('this.offices----?', response.body)
            this.gamesFormatted = [];
            var j = -1;
            for (var i = 0; i < this.offices.length; i++) {
                if (i % 3 == 0) {
                    j++;
                    this.gamesFormatted[j] = [];
                    this.gamesFormatted[j].push(this.offices[i]);
                }
                else {
                    this.gamesFormatted[j].push(this.offices[i]);
                }
            }
            var index = this.offices.findIndex(obj => obj.id == this.currId);
            this.office = this.offices[index];
            

            var map_url = this.office.google_map_url;
             console.log("map_url", map_url)
            if (map_url) {
                
                this.showmap = true;
                var substring = map_url.substring(map_url.indexOf("/@") + 2, map_url.indexOf("/data=", map_url.indexOf("/@")))
                var lat_lag_val = substring.split(",")
                this.latitude = parseFloat(lat_lag_val[0]);
                this.longitude = parseFloat(lat_lag_val[1]);


            }
            else { this.showmap = false; }

              this.profile_pic = this.office['firm_logo'];


            this.title.setTitle("Jurislex - Profile of " + this.office.firm_headline_title);
            this.meta.updateTag({ name: 'description', content: this.office.firm_headline_title });
            this.meta.updateTag({ name: 'keywords', content: this.office.firm_practice_description });

        this.getColleagueList();
      });
  }
  getColleagueList() {
    this.dataService.getCollegueList(this.lawyer_id).subscribe(
      (response1: HttpResponse<any>) => {
        this.dataService.spinnerHide();
            this.collegues = response1.body.data;

            this.gamesFormatted2 = [];
            var j = -1;
            for (var i = 0; i < this.collegues.length; i++) {
                if (i % 3 == 0) {
                    j++;
                    this.gamesFormatted2[j] = [];
                    this.gamesFormatted2[j].push(this.collegues[i]);
                }
                else {
                    this.gamesFormatted2[j].push(this.collegues[i]);
                }
            }
        console.log('this.collegues----?', response1.body)
      });
  }

  // uploadDocument(event: any) {
  //   if (event.target.files && event.target.files[0]) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       this.attachement = event.target.files[0];
  //     };
  //     reader.readAsDataURL(event.target.files[0]);
  //   }
  // }

    //onSlide(slideEvent: NgbSlideEvent) {
    //    console.log(slideEvent.source);
    //    console.log(NgbSlideEventSource.ARROW_LEFT);
    //    console.log(slideEvent.paused);
    //    console.log(NgbSlideEventSource.INDICATOR);
    //    console.log(NgbSlideEventSource.ARROW_RIGHT);
    //}


   


  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    this.dataService.spinnerShow();
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (e) => {

      this.attachement = myReader.result;
      this.dataService.spinnerHide();
    }
    myReader.readAsDataURL(file);
  }

  // contactLawyer(event) {

  //   if (this.mail.country && this.mail.state && this.mail.city) {
  //     let country = this.mail.country
  //     let state = this.mail.state
  //     let city = this.mail.city
  //     const data = {
  //       attachement: this.attachement,
  //       name: this.mail.name,
  //       phone: this.mail.phone,
  //       email: this.mail.email,
  //       address: this.mail.address,
  //       country: country,
  //       state: state,
  //       city: city,
  //       message: this.mail.message,
  //       lawyer_id: this.id
  //     }

  //     this.dataService.sendMailToLawyer(data).subscribe((response: HttpResponse<any>) => {
  //       console.log(response);
  //       if (response.body.status_code === 1)
  //         this.toastrService.error(response.body.message)
  //       else if (response.body.status_code === 0) {
  //         this.toastrService.success(response.body.message)
  //         this.mail = {};

  //       }
  //     })
  //   }

  // }

  submitRating(event) {
    if (JSON.parse(localStorage.getItem('websiteCurrentUser')).data) {
      if (!this.comment) {
        this.toastrService.error("Please write comment !");
        return;
      }
      const user = JSON.parse(localStorage.getItem('websiteCurrentUser')).data.id;
      const data = {
        user_id: user,
        rate: this.ctrl,
        lawyer_id: this.id,
        comment: this.comment
      };

      this.dataService.submitRating(data).subscribe((response: HttpResponse<any>) => {
        // console.log(response);
        if (response.body.status_code === 0) {
          this.getLawyerRatings(this.id);
          this.toastrService.success(response.body.message);
        }
        else {
          this.getLawyerRatings(this.id);

          this.toastrService.error(response.body.message);

        }

      });
    }
  }
  getLawyer(id) {
    this.dataService.getLawyer(id).subscribe((response: HttpResponse<any>) => {
      if (response.body.data.length > 0) {
        this.lawyer = response.body.data[0];
        console.log('lawyer--', this.lawyer)
         

          this.facebook = this.lawyer.facebook;
          this.linkedin = this.lawyer.linkedin;
          this.twitter = this.lawyer.twitter;
          this.instagram = this.lawyer.instagram;
          this.youtube = this.lawyer.youtube;

       


   
        

      }
      else {
        this.router.navigate(['/lawyer-search']);
      }


        //if (this.lawyer['profile_pic']) { this.profile_pic = this.lawyer['profile_pic']; }
        //else {
          
        //}

      //if (this.lawyer['firm_logo']) { this.profile_pic = this.lawyer['firm_logo']; }
      //else {
      //  this.profile_pic = this.lawyer['profile_pic'];
      //}

       

       
        

    }, (error: any) => {
      this.router.navigate(['/lawyer-search']);
    });
  }




  getLawyerRatings(id) {

    if (this.authService.isLoggedIn) {
      if (JSON.parse(localStorage.getItem('websiteCurrentUser')).data) {
        const user = JSON.parse(localStorage.getItem('websiteCurrentUser')).data.id;

        const data = {
          lawyer_id: this.id,
          user_id: user
        };
        this.dataService.getParticularLawyerRatings(data).subscribe((response: HttpResponse<any>) => {

          if (response.body.data.length > 0) {
            this.ctrl = response.body.data[0].rating;
            this.comment = response.body.data[0].comment
            this.alreadyRated = true;
            this.readonly = true;

          }
          else {
            this.alreadyRated = false;
            this.readonly = false;
            if (!this.isUser)
              this.readonly = true
          }

        });
      }
    } else {
      this.dataService.getLawyerRatings(id).subscribe((response: HttpResponse<any>) => {
        if (response.body.data.length > 0) {
          this.ctrl = response.body.data[0].average_rating;

          this.readonly = true;

        }

      });
    }
  }
  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

  changeSize(size: 'compact' | 'normal'): void {
    this.size = size;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  setLanguage(): void {
    this.lang = this.langInput.nativeElement.value;
  }

  setUseGlobalDomain(use: boolean): void {
    this.useGlobalDomain = use;
  }

  getCurrentResponse(): void {
    const currentResponse = this.captchaElem.getCurrentResponse();
    if (!currentResponse) {
      alert('There is no current response - have you submitted captcha?');
    } else {

      alert(currentResponse);
    }
  }
  ngAfterViewInit(): void {
    this.highlight();
  }

  getResponse(): void {
    const response = this.captchaElem.getResponse();
    if (!response) {
      alert('There is no response - have you submitted captcha?');
    } else {
      alert(response);
    }
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }

  private highlight(): void {
    const highlightBlocks = document.getElementsByTagName('code');
    for (let i = 0; i < highlightBlocks.length; i++) {
      const block = highlightBlocks[i];
      hljs.highlightBlock(block);
    }
  }




  createForm() {

    // this.mail.name = this.user.name;
    // this.mail.email = this.user.email;
    // this.mail.phone = this.user.phone;
    // this.mail.address = this.user.address;
    // this.mail.country = this.user.country_id;
    // this.mail.state = this.user.state_id;
    // this.mail.city = this.user.city_id;

    // this.lawyerProfile = new FormGroup({
    //   country: new FormControl('', []),
    //   state: new FormControl('', []),
    //   city: new FormControl('', [])
    // });

    this.sendMailLawyer = new FormGroup({
      name: new FormControl(this.user ? this.user.name : null, Validators.compose([
        Validators.required
      ])),

      address: new FormControl(null, Validators.compose([
        Validators.required
      ])),

      email: new FormControl(this.user ? this.user.email : null, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")
      ])),

      phone: new FormControl(this.user ? this.user.phone : null, Validators.compose([
        Validators.required
      ])),

      country: new FormControl(null, Validators.compose([
        Validators.required
      ])),

      state: new FormControl(null, Validators.compose([
        Validators.required
      ])),

      city: new FormControl(null, Validators.compose([
        Validators.required
      ])),

      message: new FormControl(null, Validators.compose([
        Validators.required
      ])),


      // recaptcha: new FormControl('', [Validators.required])
      recaptcha: new FormControl('')


    });

  }

  sendMailToLawyer() {
    this.submitted = true;
     console.log(this.sendMailLawyer.value)
    //if (this.sendMailLawyer.invalid) {
    //  return;
    //}

    const data = {
      attachement: this.attachement,
      name: this.sendMailLawyer.value.name,
      phone: this.sendMailLawyer.value.phone,
      email: this.sendMailLawyer.value.email,
      address: this.sendMailLawyer.value.address,
      country: this.sendMailLawyer.value.country,
      state: this.sendMailLawyer.value.state,
      city: this.sendMailLawyer.value.city,
      message: this.sendMailLawyer.value.message,
      lawyer_id: this.lawyer_id
    }
    console.log("request-->", data)
    this.dataService.spinnerShow()
    this.dataService.sendMailToLawyer(data).subscribe((response: HttpResponse<any>) => {
       console.log(response);
      this.dataService.spinnerHide()
      if (response.body.status_code === 1) {
        this.toastrService.error(response.body.message)
      }
      else if (response.body.status_code === 0) {
        this.toastrService.success(response.body.message)
        this.sendMailLawyer.reset();
        this.submitted = false;
      }
    })

  }

  onCountryChange(event) {

    this.states = null;
    this.dataService
      .getStates(this.sendMailLawyer.value.country)
      .subscribe((response: HttpResponse<any>) => {
        this.states = response.body.data;
        // console.log(this.states);
      });
  }
  onStateChange(event) {

    this.cities = null;
    this.dataService
      .getCities(this.sendMailLawyer.value.state)
      .subscribe((response: HttpResponse<any>) => {
        this.cities = response.body.data;
        // console.log(this.cities);
      });
  }

  viewOffice(office_id) {
    this.router.navigate([`../view-office-2`, office_id.toString(), this.lawyer_id.toString()]);
    this.ngOnInit();
  }

  viewCollegue(collegue_id) {
    this.router.navigate([`../view-collegue`, collegue_id.toString(), this.lawyer_id.toString()]);
    }


    ChangeDivHeight() {


        if (this.ShowAll == true) {
            this.ShowAll = false;
            document.getElementById('btnAll').hidden = false;
        }
        else {
            this.ShowAll = true;
            document.getElementById('btnAll').hidden = true;
            
        }
        
       
    }
}
