import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormGroupName
} from '@angular/forms';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { PasswordValidation } from '../validators/password-validation';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'mobile-lawyer-page',
  templateUrl: './mobile-lawyer-page.component.html',
  styleUrls: ['./mobile-lawyer-page.component.css']
})
export class MobileLawyerPageComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private toastrService: ToastrService,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private translate: TranslateService,
    private title: Title, private meta: Meta
  ) {
    this.currentRoute.params.subscribe(params => {
      this.id = params['id'];
      if (params['language'])
      {
        this.language = params['language'];
      }
      else{
        this.language = 'en';
      }
      console.log(this.id, this.language)

      this.useLanguage(this.language) 

      // this.searchFirm("Lawyer","Israel")
    });
  }
  id
  clicked = true;
  language='en';
  currentTab = 0; // Current tab is set to be the first tab (0)
  addLawyerStep4: FormGroup;
  countries: [any];
  states: [any];
  cities: [any];
  firm_logo: any;
  specializations;
  ngOnInit() {
    this.title.setTitle("Jurislex - Lawyer");    
    this.createForm();
    this.dataService.getSpecializations().subscribe((response: HttpResponse<any>) => {       
        this.specializations = response.body.data;
      });
    this.dataService.getCountries().subscribe((response: HttpResponse<any>) => {
      this.countries = response.body.data;    
      this.dataService.spinnerHide(); 
    });



  }


  nextPrevStep4(n) {
    let practice_area = '';
    $('.practice_area .row .check-box').each(function () {
      if (
        $(this)
          .find('input')
          .is(':checked')
      ) {
        var text = $(this).find('p').context.innerText;
        practice_area += text + ',';
        console.log(practice_area);
      }
    });

    console.log(this.currentTab, n, this.addLawyerStep4);
    const data = Object.assign(this.addLawyerStep4.value, {
      user_id: this.id,
      areas_of_practice: practice_area
    })
    this.dataService.addLawyerStep4(data).subscribe((response: HttpResponse<any>) => {

      console.log('response', response);
      if (response.body.status_code == 0) {
        this.toastrService.success(
          'successful.'
        );
        window.location.href = 'https://www.google.com'
        document.getElementById('success').innerText = "success";
        // if (n > 0 && !this.validateForm()) return false;
      } else if (response.body.status_code == 1) {
        let errors = response.body.message;
        this.toastrService.error(errors);
        return;
      }
    },
      (error: any) => {
        console.log('error', error);
        this.toastrService.error(error.error.message);
        return;
      }
    )



  }



  createForm() {
    this.addLawyerStep4 = new FormGroup({
      firm_practice_description: new FormControl('', [Validators.required])
    });

  }

  useLanguage(language) {
    this.translate.use(language);
}

}
