import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'lawyer-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  constructor(
    private dataService: DataService,
    private tostrService: ToastrService, private title: Title, private meta: Meta
  ) { }
  user = {};
  id = null;
  editTrue = false;
  ngOnInit() {
    this.title.setTitle("Jurislex - User Profile");
    this.meta.updateTag({ name: 'description', content: 'This page is ueed view user detail.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });

    
    if (localStorage.getItem('websiteCurrentUser'))
      this.id = JSON.parse(localStorage.getItem('websiteCurrentUser')).data.id;
    let id;

    if (this.id) id = this.id;
    let role = JSON.parse(localStorage.getItem('websiteCurrentUser')).data.role
    if (role == 3){
      this.dataService.getLawyerProfile(id).subscribe((response: HttpResponse<any>) => {
        this.user = response.body.data[0];
        console.log("usersss", this.user);
      });
    }
    else{
      this.dataService.getUser(id).subscribe((response: HttpResponse<any>) => {
        this.user = response.body.data[0];
        console.log("usersss", this.user);
      });
    }

  }
  onSubmit(event) {
    for (let key in this.user) {

      if (!['name', 'address', 'phone', 'zipcode', 'email'].includes(key))
        delete this.user[key];
    }
    console.log(this.id);
    if (this.id)
      this.user['user_id'] = JSON.parse(
        localStorage.getItem('websiteCurrentUser')
      ).data.id;

    console.log("data", this.user); //{ name: "alex", email: "alex@test.com", phone: "454564545", address: "Delhi", zipcode: "202555", user_id: 349 }
    this.dataService
      .editCustomer(this.user)
      .subscribe((response: HttpResponse<any>) => {
        console.log(response.body);
        this.tostrService.success(response.body.message);
        this.editTrue = !this.editTrue;
      });
  }

  onEdit(event) {
    this.editTrue = !this.editTrue;
  }

  onCancel(event) {
    this.editTrue = !this.editTrue;
  }
}
