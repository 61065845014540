import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AdvanceSearchNewComponent } from './advance-search-new.component';
import { AdvanceSearchNewRoutingModule } from './advance-search-new-routing.module';
import { SharedModuleModule } from '../shared-module/shared-module.module'
import { TranslateModule } from '@ngx-translate/core';

import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [AdvanceSearchNewComponent],
  imports: [
    CommonModule,
    FormsModule, ReactiveFormsModule,
    AdvanceSearchNewRoutingModule,
    SharedModuleModule,
    TranslateModule,
    NgxPaginationModule
  ]
})
export class AdvanceSearchNewModule { }
