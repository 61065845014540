import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthServices } from '../services/auth.service';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
declare var hljs: any;

@Component({
  selector: 'lawyer-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
    public readonly siteKey = '6LfGxPEUAAAAAAHTrNk8UsRtdrGVzRY6AAIgKrNW';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  public useGlobalDomain: boolean = false;

  @ViewChild('captchaElem', { static: true }) captchaElem: any;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;



  constructor(
    private authService: AuthServices,
    private toastrService: ToastrService,
    private router: Router, private title: Title, private meta: Meta
  ) { }
  changePasswordForm: FormGroup;
  ngOnInit() {
    this.createForm();
    this.title.setTitle("Jurislex - Change Password");
    this.meta.updateTag({ name: 'description', content: 'This page is used for changing your password.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal, Password, change password' });
  }
  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

  changeSize(size: 'compact' | 'normal'): void {
    this.size = size;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  setLanguage(): void {
    this.lang = this.langInput.nativeElement.value;
  }

  setUseGlobalDomain(use: boolean): void {
    this.useGlobalDomain = use;
  }

  getCurrentResponse(): void {
    const currentResponse = this.captchaElem.getCurrentResponse();
    if (!currentResponse) {
      alert('There is no current response - have you submitted captcha?');
    } else {
      alert(currentResponse);
    }
  }
  ngAfterViewInit(): void {
    this.highlight();
  }

  getResponse(): void {
    const response = this.captchaElem.getResponse();
    if (!response) {
      alert('There is no response - have you submitted captcha?');
    } else {
      alert(response);
    }
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }

  private highlight(): void {
    const highlightBlocks = document.getElementsByTagName('code');
    for (let i = 0; i < highlightBlocks.length; i++) {
      const block = highlightBlocks[i];
      hljs.highlightBlock(block);
    }
  }
  onChangePassword() {
    if (this.changePasswordForm.valid) {
      const token = localStorage.getItem('websiteToken');
      // this.changePasswordForm.value.token = token;
      const data = Object.assign(
        { token: token },
        this.changePasswordForm.value
      );

      this.authService.changePassword(data).subscribe(
        (response: HttpResponse<any>) => {
          if (response.body.status_code == 0) {
            this.toastrService.success(response.body.message);
            localStorage.removeItem('websiteCurrentUser');
            localStorage.removeItem('websiteToken');
            this.router.navigate(['/signin-user']);
          }
          if (response.body.status_code == 1) {
            this.toastrService.error(response.body.message);

          }
        },
        (error: any) => {
          this.toastrService.error(error.error.message);
        }
      );
    }
  }
  createForm() {
    this.changePasswordForm = new FormGroup({
      password_old: new FormControl('', [
        Validators.required,
        Validators.minLength(6)
      ]),
      password_new: new FormControl('', [
        Validators.required,
        Validators.minLength(6)
      ]),
      password_new_confirmation: new FormControl('', [
        Validators.required,
        Validators.minLength(6)
      ]),
      recaptcha: new FormControl('', [Validators.required])

    });
  }
}
