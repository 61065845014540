import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateBlogComponent } from './create-blog.component';
import { CreateBlogRoutingModule } from './create-blog-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxEditorModule } from 'ngx-editor';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
@NgModule({
  declarations: [CreateBlogComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgxEditorModule,
    FormsModule, ReactiveFormsModule,
    CreateBlogRoutingModule
  ]
})
export class CreateBlogModule { }
