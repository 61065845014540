import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../../services/seo.service';
// import { LoadedRouterConfig } from '@angular/router/src/config';


declare let $: any;


@Component({
  selector: 'lawyer-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  
  customOptions: any = {
      loop: true,
      mouseDrag: true,
      touchDrag: true,
      pullDrag: true,
      dots: false,
      autoplay: true,
      // autoplayTimeout : 10,
      // autoplaySpeed : 40000,
      autoplayHoverPause  : true,
      navSpeed: 700,
      navText: ['', ''],
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 2
        },
        740: {
          items: 3
        },
        940: {
          items: 4
        }
      },
      nav: true
  }

  featured_firm_list = [
    {
      'id': 1,
      'logo' : "https://jurislex.org/ws/public/upload/lawyer/logo-id-238.png",
      'alt' : "Gibson, Dunn & Crutcher Image",
      'firm_name' : "Gibson, Dunn & Crutcher",
      'origin' : "United States, Denver",
      'profile_url' : "https://jurislex.org/lawyer-profile/238"
    },
    {
      'id': 2,
      'logo' : "https://jurislex.org/ws/public/upload/lawyer/logo-id-42.jpeg",
      'alt' : "D&C Legal Services Image",
      'firm_name' : "D&C Legal Services",
      'origin' : "Doha, Qatar",
      'profile_url' : "https://jurislex.org/lawyer-profile/42"
    },
    {
      'id': 3,
      'logo' : "https://jurislex.org/ws/public/upload/lawyer/logo-id-95.jpeg",
      'alt' : "Hogan Lovells Law Firm Image",
      'firm_name' : "Hogan Lovells Law Firm",
      'origin' : "United States, Washington",
      'profile_url' : "https://jurislex.org/lawyer-profile/95"
    },
    {
      'id': 4,
      'logo' : "https://jurislex.org/ws/public/upload/lawyer/logo-id-140.png",
      'alt' : "BIRKENMAIER & OBSER Rechtsanwälte Image",
      'firm_name' : "BIRKENMAIER & OBSER Rechtsanwälte",
      'origin' : "Germany",
      'profile_url' : "https://jurislex.org/lawyer-profile/140"
    },
    {
      'id': 5,
      'logo' : "https://jurislex.org/ws/public/upload/lawyer/logo-id-174.png",
      'alt' : "Arthur Cox Image",
      'firm_name' : "Arthur Cox",
      'origin' : "Ireland",
      'profile_url' : "https://jurislex.org/lawyer-profile/174"
    },    
    {
      'id': 6,
      'logo' : "https://jurislex.org/ws/public/upload/lawyer/logo-id-190.jpeg",
      'alt' : "Logan & Partners Image",
      'firm_name' : "Logan & Partners",
      'origin' : "Switzerland",
      'profile_url' : "https://jurislex.org/lawyer-profile/190"
    },    
    {
      'id': 7,
      'logo' : "https://jurislex.org/ws/public/upload/lawyer/logo-id-42.jpeg",
      'alt' : "D&C Legal Services Image",
      'firm_name' : "D&C Legal Services",
      'origin' : "Beirut Lebanon",
      'profile_url' : "https://jurislex.org/lawyer-profile/42"
    },    
    {
      'id': 8,
      'logo' : "https://jurislex.org/ws/public/upload/lawyer/logo-id-301.png",
      'alt' : "McDonald Carano Image",
      'firm_name' : "McDonald Carano",
      'origin' : "United States, Reno",
      'profile_url' : "https://jurislex.org/lawyer-profile/301"
    },
    {
      'id': 9,
      'logo' : "https://jurislex.org/ws/public/upload/lawyer/logo-id-143.png",
      'alt' : "Lexia Image",
      'firm_name' : "Lexia",
      'origin' : "Finland",
      'profile_url' : "https://jurislex.org/lawyer-profile/143"
    }
  ]

  countries = ['India', 'USA', 'China'];
  practice_areas = ["practice area", "Business Law", "Civil Rights Law", "Entertainment Law"]
  //sliders
    // slider_01 = 'https://jurislex.org/assets/images/slider-01.jpg';
    // slider_02 = 'https://jurislex.org/assets/images/slider-02.jpg';
    // slider_03 = 'https://jurislex.org/assets/images/slider-03.jpg';
    // slider_04 = 'https://jurislex.org/assets/images/slider-04.jpg';

    slider_01 = '../../../assets/images/slider-01.JPEG';
    slider_02 = '../../../assets/images/slider-02.JPEG';
    slider_03 = '../../../assets/images/slider-03.JPEG';
    slider_04 = '../../../assets/images/slider-04.JPEG';

    // My_Movie_web = 'https://jurislex.org/assets/videos/My-Movie.webm';
    // My_Movie_mp4 = 'https://jurislex.org/assets/videos/My-Movie.mp4';
    // My_Movie_ogg = 'https://jurislex.org/assets/videos/My-Movie.ogg';
    // My_Movie_mp4 = '../../../assets/videos/My-Movie.mp4';
    // img_1_png = 'https://jurislex.org/assets/images/img-1.png';
    // img_2_png = 'https://jurislex.org/assets/images/img-2.png';
    // img_3_png = 'https://jurislex.org/assets/images/dc_ls.png';
    My_Movie_mp4 = '../../../assets/videos/My-Movie.mp4';
    img_1_png = '../../../assets/images/lawyer-need.jpg';
    img_2_png = '../../../assets/images/lawyer-list.jpg';
    
  applyPopup: boolean = false;
  states;
  searchCountry = '';
  searchType = '';
 
  @ViewChild('videoPlayer', { static: true }) videoplayer: ElementRef;
  constructor(private meta: Meta, private title: Title, private dataService: DataService, private router: Router,private seoService: SeoService) { }

  ngOnInit() {   

    $(document).ready(function () {
      setTimeout(() => {
        $('.bxslider').bxSlider({
          mode: 'fade',
          captions: true,
          auto: true,
        });
      }, 500);
    })

  }
  ngAfterViewInit(): void {
    
   
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.seoService.createLinkForCanonicalURL();
    this.title.setTitle("Top Law Firms- International Legal Directory | Jurislex");
    this.meta.updateTag({ name: 'description', content: 'Jurislex, an International law firm directory. People seeking law services can find lawyers and law firms can list themselves to get more marketing exposure' });
    this.meta.updateTag({ name: 'keywords', content: 'bankruptcy attorney, injury attorney, premises liability attorney, divorce attorney, criminal defence attorney, DUI attorney, Copyright lawyers directory, uk legal firms directory, corporate lawyers firm directory, Trademark legal service directory, law firm listing portal, online corporate lawyer firms directory, business law firms directory online, online law firm listing portal, solicitor firm listing online portal, lawyer firm listing online portal, international lawyers online directory, legal services firms online directory, corporate legal firms directory, business legal service online directory, law firm directory online, legal services law firm directory, lawyers directory usa, country wise lawyers directory, Legal directory submissions, lawyers online directory, commercial solicitor directory, best attorney at law firm directory, attorney at law firm online directory, international lawyer directory, online lawyers platform, international business lawyer directory' });

    this.dataService.getCountries().subscribe((response: HttpResponse<any>) => {
      this.countries = response.body.data;

    });
    this.dataService.getPracticeArea().subscribe((response: HttpResponse<any>) => {
      this.practice_areas = response.body.data.practice_areas;

    });
    // this.videoplayer.nativeElement.play();
    
  }
  openModal() {
    $('#myModal').modal('show');
    this.applyPopup = !this.applyPopup;
  }


  lawyerSearchRedirect(searchType = "", searchCountry = "") {
    if (searchType === 'Any') { searchType = '' }
    this.router.navigate(['/lawyer-search-result', searchType, searchCountry]);
  }

  lawyerSearchOnEnter(e, searchType = "", searchCountry = "") {
    var key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0;
    if (key == 13) {
      e.preventDefault();
      if (searchType === 'Any') { searchType = '' }
      this.router.navigate(['/lawyer-search-result', searchType, searchCountry]);
    }
  }

  rePlay() {
    this.videoplayer.nativeElement.play();
  }

}
