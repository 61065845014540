import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthServices } from '../services/auth.service';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'lawyer-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.css']
})
export class VerifyUserComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private authService: AuthServices,
    private toastrService: ToastrService, private title: Title, private meta: Meta
  ) { }
  code: String;
  ngOnInit() {
    this.title.setTitle("Jurislex - User Verification");
    this.meta.updateTag({ name: 'description', content: 'Please verify your profile here .' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });
    this.route.params.subscribe(params => {


      this.code = params['token'];


      console.log(this.code);
      this.authService.verifyUser(this.code).subscribe(
          (response: HttpResponse<any>) => {

          if (response.body.status_code == 0) {

              if (response.body.data.verified === "1") {
                  if (response.body.data.role === 3)
                      this.router.navigate(['/signin-lawyer']);
                  else
                      this.router.navigate(['/signin-user']);

                  this.toastrService.success('Account verified successfully.');
              }
              else {
                  if (response.body.data.role === 3)
                      this.router.navigate(['/sign-up']);
                  else
                      this.router.navigate(['/signin-user']);

                  this.toastrService.success('Email verified successfully, Please proceed');
              }
            
          }
        },
        (error: any) => {

          if (error.error.message) {
            this.toastrService.error(error.error.message);
          } else {
            this.toastrService.error('Something went wrong');
          }
          this.router.navigate(['/sign-in']);
        }
      );
    });
  }
}
