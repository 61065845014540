import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    private subject = new Subject<any>();

    setErrorPage() {
        this.subject.next({ error: true })
    }
    clearErrorPage() {
        this.subject.next({ error: false });
    }
    getErrorpage(): Observable<any> {
        return this.subject.asObservable();
    }
}