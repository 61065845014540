import { Component, OnInit, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormGroupName,
  NgForm
} from '@angular/forms';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { PasswordValidation } from '../validators/password-validation';
import { Meta, Title } from '@angular/platform-browser';
declare var $: any;
declare var StripeCheckout: any;
declare var stripe: any;
declare var elements: any;
@Component({
  selector: 'lawyer-add-lawyer',
  templateUrl: './add-lawyer.component.html',
  styleUrls: ['./add-lawyer.component.css']
})
export class AddLawyerComponent implements OnInit {
  dropdownList = [];
  selectedItems = [];
  dropdownSettings = {};
  fieldTextType: boolean;

  onItemSelect(item: any) {


  }
  onSelectAll(items: any) {

  }
  changeStatus(x) {
    if (x == 0) {
      (<HTMLInputElement>document.getElementById("check2")).checked = true;
      (<HTMLInputElement>document.getElementById("check14")).checked = true;
      (<HTMLInputElement>document.getElementById("check15")).checked = true;
      (<HTMLInputElement>document.getElementById("check16")).checked = true;
      (<HTMLInputElement>document.getElementById("check17")).checked = true;
      (<HTMLInputElement>document.getElementById("check18")).checked = true;
      (<HTMLInputElement>document.getElementById("check19")).checked = true;
      (<HTMLInputElement>document.getElementById("check20")).checked = true;
      (<HTMLInputElement>document.getElementById("check21")).checked = true;
      (<HTMLInputElement>document.getElementById("check22")).checked = true;
    }
    if (x == 1) {

      (<HTMLInputElement>document.getElementById("check24")).checked = true;
      (<HTMLInputElement>document.getElementById("check25")).checked = true;
      (<HTMLInputElement>document.getElementById("check26")).checked = true;
      (<HTMLInputElement>document.getElementById("check27")).checked = true;
      (<HTMLInputElement>document.getElementById("check28")).checked = true;
      (<HTMLInputElement>document.getElementById("check29")).checked = true;
      (<HTMLInputElement>document.getElementById("check30")).checked = true;
      (<HTMLInputElement>document.getElementById("check31")).checked = true;
      (<HTMLInputElement>document.getElementById("check32")).checked = true;
      (<HTMLInputElement>document.getElementById("check33")).checked = true;
      (<HTMLInputElement>document.getElementById("check34")).checked = true;
      (<HTMLInputElement>document.getElementById("check35")).checked = true;
    }
    if (x == 2) {

      (<HTMLInputElement>document.getElementById("check9")).checked = true;
      (<HTMLInputElement>document.getElementById("check10")).checked = true;
      (<HTMLInputElement>document.getElementById("check11")).checked = true;
      (<HTMLInputElement>document.getElementById("check39")).checked = true;
      (<HTMLInputElement>document.getElementById("check40")).checked = true;
      (<HTMLInputElement>document.getElementById("check41")).checked = true;
      (<HTMLInputElement>document.getElementById("check42")).checked = true;
      (<HTMLInputElement>document.getElementById("check43")).checked = true;
      (<HTMLInputElement>document.getElementById("check44")).checked = true;
      (<HTMLInputElement>document.getElementById("check45")).checked = true;

    }

    if (x == 3) {

      (<HTMLInputElement>document.getElementById("check47")).checked = true;
      (<HTMLInputElement>document.getElementById("check48")).checked = true;
      (<HTMLInputElement>document.getElementById("check49")).checked = true;
      (<HTMLInputElement>document.getElementById("check50")).checked = true;
      (<HTMLInputElement>document.getElementById("check51")).checked = true;
      (<HTMLInputElement>document.getElementById("check52")).checked = true;

    }

    if (x == 4) {

      (<HTMLInputElement>document.getElementById("check54")).checked = true;
      (<HTMLInputElement>document.getElementById("check55")).checked = true;
      (<HTMLInputElement>document.getElementById("check56")).checked = true;
      (<HTMLInputElement>document.getElementById("check57")).checked = true;
      (<HTMLInputElement>document.getElementById("check58")).checked = true;
      (<HTMLInputElement>document.getElementById("check59")).checked = true;
      (<HTMLInputElement>document.getElementById("check60")).checked = true;

    }

    if (x == 5) {

      (<HTMLInputElement>document.getElementById("check62")).checked = true;
      (<HTMLInputElement>document.getElementById("check63")).checked = true;
      (<HTMLInputElement>document.getElementById("check64")).checked = true;


    }

    if (x == 6) {

      (<HTMLInputElement>document.getElementById("check66")).checked = true;
      (<HTMLInputElement>document.getElementById("check67")).checked = true;
      (<HTMLInputElement>document.getElementById("check68")).checked = true;
      (<HTMLInputElement>document.getElementById("check69")).checked = true;

    }

    if (x == 7) {

      (<HTMLInputElement>document.getElementById("check75")).checked = true;
      (<HTMLInputElement>document.getElementById("check76")).checked = true;
      (<HTMLInputElement>document.getElementById("check77")).checked = true;
      (<HTMLInputElement>document.getElementById("check78")).checked = true;
      (<HTMLInputElement>document.getElementById("check79")).checked = true;
      (<HTMLInputElement>document.getElementById("check80")).checked = true;
      (<HTMLInputElement>document.getElementById("check81")).checked = true;
      (<HTMLInputElement>document.getElementById("check82")).checked = true;
      (<HTMLInputElement>document.getElementById("check83")).checked = true;
    }

    if (x == 8) {

      (<HTMLInputElement>document.getElementById("check85")).checked = true;
      (<HTMLInputElement>document.getElementById("check86")).checked = true;


    }

    if (x == 9) {

      (<HTMLInputElement>document.getElementById("check88")).checked = true;
      (<HTMLInputElement>document.getElementById("check89")).checked = true;
      (<HTMLInputElement>document.getElementById("check90")).checked = true;

    }

    if (x == 10) {

      (<HTMLInputElement>document.getElementById("check112")).checked = true;


    }
    if (x == 11) {

      (<HTMLInputElement>document.getElementById("check114")).checked = true;
    }

    if (x == 12) {

      (<HTMLInputElement>document.getElementById("check96")).checked = true;
      (<HTMLInputElement>document.getElementById("check97")).checked = true;

    }
    if (x == 13) {

      (<HTMLInputElement>document.getElementById("check99")).checked = true;
      (<HTMLInputElement>document.getElementById("check100")).checked = true;

    }

    if (x == 14) {

      (<HTMLInputElement>document.getElementById("check113")).checked = true;

    }

    if (x == 15) {

      (<HTMLInputElement>document.getElementById("check103")).checked = true;
      (<HTMLInputElement>document.getElementById("check104")).checked = true;
    }



    if (x == 16) {

      (<HTMLInputElement>document.getElementById("check117")).checked = true;
      (<HTMLInputElement>document.getElementById("check118")).checked = true;
    }
    if (x == 17) {

      (<HTMLInputElement>document.getElementById("check119")).checked = true;
    }

    if (x == 18) {

      (<HTMLInputElement>document.getElementById("check108")).checked = true;
      (<HTMLInputElement>document.getElementById("check109")).checked = true;
      (<HTMLInputElement>document.getElementById("check110")).checked = true;
      (<HTMLInputElement>document.getElementById("check111")).checked = true;
    }

    if (x == 19) {

      (<HTMLInputElement>document.getElementById("check115")).checked = true;

    }

    if (x == 20) {

      (<HTMLInputElement>document.getElementById("check70")).checked = true;
      (<HTMLInputElement>document.getElementById("check71")).checked = true;
      (<HTMLInputElement>document.getElementById("check72")).checked = true;
      (<HTMLInputElement>document.getElementById("check73")).checked = true;
    }

    if (x == 21) {

      (<HTMLInputElement>document.getElementById("check91")).checked = true;
      (<HTMLInputElement>document.getElementById("check92")).checked = true;

    }
  }

  onSearchChange(searchValue: string): void {
    console.log(searchValue);
    if (this.plan == 6) {
      this.addLawyerStep2.get('total_cost').setValue('Annual price in US$, per number of offices and lawyers: ' + '0');

    }
    if (this.plan == 7) {
      this.addLawyerStep2.get('total_cost').setValue('Annual price in US$, per number of offices and lawyers: ' + Number(searchValue) * Number(199));

    }
    if (this.plan == 8) {
      this.addLawyerStep2.get('total_cost').setValue('Annual price in US$, per number of offices and lawyers: ' + Number(searchValue) * Number(299));

    }
    if (this.plan == 9) {
      this.addLawyerStep2.get('total_cost').setValue('Annual price in US$, per number of offices and lawyers: ' + Number(searchValue) * Number(499));

    }
  }

  constructor(
    private dataService: DataService,
    private toastrService: ToastrService,
    private router: Router,
    private currentRoute: ActivatedRoute,
    private title: Title, private meta: Meta
  ) {
    this.currentRoute.params.subscribe(params => {
      this.plan = params['id'];
      this.free = params['free'];

      if (this.free === 'free') {
        document.getElementById('nextBtnFree').style.display = 'block';
        document.getElementById('nextBtnEmail').style.display = 'none';
      }
      else {
        document.getElementById('nextBtnFree').style.display = 'none';
        document.getElementById('nextBtnEmail').style.display = 'block';
      }


    });
  }
  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef
  free
  plan
  apiToken
  clicked = true;
  currentTab = 0; // Current tab is set to be the first tab (0)
  addLawyerStep0: FormGroup;
  addLawyerStep1: FormGroup;
  addLawyerStep2: FormGroup;
  addLawyerStep3: FormGroup;
  addLawyerStep4: FormGroup;
  addLawyerStep5: FormGroup;

  countries: [any];
  states: [any];
  cities: [any];
  firm_logo: any;
  specializations;
  generatedLawyerId;
  userDetails: any = {};
  closeResult: string;

  handler = StripeCheckout.configure({
    // key: 'pk_test_Lj0m49gh5uiGmEzzs3smKqE900qFZDCznJ',// test pubish key
    key: 'pk_live_lrGk4gRfJ0YNznHs3JwgOaIa00jDhWFuc9',// client pubish key
    image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
    locale: 'auto',
    token: function (token) {

      // You can access the token ID with `token.id`.
      // Get the token ID to your server-side code for use.
    }
  });



  // Close Checkout on page navigation:



  ngOnInit() {
    this.title.setTitle("Jurislex - Lawyer SignUp");
    this.meta.updateTag({ name: 'description', content: 'Register and list your firm on jurislex lawyer directory platform.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });

    this.dropdownList = [
      { item_id: 1, item_text: 'English' },
      { item_id: 2, item_text: 'French' },
      { item_id: 3, item_text: 'Hindi' },
      { item_id: 4, item_text: 'Arabic' },
      { item_id: 5, item_text: 'Spanish' },
      { item_id: 6, item_text: 'Chinese' },
      { item_id: 7, item_text: 'Russian' },
      { item_id: 8, item_text: 'Japanese' },
      { item_id: 9, item_text: 'German' },
      { item_id: 10, item_text: 'Korean' },
      { item_id: 11, item_text: 'Turkish' },
      { item_id: 12, item_text: 'Other' }

    ];
    this.selectedItems = [
      { item_id: 1, item_text: 'English' },
      /*{ item_id: 4, item_text: 'Arabic' }*/
    ];

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.createForm();
    this.showTab(this.currentTab); // Display the crurrent tab
    this.dataService
      .getSpecializations()
      .subscribe((response: HttpResponse<any>) => {
        console.log(response.body.data);
        this.specializations = response.body.data;
      });
    this.dataService.getCountries().subscribe((response: HttpResponse<any>) => {
      this.countries = response.body.data;
      console.log(this.countries);
    });


    // $('.cntnt').css({ display: 'none' });
    // //$('.cntnt').slideUp();
    // $('.divRow.first .cntnt').css({ display: 'block' });
    // $('.divRow.first .top').addClass('actv');

  }
  getToken() {
    // Open Checkout with further options:
    this.handler.open({
      name: 'Stripe.com',
      description: '2 widgets',
      zipCode: true,
      amount: 2000
    });
    // e.preventDefault();
  };

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  showTab(n) {
    console.log('called', n);
    var x = document.getElementsByClassName('tab');
    (<HTMLElement>x[n]).style.display = 'block';

    this.fixStepIndicator(n);
  }

  nextPrevBack(n) {
    var x = document.getElementsByClassName('tab');
    // if (n > 0 && !this.validateForm()) return false;
    console.log(x, this.currentTab);

    if (this.currentTab == 5) {
      //alert('1');

      //(<HTMLElement>x[this.currentTab - 1]).style.display = 'none';
      (<HTMLElement>x[this.currentTab]).style.display = 'none';
    }
    else {
      //alert('2');
      (<HTMLElement>x[this.currentTab]).style.display = 'none';
    }
    this.currentTab = this.currentTab + n;
    this.showTab(this.currentTab);
  }
  nextPrev0(n) {
    // if (n > 0 && !this.validateForm()) return false;
    const data = Object.assign(this.addLawyerStep0.value, { membership: this.plan })
    this.dataService.addLawyerStep0(data).subscribe((response: HttpResponse<any>) => {

      console.log('response', response.body.data, response.body.status_code);
      if (response.body.status_code == 0) {

        this.toastrService.success(
          'Successful. Please procced.'
        );
        this.generatedLawyerId = response.body.data[0].user_id;
        console.log(this.generatedLawyerId);
        this.userDetails = response.body.data[0];
        this.assignData();
        var x = document.getElementsByClassName('tab');

        if (this.currentTab == 5)
          (<HTMLElement>x[this.currentTab - 1]).style.display = 'none';
        else (<HTMLElement>x[this.currentTab]).style.display = 'none';
        // Increase or decrease the current tab by 1:
        this.currentTab = this.currentTab + n;
        this.showTab(this.currentTab);
        // this.router.navigate(['/signin-lawyer']);
      } else if (response.body.status_code == 1) {
        let errors = response.body.data;

        if (response.body.message != 'Lawyer with this email is already registered.') {
          let errorMessage = '';
          for (let error in errors) {
            errorMessage += errors[error][0] + '\n';
          }
          this.toastrService.error(errorMessage);

        }
        else {
          let errorMessage = response.body.message
          this.toastrService.error(errorMessage);

        }
        return;
      } else if (response.body.status_code == 2) {
        this.toastrService.success(
          'Just one step to procced: please activate your email'
        );
        return;
      }
    },
      (error: any) => {
        console.log('error', error);
        this.toastrService.error(error.error.message);
        return;
      }
    )


  }
  assignData() {
    this.addLawyerStep1.get('pic_title').setValue(this.userDetails.pic_title)
    this.addLawyerStep1.get('pic_fname').setValue(this.userDetails.pic_fname)
    this.addLawyerStep1.get('pic_lname').setValue(this.userDetails.pic_lname)
    this.addLawyerStep1.get('pic_mname').setValue(this.userDetails.pic_mname)
    this.addLawyerStep1.get('pic_position').setValue(this.userDetails.pic_position)
    this.addLawyerStep1.get('main_firm_email').setValue(this.userDetails.main_firm_email)
    this.addLawyerStep1.get('main_contact_name').setValue(this.userDetails.main_contact_name)
    this.addLawyerStep1.get('main_contact_email').setValue(this.userDetails.main_contact_email)
    this.addLawyerStep1.get('lead_receiver_name').setValue(this.userDetails.lead_receiver_name)
    this.addLawyerStep1.get('contact_web_link').setValue(this.userDetails.contact_web_link)
    if (this.userDetails.official_name)
      this.addLawyerStep2.get('official_name').setValue(this.userDetails.official_name)
    if (this.userDetails.address)
      this.addLawyerStep2.get('address').setValue(this.userDetails.address)
    if (this.userDetails.google_map_url)
      this.addLawyerStep2.get('google_map_url').setValue(this.userDetails.google_map_url)
    if (this.userDetails.city_id)
      this.addLawyerStep2.get('city_id').setValue(this.userDetails.city_id)
    if (this.userDetails.city_id)
      this.addLawyerStep2.get('city_other').setValue(this.userDetails.city_other)
    if (this.userDetails.city_other)
      this.addLawyerStep2.get('state_id').setValue(this.userDetails.state_id)
    if (this.userDetails.country_id)
      this.addLawyerStep2.get('country_id').setValue(this.userDetails.country_id)
    if (this.userDetails.zipcode)
      this.addLawyerStep2.get('zipcode').setValue(this.userDetails.zipcode)
    if (this.userDetails.phone)
      this.addLawyerStep2.get('phone').setValue(this.userDetails.phone)
    if (this.userDetails.countrycode)
      this.addLawyerStep2.get('countrycode').setValue(this.userDetails.countrycode)
    if (this.userDetails.free_number)
      this.addLawyerStep2.get('free_number').setValue(this.userDetails.free_number)
    if (this.userDetails.fax)
      this.addLawyerStep2.get('faxcountrycode').setValue(this.userDetails.faxcountrycode)
    if (this.userDetails.fax)
      this.addLawyerStep2.get('fax').setValue(this.userDetails.fax)
    if (this.userDetails.vat)
      this.addLawyerStep2.get('vat').setValue(this.userDetails.vat)
    if (this.userDetails.firm_founded_year)
      this.addLawyerStep2.get('firm_founded_year').setValue(this.userDetails.firm_founded_year)
    if (this.userDetails.litigation_perc)
      this.addLawyerStep2.get('litigation_perc').setValue(this.userDetails.litigation_perc)
    if (this.userDetails.other_lang)
      this.addLawyerStep2.get('other_lang').setValue(this.userDetails.other_lang)
    if (this.userDetails.languages)
      this.addLawyerStep2.get('languages').setValue(this.userDetails.languages)
    if (this.userDetails.total_lawyers_wordwide)
      this.addLawyerStep2.get('total_lawyers_wordwide').setValue(this.userDetails.total_lawyers_wordwide)
    if (this.userDetails.total_lawyers)
      this.addLawyerStep2.get('total_lawyers').setValue(this.userDetails.total_lawyers)
    if (this.userDetails.total_offices)
      this.addLawyerStep2.get('total_offices').setValue(this.userDetails.total_offices)
    if (this.userDetails.total_cost)
      this.addLawyerStep2.get('total_cost').setValue(this.userDetails.total_cost)
    if (this.userDetails.firm_headline_title)
      this.addLawyerStep2.get('firm_headline_title').setValue(this.userDetails.firm_headline_title)


    if (this.userDetails.facebook)
      this.addLawyerStep2.get('facebook').setValue(this.userDetails.facebook)
    if (this.userDetails.linkedin)
      this.addLawyerStep2.get('linkedin').setValue(this.userDetails.linkedin)
    if (this.userDetails.twitter)
      this.addLawyerStep2.get('twitter').setValue(this.userDetails.twitter)
    if (this.userDetails.instagram)
      this.addLawyerStep2.get('instagram').setValue(this.userDetails.instagram)
    if (this.userDetails.youtube)
      this.addLawyerStep2.get('youtube').setValue(this.userDetails.youtube)


    if (this.userDetails.firm_overview)
      this.addLawyerStep3.get('firm_overview').setValue(this.userDetails.firm_overview)
    if (this.userDetails.a)
      this.addLawyerStep3.get('a').setValue(this.userDetails.a)
    if (this.userDetails.firm_logo)
      this.addLawyerStep3.get('firm_logo').setValue(this.userDetails.firm_logo)
    if (this.userDetails.firm_video)
      this.addLawyerStep3.get('firm_video').setValue(this.userDetails.firm_video)
    if (this.userDetails.firm_video_desc)
      this.addLawyerStep3.get('firm_video_desc').setValue(this.userDetails.firm_video_desc)
    if (this.userDetails.free_consultation)
      this.addLawyerStep3.get('free_consultation').setValue(this.userDetails.free_consultation)
    if (this.userDetails.contingency_fee_cases)
      this.addLawyerStep3.get('contingency_fee_cases').setValue(this.userDetails.contingency_fee_cases)
    if (this.userDetails.accepts_creditcard)
      this.addLawyerStep3.get('accepts_creditcard').setValue(this.userDetails.accepts_creditcard)
    if (this.userDetails.firm_website)
      this.addLawyerStep3.get('firm_website').setValue(this.userDetails.firm_website)
    if (this.userDetails.title_section)
      this.addLawyerStep3.get('title_section').setValue(this.userDetails.title_section)

    if (this.userDetails.firm_practice_description)
      this.addLawyerStep4.get('firm_practice_description').setValue(this.userDetails.firm_practice_description);


    if (this.userDetails.firm_affiliation_networks)
      this.addLawyerStep5.get('firm_affiliation_networks').setValue(this.userDetails.firm_affiliation_networks);






    console.log(this.addLawyerStep1.value)
  }

  nextPrev(n) {
    // if (n > 0 && !this.validateForm()) return false;

    console.log(this.currentTab, n, this.addLawyerStep1);
    const data = Object.assign(this.addLawyerStep1.value, {
      user_id: this.generatedLawyerId
    })
    this.dataService.addLawyerStep1(data).subscribe((response: HttpResponse<any>) => {

      console.log('response', response);
      if (response.body.status_code == 0) {
        this.toastrService.success(
          'Successful. Please procced.'
        );
        // this.generatedLawyerId = response.body.data.id;
        var x = document.getElementsByClassName('tab');

        if (this.currentTab == 5)
          (<HTMLElement>x[this.currentTab - 1]).style.display = 'none';
        else (<HTMLElement>x[this.currentTab]).style.display = 'none';
        // Increase or decrease the current tab by 1:
        this.currentTab = this.currentTab + n;
        this.showTab(this.currentTab);
        // this.router.navigate(['/signin-lawyer']);
      } else if (response.body.status_code == 1) {
        let errors = response.body.data;
        let errorMessage = '';
        for (let error in errors) {
          errorMessage += errors[error][0] + '\n';
        }
        this.toastrService.error(errorMessage);
        return;
      }
    },
      (error: any) => {
        console.log('error', error);
        this.toastrService.error(error.error.message);
        return;
      }
    )


  }

  nextPrevStep2(n) {
    this.addLawyerStep2.value.languages = this.selectedItems.map(function (elem) {
      return elem.item_text;
    }).join(",")
    console.log(this.currentTab, n, this.addLawyerStep2);
    const data = Object.assign(this.addLawyerStep2.value, {
      user_id: this.generatedLawyerId
    })
    this.dataService.addLawyerStep2(data).subscribe((response: HttpResponse<any>) => {

      console.log('response', response);
      if (response.body.status_code == 0) {
        this.toastrService.success(
          'successful.'
        );
        var x = document.getElementsByClassName('tab');
        // if (n > 0 && !this.validateForm()) return false;
        console.log(x, this.currentTab);
        if (this.currentTab == 5)
          (<HTMLElement>x[this.currentTab - 1]).style.display = 'none';
        else (<HTMLElement>x[this.currentTab]).style.display = 'none';
        this.currentTab = this.currentTab + n;
        this.showTab(this.currentTab);
      } else if (response.body.status_code == 1) {
        let errors = response.body.data;
        let errorMessage = '';
        for (let error in errors) {
          errorMessage += errors[error][0] + '\n';
        }
        this.toastrService.error(errorMessage);
        return;
      }
    },
      (error: any) => {
        console.log('error', error);
        this.toastrService.error(error.error.message);
        return;
      }
    )



  }

  nextPrevStep3(n) {

    console.log(this.currentTab, n, this.addLawyerStep3);
    const data = Object.assign(this.addLawyerStep3.value, {
      user_id: this.generatedLawyerId,
      firm_logo: this.firm_logo
    })
    console.log(data)
    this.dataService.addLawyerStep3(data).subscribe((response: HttpResponse<any>) => {

      console.log('response', response);
      if (response.body.status_code == 0) {
        this.apiToken = response.body.data.api_token
        this.toastrService.success(
          'successful.'
        );
        var x = document.getElementsByClassName('tab');
        // if (n > 0 && !this.validateForm()) return false;
        console.log(x, this.currentTab);
        if (this.currentTab == 5)
          (<HTMLElement>x[this.currentTab - 1]).style.display = 'none';
        else (<HTMLElement>x[this.currentTab]).style.display = 'none';
        this.currentTab = this.currentTab + n;
        this.showTab(this.currentTab);
      } else if (response.body.status_code == 1) {
        let errors = response.body.data;
        let errorMessage = '';
        for (let error in errors) {
          errorMessage += errors[error][0] + '\n';
        }
        this.toastrService.error(errorMessage);
        return;
      }
    },
      (error: any) => {
        console.log('error', error);
        this.toastrService.error(error.error.message);
        return;
      }
    )



  }

  nextPrevStep4(n) {
    let practice_area = '';
    $('.practice_area .row .check-box').each(function () {
      if (
        $(this)
          .find('input')
          .is(':checked')
      ) {
        var text = $(this).find('p').context.innerText;
        practice_area += text + ',';
        console.log(practice_area);
      }
    });

    console.log(this.currentTab, n, this.addLawyerStep4);
    const data = Object.assign(this.addLawyerStep4.value, {
      user_id: this.generatedLawyerId,
      areas_of_practice: practice_area
    })
    this.dataService.addLawyerStep4(data).subscribe((response: HttpResponse<any>) => {

      console.log('response', response);
      if (response.body.status_code == 0) {
        this.toastrService.success(
          'successful.'
        );
        var x = document.getElementsByClassName('tab');
        // if (n > 0 && !this.validateForm()) return false;
        console.log(x, this.currentTab);

        if (this.free === 'free') {
          document.getElementById('nextBtnFree').style.display = 'block';
          document.getElementById('nextBtnEmail').style.display = 'none';
        }
        else {
          document.getElementById('nextBtnFree').style.display = 'none';
          document.getElementById('nextBtnEmail').style.display = 'block';
        }


        if (this.currentTab == 5)
          (<HTMLElement>x[this.currentTab - 1]).style.display = 'none';
        else (<HTMLElement>x[this.currentTab]).style.display = 'none';
        this.currentTab = this.currentTab + n;
        this.showTab(this.currentTab);
      } else if (response.body.status_code == 1) {
        let errors = response.body.data;
        let errorMessage = '';
        for (let error in errors) {
          errorMessage += errors[error][0] + '\n';
        }
        this.toastrService.error(errorMessage);
        return;
      }
    },
      (error: any) => {
        console.log('error', error);
        this.toastrService.error(error.error.message);
        return;
      }
    )



  }

  nextPrevStep5(n) {

    console.log(this.currentTab, n, this.addLawyerStep5);
    const data = Object.assign(this.addLawyerStep5.value, {
      user_id: this.generatedLawyerId
    })
    console.log(data);
    this.dataService.addLawyerStep5(data).subscribe((response: HttpResponse<any>) => {

      console.log('response', response);
      if (response.body.status_code == 0) {

        if (this.plan === 6 || this.plan === '6') {
          this.router.navigate(['/sucessfull']);
          this.toastrService.success(
            'Nice! You are on your way to becoming a Jurislex user, Please activate your email address'
          );
        }

        else {
          this.router.navigate(['/payment-checkout', this.plan, this.apiToken]);
          this.toastrService.success(
            'congratulations! sign up completed successfully.Please procced to payment.'
          );
        }

      } else if (response.body.status_code == 1) {
        let errors = response.body.data;
        let errorMessage = '';
        for (let error in errors) {
          errorMessage += errors[error][0] + '\n';
        }
        this.toastrService.error(errorMessage);
        return;
      }
    },
      (error: any) => {
        console.log('error', error);
        this.toastrService.error(error.error.message);
        return;
      }
    )



  }

  nextPrevStep5Free(n) {

    console.log(this.currentTab, n, this.addLawyerStep5);
    const data = Object.assign(this.addLawyerStep5.value, {
      user_id: this.generatedLawyerId
    })
    console.log(data);
    this.dataService.addLawyerStep5Free(data).subscribe((response: HttpResponse<any>) => {

      console.log('response', response);
      if (response.body.status_code == 0) {

        if (this.plan === 6 || this.plan === '6') {
          this.router.navigate(['/sucessfull_member']);
          this.toastrService.success(
            'congratulations! You are a Jurislex user'
          );
        }

        else {
          this.router.navigate(['/payment-checkout', this.plan, this.apiToken]);
          this.toastrService.success(
            'congratulations! sign up completed successfully.Please procced to payment.'
          );
        }

      } else if (response.body.status_code == 1) {
        let errors = response.body.data;
        let errorMessage = '';
        for (let error in errors) {
          errorMessage += errors[error][0] + '\n';
        }
        this.toastrService.error(errorMessage);
        return;
      }
    },
      (error: any) => {
        console.log('error', error);
        this.toastrService.error(error.error.message);
        return;
      }
    )



  }


  validateForm() {
    // This function deals with validation of the form fields
    var x,
      y,
      i,
      valid = true;
    x = document.getElementsByClassName('tab');
    y = x[this.currentTab].getElementsByTagName('input');
    // A loop that checks every input field in the current tab:
    for (i = 0; i < y.length; i++) {
      // If a field is empty...
      if (y[i].value == '') {
        // add an "invalid" class to the field:
        y[i].className += ' invalid';
        // and set the current valid status to false
        valid = false;
      }
    }
    // If the valid status is true, mark the step as finished and valid:
    if (valid) {
      document.getElementsByClassName('step')[this.currentTab].className +=
        ' finish';
    }
    return valid; // return the valid status
  }

  fixStepIndicator(n) {
    // This function removes the "active" class of all steps...
    var i,
      x = document.getElementsByClassName('step');
    for (i = 0; i < x.length; i++) {
      x[i].className = x[i].className.replace(' active', '');
    }
    //... and adds the "active" class on the current step:
    x[n].className += ' active';
  }

  onCountryChange(event) {
    console.log(this.addLawyerStep2.value, '8888');
    this.states = null;
    this.cities = null;
    this.dataService
      .getStates(this.addLawyerStep2.value.country_id)
      .subscribe((response: HttpResponse<any>) => {
        this.states = response.body.data;
        console.log(this.states);
      });
  }

  onStateChange(event) {
    this.cities = null;
    // console.log(this.addLawyerStep2.value, '8888');
    this.dataService
      .getCities(this.addLawyerStep2.value.state_id)
      .subscribe((response: HttpResponse<any>) => {
        this.cities = response.body.data;
        console.log(this.cities);
      });
  }

  createForm() {
    let title_section = new FormArray([]);
    this.addLawyerStep0 = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      name: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        // Validators.minLength(6)
        Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{5,}$"),
      ]),
      password_confirmation: new FormControl('', [
        Validators.required,
        Validators.minLength(6)
      ]),
    }, { validators: PasswordValidation.MatchPassword });

    this.addLawyerStep1 = new FormGroup({
      pic_title: new FormControl('Mr', []),
      pic_fname: new FormControl('', [Validators.required]),
      pic_lname: new FormControl('', [Validators.required]),
      // pic_mname: new FormControl('', [Validators.required]),
      pic_mname: new FormControl(''),
      pic_position: new FormControl('', [Validators.required]),
      contact_web_link: new FormControl('', [Validators.required, Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")]),
      main_firm_email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      main_contact_name: new FormControl('', [Validators.required]),
      main_contact_email: new FormControl('', [
        Validators.required,
        Validators.email
      ]),
      lead_receiver_name: new FormControl('', [Validators.required]),
      lead_receiver_email: new FormControl('', [
        // Validators.required,
        Validators.email
      ]),
    });
    this.addLawyerStep2 = new FormGroup({
      official_name: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      google_map_url: new FormControl('', []),
      city_id: new FormControl('', []),
      city_other: new FormControl('', []),
      state_id: new FormControl('', []),
      country_id: new FormControl('', [Validators.required]),
      zipcode: new FormControl('', []),
      countrycode: new FormControl('', [
        Validators.required
      ]),
      phone: new FormControl('', [Validators.required]),
      free_number: new FormControl('', []),
      faxcountrycode: new FormControl('', []),
      fax: new FormControl('', []),
      vat: new FormControl('', []),
      firm_founded_year: new FormControl('', [Validators.required]),
      litigation_perc: new FormControl('', []),
      languages: new FormControl('', [Validators.required]),
      other_lang: new FormControl('', []),
      total_lawyers_wordwide: new FormControl('', []),
      total_lawyers: new FormControl('', [
        Validators.required,
        Validators.min(1)
      ]),
      total_offices: new FormControl('', [Validators.required]),
      total_cost: new FormControl('', []),

      firm_headline_title: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(10)
      ]),

      facebook: new FormControl('', [Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")]),
      linkedin: new FormControl('', [Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")]),
      twitter: new FormControl('', [Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")]),
      instagram: new FormControl('', [Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")]),
      youtube: new FormControl('', [Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")]),
    });
    this.addLawyerStep3 = new FormGroup({
      firm_overview: new FormControl('', [Validators.required]),
      a: new FormControl(''),
      firm_logo: new FormControl(''),
      firm_video: new FormControl('', []),
      firm_video_desc: new FormControl('', []),
      free_consultation: new FormControl(false, []),
      contingency_fee_cases: new FormControl(false, []),
      accepts_creditcard: new FormControl(false, []),
      firm_website: new FormControl('', [Validators.required, Validators.pattern("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")]),
      title_section: title_section,
    })

    this.addLawyerStep4 = new FormGroup({
      firm_practice_description: new FormControl('', [Validators.required])

    });

    this.addLawyerStep5 = new FormGroup({
      firm_affiliation_networks: new FormArray([])

    })

  }

  addAffilationItem() {
    const control = new FormControl(null);
    (<FormArray>this.addLawyerStep5.get('firm_affiliation_networks')).push(control);
  }

  removeAffilationItem(index) {
    (<FormArray>this.addLawyerStep5.get('firm_affiliation_networks')).removeAt(index);
  }

  addPageAndWebTitle() {
    const control = new FormGroup({
      page_title: new FormControl(null),
      web_title: new FormControl(null)
    });
    (<FormArray>this.addLawyerStep3.controls['title_section']).push(control);
    console.log(this.addLawyerStep3.controls);
  }

  changeListener($event): void {
    this.readThis($event.target);
  }

  // readThis(inputValue: any): void {    
  //   var file: File = inputValue.files[0];
  //   var myReader: FileReader = new FileReader();
  //   myReader.onloadend = (e) => {
  //     this.addLawyerStep3.value.firm_logo = myReader.result;
  //       this.firm_logo = myReader.result;
  //       console.log(this.firm_logo);
  //   }
  //   myReader.readAsDataURL(file);
  //   this.addLawyerStep3.get('firm_logo').setErrors({ maxSize: false });
  // }

  readThis(inputValue: any): void {
    var file: File = inputValue.files[0];
    if (file.size < 5000000) {
      var myReader: FileReader = new FileReader();

      myReader.onloadend = e => {
        this.addLawyerStep3.value.firm_logo = myReader.result;
        this.firm_logo = myReader.result;
        console.log(this.firm_logo);
      };
      myReader.readAsDataURL(file);
      this.addLawyerStep3.get('firm_logo').setErrors({ maxSize: false });
    } else {
      this.addLawyerStep3.get('firm_logo').setErrors({ maxSize: true });
      console.log('this..errrrrrrr', this.addLawyerStep3.get('firm_logo').errors);
    }
  }
}
