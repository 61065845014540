import { Component, OnInit, ElementRef, ChangeDetectorRef, ViewChild } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../services/seo.service';
declare var stripe: any;
declare var elements: any;
declare let $: any;

@Component({
  selector: 'lawyer-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.css']
})
export class MembershipComponent implements OnInit {
  constructor(private dataService: DataService, private cd: ChangeDetectorRef, private title: Title, private router: Router, private meta: Meta, private seoService: SeoService) { }
  plans;
  error: string
  showPopUp: boolean = false;
  userSelectedPlainId: number = 0;
    benifits = {
    2: 'Listing Title',
    1: 'Basic Information',    
    3: 'Address',
        4: 'Phone Number',
        10: 'Allow Contact us',
        5: 'Unlimited Firm Description',
        9: 'Brief Firm Overview',
    6: 'Full Contact Information',
    7: 'All Practice Areas',
    8: 'Logo and Pictures',    
    11: 'Access to Your Website',
        12: 'Social Network Links',
        20: 'Lead Generate from Jurislex',
    13: 'Article Submissions',
        14: 'Add Your News',   
        18: 'Jurislex verification',
        19: 'Jurislex Certification',
        23: 'Jurislex Award Nominees',
      16: 'Featured Firms',
      17: 'Top Featured Listing',
        24: 'Social Network Posts Promotion', 
      21: 'Video Corner',
      22: 'Tender Notification'    

  }
  ngOnInit() {
    this.seoService.createLinkForCanonicalURL();
    this.title.setTitle("Law Firms Listing Website | Jurislex");
    this.meta.updateTag({ name: 'description', content: 'See your firm at the top rated legal firms list of your country. Choose the membership plan to list your legal firm online according to your need.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });
    this.dataService.getPlans().subscribe((response: HttpResponse<any>) => {

      if (response.body.status_code == 0) {
        this.plans = response.body.data;
        for (var i = 0; i < this.plans.length; i++) {
          let max_offices = this.plans[i].max_offices
          let max_lawyers = this.plans[i].max_lawyers
          let benefitsArray = this.plans[i].benefits.split(',')
          let arr = []
          for (var j = 0; j < benefitsArray.length; j++) {
            arr.push(this.benifits[benefitsArray[j]])
          }
          this.plans[i].benefits = arr
          this.plans[i].benefits.push(`${max_offices} Offices and ${max_lawyers} Lawyers`)
        }

      }
    });

    $(window).load(function () {
      setTimeout(function () {
        var windWdth = $(window).width()
        if (windWdth > 767) {
          var liLength = $('.membership_container ul li').length;
          var proBoxWidth = 300 * liLength;

          if (liLength > 4) {
            $('.membership_container').addClass('extra-product');
            $('.membership_container ul li').css({ 'width': 300 });
            $('.membership_container ul').css({ 'width': proBoxWidth + 10 });
          }
        }
      }, 400)
    })


  }

  signUpClicked(PlanId) {
    this.showPopUp = true;
    this.userSelectedPlainId = PlanId
  }
  popUpClose() {
    this.showPopUp = false;
  }
  acceptTerms() {
    this.router.navigate(['/register-lawyer', this.userSelectedPlainId]);
    // console.log(this.userSelectedPlainId)
  }

}
