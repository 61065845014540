import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MembershipRoutingModule } from './membership-routing.module';
import { MembershipComponent } from './membership.component';
@NgModule({
  declarations: [MembershipComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MembershipRoutingModule
  ]
})
export class MembershipModule { }
