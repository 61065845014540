import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'lawyer-view-collegue',
  templateUrl: './view-collegue.component.html',
  styleUrls: ['./view-collegue.component.css']
})
export class ViewCollegueComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private router: Router, private dataService: DataService, private title: Title, private meta: Meta) { }
  currId = null;
  user_id = null;
  benefits = [];
  collegues = [];
  collegue = null;
  offices = [];
  office = null;
    lawyer_id = null;
    facebook = null;
    linkedin = null;
    twitter = null;
    instagram = null;
    youtube = null;
  ngOnInit() {
    this.title.setTitle("Jurislex - Collegue Detail");
    this.meta.updateTag({ name: 'description', content: 'This page is for view collegue to your firm.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });
    this.activatedRoute.params.subscribe(params => {
      this.currId = params[`id`];
      this.lawyer_id = params[`lawyer_id`];
    })
    // this.user_id = JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id?JSON.parse        (localStorage.getItem(`websiteCurrentUser`)).data.id:null;
    this.getOffice();
    this.getColleagueList();

  }

    getOffice() {
        this.dataService.spinnerShow();
        this.dataService.getOffices(this.lawyer_id).subscribe(
            (response: HttpResponse<any>) => {
                this.dataService.spinnerHide();
                this.offices = response.body.data;
                console.log(this.offices);
            });
    }

  getColleagueList() {
    this.dataService.spinnerShow();
    this.dataService.getCollegueList(this.lawyer_id).subscribe(
      (response1: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.benefits = response1.body.benefits
        this.collegues = response1.body.data;
        var index = this.collegues.findIndex(obj => obj.id == this.currId);
            this.collegue = this.collegues[index];
            
            var index1 = this.offices.findIndex(obj1 => obj1.id == this.collegue.office_id);
            console.log(index1);

            this.office = this.offices[index1];
           
            this.facebook = this.office.facebook;
            this.linkedin = this.office.linkedin;
            this.twitter = this.office.twitter;
            this.instagram = this.office.instagram;
            this.youtube = this.office.youtube;
      });
  }

 

}
