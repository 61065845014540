import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthServices } from '../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
declare var hljs: any;

@Component({
  selector: 'lawyer-lawyer-login',
  templateUrl: './lawyer-login.component.html',
  styleUrls: ['./lawyer-login.component.css']
})
export class LawyerLoginComponent implements OnInit {
    public readonly siteKey = '6LfGxPEUAAAAAAHTrNk8UsRtdrGVzRY6AAIgKrNW';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  public useGlobalDomain: boolean = false;

  @ViewChild('captchaElem', { static: true }) captchaElem: any;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;

  constructor(
    private authService: AuthServices,
    private tostrService: ToastrService,
    private router: Router, private title: Title, private meta: Meta
  ) { }
  signinForm: FormGroup;
  ngOnInit() {
    this.createForm();
    this.title.setTitle("Jurislex - Lawyer Login");
    this.meta.updateTag({ name: 'description', content: 'Please login through this page as a lawyer.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });
  }
  createForm() {
    this.signinForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6)
      ]),
      // recaptcha: new FormControl('', [Validators.required])
      recaptcha: new FormControl('')

    });
  }
  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

  changeSize(size: 'compact' | 'normal'): void {
    this.size = size;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  setLanguage(): void {
    this.lang = this.langInput.nativeElement.value;
  }

  setUseGlobalDomain(use: boolean): void {
    this.useGlobalDomain = use;
  }

  getCurrentResponse(): void {
    const currentResponse = this.captchaElem.getCurrentResponse();
    if (!currentResponse) {
      alert('There is no current response - have you submitted captcha?');
    } else {
      alert(currentResponse);
    }
  }
  ngAfterViewInit(): void {
    this.highlight();
  }

  getResponse(): void {
    const response = this.captchaElem.getResponse();
    if (!response) {
      alert('There is no response - have you submitted captcha?');
    } else {
      alert(response);
    }
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }

  private highlight(): void {
    const highlightBlocks = document.getElementsByTagName('code');
    for (let i = 0; i < highlightBlocks.length; i++) {
      const block = highlightBlocks[i];
      hljs.highlightBlock(block);
    }
  }

  onLogin() {
    const email = this.signinForm.value.email;
    const password = this.signinForm.value.password;
    // console.log(email, password);
    if (this.signinForm.valid) {
      this.authService.loginLawyer(email, password).subscribe(
        (response: HttpResponse<any>) => {
          console.log(response);
          if (response.body.status_code == 0) {
            // localStorage.clear();
            localStorage.removeItem('websiteCurrentUser');
            localStorage.removeItem('websiteToken');
            localStorage.setItem('websiteToken', response.body.data.api_token);
            localStorage.setItem('websiteCurrentUser', JSON.stringify(response.body));
            // console.log(JSON.stringify(response.body))
            // this.tostrService.success('You have successfully Logged In', 'Successful',
            // { timeOut: 200000, positionClass: 'toast-top-center', closeButton: true, toastClass: 'toast' })
            this.tostrService.success('You have successfully Logged In');
            this.router.navigate(['/dashboard']);
          } else if (response.body.status_code == 1) {
            this.tostrService.error(response.body.message);
          }
        },
        error => {
          console.log(error);
          if (error.status == 422) {
            this.tostrService.error(error.error.errors.email);
          } else {
            this.tostrService.error(error.error.error);
          }
        }
      );
    }
  }
}
