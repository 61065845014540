import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'lawyer-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  constructor(private title: Title, private meta: Meta) { }

  ngOnInit() {this.title.setTitle("Jurislex - SignUp");
    this.meta.updateTag({ name: 'description', content: 'Register as lawyer or Register as customer here .' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });  
  }

}
