import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpResponse } from '@angular/common/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService } from '../services/data.service';
import { Meta, Title } from '@angular/platform-browser';

declare var stripe: any;
declare var elements: any;

@Component({
  selector: 'lawyer-payment-checkout-mobile',
  templateUrl: './payment-checkout-mobile.component.html',
  styleUrls: ['./payment-checkout-mobile.component.css']
})
export class PaymentCheckoutMobileComponent implements OnInit {
  @ViewChild('cardInfo', { static: true }) cardInfo: ElementRef;

  headers: Headers = new Headers({ 'Content-Type': 'application/json' })

  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;

  apiToken: any;
  planId: any;
  planAmount: any;

  constructor(
    private httpClient: HttpClient,
    private dataService: DataService,
    private cd: ChangeDetectorRef,
    private currentRoute: ActivatedRoute,
    private router: Router,private title: Title, private meta: Meta
  ) {
    this.headers = new Headers();
    this.headers.append('Content-Type', 'application/json');

    this.currentRoute.params.subscribe((params) => {
      this.apiToken = params['apiToken'];
      this.planId = params['planId'];
    })

    // this.apiToken = "ZtpL1mQUV9beHmC454qypu5IJH6uzmhGPtp3xde3PWufyf9VflOYolc27U2Y";
    // this.planId = 2;
  }

  ngOnInit(): void {
    this.title.setTitle("Jurislex -Payment Checkout");
   
    this.dataService.spinnerHide();
    this.dataService.getPlans().subscribe((response: HttpResponse<any>) => {
      let data = response.body.data;
      data.forEach(element => {
        if (element.id == this.planId) this.planAmount = element.price;
      });

      console.log("----------------->>", this.planAmount)
    })
  }

  ngAfterViewInit() {
    const style = {
      base: {
        lineHeight: '24px',
        fontFamily: 'monospace',
        fontSmoothing: 'antialiased',
        fontSize: '19px',
        '::placeholder': {
          color: 'purple'
        }
      }
    };

    this.card = elements.create('card', { style });
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', this.cardHandler);
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit(form: NgForm) {
    const { token, error } = await stripe.createToken(this.card);

    if (error) {
      console.log('Something is wrong:', error);
    } else {
      console.log('Success!', token);
      let _stoken = token.id;
      console.log("----------->>>>>", _stoken)
      this.dataService.createCharges(this.planAmount, _stoken, this.apiToken).subscribe((response: HttpResponse<any>) => {
        console.log("response------------------>>>>>>", response)
        window.location.href = 'https://www.google.com'
      })
    }
  }

  // checkMembership() {
  //   const headers = new HttpHeaders({ 'Content-type': 'application/json' });
  //   return this.httpClient.get('http://40.121.65.234:83/ws/api/memberships', {
  //     observe: 'response',
  //     headers: headers
  //   });
  // }

  // createCharges(amount, _stoken, api_token) {
  //   const headers = new HttpHeaders({ 'Content-type': 'application/json' });
  //   return this.httpClient.post('http://40.121.65.234:83/ws/api/create-charges', { amount, _stoken, api_token }, {
  //     observe: 'response',
  //     headers: headers
  //   });
  // }
}
