import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OurThinkingComponent } from './our-thinking.component';
import { OurThinkingRoutingModule } from './our-thinking-routing.module';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [OurThinkingComponent],
  imports: [
    CommonModule,
    OurThinkingRoutingModule,
    TranslateModule
  ]
})
export class OurThinkingModule { }
