import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AddOfficeRoutingModule } from './add-office-routing.module';
import { AddOfficeComponent } from './add-office.component';
@NgModule({
  declarations: [AddOfficeComponent],
  imports: [
    TranslateModule,
    FormsModule, ReactiveFormsModule,
    CommonModule,
    AddOfficeRoutingModule
  ]
})
export class AddOfficeModule { }
