import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogManagementComponent } from './blog-management.component';
import { BlogManagementRoutingModule } from './blog-management-routing.module';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [BlogManagementComponent],
  imports: [
    CommonModule,
    TranslateModule,
    BlogManagementRoutingModule
  ]
})
export class BlogManagementModule { }
