import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DisclaimerComponent } from './disclaimer.component';
import { DisclaimerRoutingModule } from './disclaimer-routing.module';

@NgModule({
  declarations: [DisclaimerComponent],
  imports: [
    CommonModule,
    TranslateModule,
    DisclaimerRoutingModule
  ]
})
export class DisclaimerModule { }
