import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { SeoService } from '../services/seo.service';

@Component({
  selector: 'lawyer-testimony',
  templateUrl: './testimony.component.html',
  styleUrls: ['./testimony.component.css']
})
export class TestimonyComponent implements OnInit {

  constructor(private dataService: DataService, private title: Title, private meta: Meta, private seoService: SeoService) {
    this.dataService.getLanguageSignal.subscribe(response => {
      console.log("Testimony ne sun liya malik")
      this.getContent(response);
    });
  }
  myContent;
  ngOnInit() {
    let lang = localStorage.getItem('prefered_language');
    this.seoService.createLinkForCanonicalURL();
    this.title.setTitle("Jurislex - Testimony");
    this.meta.updateTag({ name: 'description', content: 'Don’t trust blindly when you’re seeking some legal solutions. Know what the people are saying about Jurislex before choosing them.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });
    this.getContent(lang);

  }
  getContent(lang) {
    this.dataService.getContent(lang).subscribe((response: HttpResponse<any>) => {
      this.myContent = response.body.data[5].description;
      console.log(response.body)
    });
  }

}
