import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LawyerSearchResultRoutingModule } from './lawyer-search-result-routing.module';
import { LawyerSearchResultComponent } from './lawyer-search-result.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SharedModuleModule } from '../shared-module/shared-module.module'

@NgModule({
  declarations: [LawyerSearchResultComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule, ReactiveFormsModule,
    NgxPaginationModule,
    LawyerSearchResultRoutingModule,
    SharedModuleModule
  ],

})
export class LawyerSearchResultModule { }
