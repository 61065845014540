import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'lawyer-user-dashboard',
  templateUrl: './user-dashboard.component.html',
  styleUrls: ['./user-dashboard.component.css']
})
export class UserDashboardComponent implements OnInit {

  constructor(private router: Router, private dataService: DataService,private title: Title, private meta: Meta) { }
  user = {};
  profile_pic = '../assets/images/no-image-found.jpeg'
  ngOnInit() {
    this.title.setTitle("Jurislex - Dashboard");
    this.meta.updateTag({ name: 'description', content: 'View your profile and details on user dashboard.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });  
    if (JSON.parse(localStorage.getItem('websiteCurrentUser'))) {
      let user = JSON.parse(localStorage.getItem('websiteCurrentUser')).data;
      
      this.dataService.getUser(user.id).subscribe((response: HttpResponse<any>) => {
       
        this.user = response.body.data[0];
        if (this.user['pic'])
          this.profile_pic = this.user['pic']
      })
    }

  }
  onLogout() {
    this.router.navigate(['/signin-user']);
    localStorage.removeItem('websiteCurrentUser');
    localStorage.removeItem('websiteToken');
  }
}
