import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'blog-management',
  templateUrl: './blog-management.component.html',
  styleUrls: ['./blog-management.component.css']
})
export class BlogManagementComponent implements OnInit {

  constructor(private dataService: DataService, private router: Router, private title: Title, private meta: Meta) { }
  offices = [];
  collegue = [];
  user_data = null;
  blogs;
  showArticleManagement: boolean = false;
  ngOnInit() {
    this.title.setTitle("Jurislex - Article management");
    this.meta.updateTag({ name: 'description', content: 'This page is about viewing managing all blog.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal, Office collegue List' });

    this.user_data = JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id ? JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data : null;
    if (this.user_data.benefits.lastIndexOf('13') != -1) this.showArticleManagement = true;

    if (this.showArticleManagement == false) {
      this.router.navigate(['/'])
    }

    this.getBlogList()

  }


  getBlogList() {
    let currId = JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data ? JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id : null;
    this.dataService.getAllBlogs().subscribe((response: HttpResponse<any>) => {
      this.blogs = response.body.data;
      let blogByUser = [];
      for (var i in this.blogs) {
        if (this.blogs[i].user_id == currId) {
          // console.log(this.blogs[i])
          blogByUser.push(this.blogs[i])
        }
      }
      this.blogs = blogByUser
    })
  }


  openSuccessCancelSwal(data) {

    swal.fire({
      title: 'Are you sure?',
      text: 'You not be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger mr-sm',
    }).then(result => {
      if (result.value) {
        this.dataService.deleteBlog(data).subscribe((response: HttpResponse<any>) => {
          console.log(response)
          if (response.status === 200) {
            this.getBlogList();
            swal.fire('Deleted!', 'Article deleted successfully.', 'success');
          }
        });
      }
      else if (result.dismiss) {
        swal.fire('Cancelled', 'You have cancelled deletion request.', 'error');
      }
    })

  }

  editArticle(article_id) {
    this.router.navigate([`../edit-article`, article_id.toString()]);
  }

  viewArticle(article_id) {
    this.router.navigate([`../blogs`, article_id.toString()]);
  }



}
