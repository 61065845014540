import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxCaptchaModule } from 'ngx-captcha';
import { DescribeLegalProblemRoutingModule } from './describe-legal-problem-routing.module';
import { DescribeLegalProblemComponent } from './describe-legal-problem.component';
@NgModule({
  declarations: [DescribeLegalProblemComponent],
  imports: [
    CommonModule,
    TranslateModule,
    NgxCaptchaModule,
    FormsModule, ReactiveFormsModule,
    DescribeLegalProblemRoutingModule
  ]
})
export class DescribeLegalProblemModule { }
