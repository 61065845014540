import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment as env } from '../../environments/environment';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class AuthServices {
  nameChange: Subject<string> = new Subject<string>();
  constructor(private httpClient: HttpClient) { }

  linkedInAuth(data) {
    
    const headers = new HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded' });
    return this.httpClient.post(
      `https://www.linkedin.com/oauth/v2/accessToken`, data.toString(),
      { observe: 'response', headers: headers }
    );
  }


  getLinkedInProfile(accessToken) {
    let data = { 'accessToken':accessToken }
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    console.log("headers",headers)
    return this.httpClient.post(`${env.API_URL}/api/social_login_token`,data, {
      headers: headers,      
      observe: 'response',
      
    });
    
  }

  loginUser(email, password) {
    console.log(email, password);
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
 
    
    return this.httpClient.post(
      `${env.API_URL}/api/login`,{ email, password, role: 2 },{ observe: 'response', headers: headers }
    );
  }

  loginLawyer(email, password) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(
      `${env.API_URL}/api/login`,
      { email, password, role: 3 },
      { observe: 'response', headers: headers }
    );
  }

  sendResetPasswordLink(email) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(
      `${env.API_URL}/api/password/create`,
      { email },
      { observe: 'response', headers: headers }
    );
  }

  registerUser(user) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/register`, user, {
      headers: headers,
      observe: 'response'
    });
  }

  socialLogin(user) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/social_login`, user, {
      headers: headers,
      observe: 'response'
    });
  }

  checkToken(token) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/password/find/${token}`, {
      headers: headers,
      observe: 'response'
    });
  }
  resetPassword(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(`${env.API_URL}/api/password/reset`, data, {
      headers: headers,
      observe: 'response'
    });
  }

  changePassword(data) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.post(
      `${env.API_URL}/api/password/change?api_token=${data.token}`,
      data,
      {
        headers: headers,
        observe: 'response'
      }
    );
  }

  verifyUser(token) {
    const headers = new HttpHeaders({ 'Content-type': 'application/json' });
    return this.httpClient.get(`${env.API_URL}/api/verify/${token}`, {
      headers: headers,
      observe: 'response'
    });
  }

  getRole() {
    if (localStorage.getItem('websiteCurrentUser') && JSON.parse(localStorage.getItem('websiteCurrentUser')).data)
      return JSON.parse(localStorage.getItem('websiteCurrentUser')).data.role;
  }

  get isLoggedIn() {
    console.log(localStorage.getItem('websiteToken'));
    if (localStorage.getItem('websiteToken')) {
      this.nameChange.next('loggedIn');
      return true;
    } else return false;
  }
}
