import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../services/seo.service';
@Component({
  selector: 'lawyer-our-thinking',
  templateUrl: './our-thinking.component.html',
  styleUrls: ['./our-thinking.component.css']
})
export class OurThinkingComponent implements OnInit {
  constructor(private dataService: DataService, private title: Title, private meta: Meta, private seoService: SeoService) {
    this.dataService.getLanguageSignal.subscribe(response => {
      this.getContent(response);
    });
  }
  myContent;
  ngOnInit() {
    let lang = localStorage.getItem('prefered_language');
    this.seoService.createLinkForCanonicalURL();
    this.title.setTitle("What Jurislex Thinks");
    this.meta.updateTag({ name: 'description', content: 'At Jurislex, you get information about experienced lawyers and on different legal issues. Contact Jurislex and make decisions based on accurate information' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal, About Jurislex' });
    this.getContent(lang);
  }
  getContent(lang) {
    // this.dataService.getContent(lang).subscribe((response: HttpResponse<any>) => {
    //   this.myContent = response.body.data[6].description;
    // });
    this.myContent = "<div class=\"container\">\r\n<div class=\"overview_container\">\r\n<h2 style=\"text-align: justify;\"><strong>Our Thinking</strong></h2>\r\n\r\n<p style=\"text-align: justify;\">Here at Jurislex, we strongly believe that the more information you will have in hand, the better decisions you will be able to make. Jurislex is the place where you will get detailed information about every experienced lawyer as well as on different legal issues so that the decisions you take are based on accurate information.</p>\r\n\r\n<p style=\"text-align: justify;\">At our website, you can find a list of attorneys with their profiles which is further divided on the basis of regions and states so that your search for a professional and licensed lawyer is created in best user experience.</p>\r\n\r\n<p style=\"text-align: justify;\">At Jurislex, we are committed to responsible practices for our communities, our team members, our environment, our business partners, and we take this role seriously.</p>\r\n\r\n<p style=\"text-align: justify;\">We are also committed to promoting diversity and inclusion. Corporate Responsibility (ROE) is about building relationships and making a difference. What does this mean concretely? For more information, please select a country of interest.</p>\r\n\r\n<p style=\"text-align: justify;\">&nbsp;</p>\r\n</div>\r\n</div>\r\n"
  }
}
