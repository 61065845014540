import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination';
// import { CKEditorModule } from 'ckeditor4-angular';
import { NgxEditorModule } from 'ngx-editor';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AngularFontAwesomeModule } from 'angular-font-awesome';

import { AppComponent } from './app.component';
import { HeaderComponent } from './core-module/header/header.component';
import { FooterComponent } from './core-module/footer/footer.component';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './core-module/home/home.component';
// import { LawyerSearchComponent } from './lawyer-search/lawyer-search.component';
import { CommonModule } from '@angular/common';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RegisterUserComponent } from './register-user/register-user.component';
import { SigninUserComponent } from './signin-user/signin-user.component';
import { ForgotUserComponent } from './forgot-user/forgot-user.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthServices } from './services/auth.service';
import { LawyerSearchService } from './services/lawyer-search.service';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { SocialLoginModule, AuthServiceConfig } from 'angularx-social-login';
import {
  FacebookLoginProvider,
  LinkedInLoginProvider
} from 'angularx-social-login';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxCaptchaModule } from 'ngx-captcha';

import { environment as env } from '../environments/environment';
// import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { VerifyUserComponent } from './verify-user/verify-user.component';
// import { AboutUsComponent } from './about-us-route/about-us.component';
// import { OurThinkingComponent } from './our-thinking/our-thinking.component';
import { LawyerProfileComponent } from './lawyer-profile/lawyer-profile.component';
// import { MembershipComponent } from './membership/membership.component';
// import { AddLawyerComponent } from './add-lawyer/add-lawyer.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
// import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { SucessfullComponent } from './sucessfull/sucessfull.component';
import { sucessfull_memberComponent } from './sucessfull_member/sucessfull_member.component';
import { sucessfull_dcComponent } from './sucessfull_dc/sucessfull_dc.component';
import { DC_PaymentCheckoutComponent } from './payment-checkout-dc/payment-checkout-dc.component';
// import { LawyerSearchResultComponent } from './lawyer-search-result/lawyer-search-result.component';
import { TopLawyersComponent } from './top-lawyers/top-lawyers.component';
import { AuthGuardService } from './services/auth-guard.service';
import { HeaderLoginComponent } from './core-module/header-login/header.component';
import { LawyerLoginComponent } from './lawyer-login/lawyer-login.component';
import { SigninComponent } from './signin/signin.component';
// import { ContactUsComponent } from './contact-us/contact-us.component';
// import { DescribeLegalProblemComponent } from './describe-legal-problem/describe-legal-problem.component';
import { Safe } from './about-us-route/custom-pipe';
// import { DashboardComponent } from './dashboard/dashboard.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { BlogListComponent } from './blog-list/blog-list.component';
// import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { MobileLawyerPageComponent } from './mobile-lawyer-page/mobile-lawyer-page.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
import { MobileSuccessPageComponent } from './mobile-success-page/mobile-success-page.component';
import { OfficeCollegueListComponent } from './office-collegue-list/office-collegue-list.component';
// import { AddCollegueComponent } from './add-collegue/add-collegue.component';
// import { AddOfficeComponent } from './add-office/add-office.component';
import { PaymentCheckoutComponent } from './payment-checkout/payment-checkout.component';
import { PaymentCheckoutMobileComponent } from './payment-checkout-mobile/payment-checkout-mobile.component';
import { EditOfficeComponent } from './edit-office/edit-office.component';
import { EditCollegueComponent } from './edit-collegue/edit-collegue.component';
import { ViewCollegueComponent } from './view-collegue/view-collegue.component';
import { ViewOfficeComponent } from './view-office/view-office.component';
import { ViewOffice2Component } from './view-office-2/view-office-2.component';
import { NgxSpinnerModule } from 'ngx-spinner';
// import { FeaturedLawyersComponent } from './featured-lawyers/featured-lawyers.component';
import { specialPipe } from './specialPipe';
import { InvoiceComponent } from './invoice/invoice.component';
import { ErrorComponent } from './error/error.component';
// import { TruncatePipe } from './limitToPipe';
import { MembershipUpgradeComponent } from './membership-upgrade/membership-upgrade.component';
import { AgmCoreModule } from '@agm/core';
import { ViewFeedbacksComponent } from './view-feedbacks/view-feedbacks.component';
// import { AdvanceSearchComponent } from './advance-search/advance-search.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
// import { AdvanceSearchNewComponent } from './advance-search-new/advance-search-new.component';
// import { FaqComponent } from './faq/faq.component';
// import { TestimonyComponent } from './testimony/testimony.component';
// import { CreateBlogComponent } from './create-blog/create-blog.component';
import { EditBlogComponent } from './edit-blog/edit-blog.component';
// import { BlogManagementComponent } from './blog-management/blog-management.component';
import { PaypalComponent } from './modules/payment/components/paypal/paypal.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ImgDirective } from './img.directive';
// import { SharedModuleModule } from './shared-module/shared-module.module'

import { LazyModule } from './lazy/lazy.module';
import { TestimonyModule } from './testimony/testimony.module';
import { AboutUsRouteModule } from './about-us-route/about-us-route.module';
import { LawyerSignupModule } from './lawyer-signup/lawyer-signup.module';
import { AddCollegueModule } from './add-collegue/add-collegue.module';
import { AddOfficeModule } from './add-office/add-office.module';
import { LawyerSearchModule } from './lawyer-search/lawyer-search.module';
import { LawyerSearchResultModule } from './lawyer-search-result/lawyer-search-result.module';
import { AdvanceSearchNewModule } from './advance-search-new/advance-search-new.module';
import { BlogListModule } from './blog-list/blog-list.module';
import { BlogDetailsModule } from './blog-details/blog-details.module';
import { BlogManagementModule } from './blog-management/blog-management.module';
import { FeaturedLawyersModule } from './featured-lawyers/featured-lawyers.module';
import { ChangepasswordModule } from './changepassword/changepassword.module';
import { CreateBlogModule } from './create-blog/create-blog.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DescribeLegalProblemModule } from './describe-legal-problem/describe-legal-problem.module';
import { DisclaimerModule } from './disclaimer/disclaimer.module';
import { FaqModule } from './faq/faq.module';
import { ForgotUserModule } from './forgot-user/forgot-user.module';
import { OurThinkingModule } from './our-thinking/our-thinking.module';
import { MembershipModule } from './membership/membership.module';

let config = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(env.socialLoginApIKeys.facebookAppId)
  },
  {
    id: LinkedInLoginProvider.PROVIDER_ID,
    provider: new LinkedInLoginProvider(env.socialLoginApIKeys.linkedInAppId, false, 'en_US'
    )
  }


]);

export function provideConfig() {
  return config;
}
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    AppComponent,
    // LawyerSearchComponent,
    RegisterUserComponent,
    SigninUserComponent,
    // ForgotUserComponent,
    ResetPasswordComponent,
    SignUpComponent,
    // ChangepasswordComponent,
    VerifyUserComponent,
    // AboutUsComponent,
    // OurThinkingComponent,
    LawyerProfileComponent,
    // MembershipComponent,
    // AddLawyerComponent,
    UserProfileComponent,
    PrivacyPolicyComponent,
    TermsOfUseComponent,
    // DisclaimerComponent,
    SucessfullComponent,
    sucessfull_memberComponent,
    sucessfull_dcComponent,
    DC_PaymentCheckoutComponent,
    // LawyerSearchResultComponent,
    TopLawyersComponent,
    HeaderLoginComponent,
    LawyerLoginComponent,
    SigninComponent,
    Safe,
    // ContactUsComponent,
    // DescribeLegalProblemComponent,
    // DashboardComponent,
    // BlogListComponent,
    // BlogDetailsComponent,
    MobileLawyerPageComponent,
    UserDashboardComponent,
    MobileSuccessPageComponent,
    OfficeCollegueListComponent,
    // AddCollegueComponent,
    // AddOfficeComponent,
    PaymentCheckoutComponent,
    PaymentCheckoutMobileComponent,
    EditOfficeComponent,
    EditCollegueComponent,
    ViewCollegueComponent,
    ViewOfficeComponent,
    ViewOffice2Component,
    // FeaturedLawyersComponent,
    specialPipe,
    InvoiceComponent,
    // TruncatePipe,
    ErrorComponent,
    MembershipUpgradeComponent,
    ViewFeedbacksComponent,
    // AdvanceSearchComponent,
    // AdvanceSearchNewComponent,
    // FaqComponent,
    // TestimonyComponent,
    // CreateBlogComponent,
    EditBlogComponent,
    // BlogManagementComponent,
    PaypalComponent,
    ImgDirective

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    RouterModule,
    CommonModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SocialLoginModule,
    NgxSpinnerModule,

    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-center',
      preventDuplicates: true
    }),
    NgxPaginationModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxCaptchaModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDbWbY2HXJh7aIt5s_GI-2_TED0CUAxm8g'
    }),

    //AgmCoreModule.forRoot({
    //    apiKey: 'AIzaSyCSIFuXPQXel1splGkx5ElXoU1bL60Jn - I'
    //}),

    HttpClientModule,
    CarouselModule,
    // CKEditorModule,
    TooltipModule.forRoot(),
    NgxEditorModule,
    AngularFontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),



    LazyModule,

    TestimonyModule,

    AboutUsRouteModule,

    LawyerSignupModule,

    AddCollegueModule,

    AddOfficeModule,

    LawyerSearchModule,

    LawyerSearchResultModule,

    AdvanceSearchNewModule,

    BlogListModule,

    BlogDetailsModule,

    BlogManagementModule,

    FeaturedLawyersModule,

    ChangepasswordModule,

    CreateBlogModule,

    DashboardModule,

    DescribeLegalProblemModule,

    DisclaimerModule,

    FaqModule,

    ForgotUserModule,

    OurThinkingModule,

    MembershipModule

  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    AuthServices,
    AuthGuardService,
    LawyerSearchService,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}