import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'lawyer-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private dataService: DataService, private router: Router, private title: Title, private meta: Meta) { }
  date = new Date();
  currId = null;
  payments = null;
  trans = null;
  ngOnInit() {
    this.title.setTitle("Jurislex - Invoice");
    this.meta.updateTag({ name: 'description', content: 'View your invoice regarding payment.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });
    this.activatedRoute.params.subscribe(params => {
      this.currId = params[`id`];
    })
    this.getPaymentList()
  }

  getPaymentList() {
    this.dataService.spinnerShow();
    let user_id = JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id ? JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id : null;

    this.dataService.getPaymentData(user_id).subscribe(
      (response1: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.payments = response1.body.data;
        console.log('\n\n\n---------', JSON.stringify(this.payments))
        var index = this.payments.findIndex(obj => obj.id == this.currId);
        if (index >= 0) {
          this.trans = this.payments[index];
          console.log('\n\n\n---------', JSON.stringify(this.trans))
        }
        else {
          this.router.navigate(['/dashboard']);
        }


      });
  }
  htmltoPDF() {
    // https://www.quora.com/How-do-I-generate-a-PDF-using-Angular-4-6
    html2canvas(document.querySelector("#invoice")).then(canvas => {
      var imgWidth = 220;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/jpeg', 1.0)
      let pdf = new jsPDF('p', 'mm', 'a4'); // A4 size page of PDF             
      var x_position = 0;
      var y_position = 50;
      pdf.addImage(contentDataURL, 'PNG', x_position, y_position, imgWidth, imgHeight)
      pdf.save('invoice.pdf');
    });

  }
}
