import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../services/seo.service';

declare var $: any;

@Component({
  selector: 'lawyer-lawyer-search',
  templateUrl: './lawyer-search.component.html',
  styleUrls: ['./lawyer-search.component.css']
})
export class LawyerSearchComponent implements OnInit {
  p: number = 1;
  x: number = 0;
  y: number = 0;
  focusFlag: Boolean = false;
  pagination_data = null;
  searchResult = [];
  pagination_value_key:boolean;
  pagination_value:boolean;
  // searchResult2 = [];
  // searchResult3 = [];
  name: string = '';
  city: string = '';
  countries;
  practice_areas;
  pagination_data1: any;



  constructor(private dataService: DataService, private router: Router, private title: Title, private meta: Meta, private seoService: SeoService) { }

  ngOnInit() {
    this.pagination_value_key=true;
    this.seoService.createLinkForCanonicalURL();
    this.title.setTitle("Experienced Lawyer Firms Online Directory | Jurislex");
    this.meta.updateTag({ name: 'description', content: 'Best experienced lawyer with Jurislex, a full service lawyers directory. Search the top rated lawyers in US, UK, India, Canada or any country based on your need' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });
    let data = {
      search: this.name,
      city: this.city,
      current_page: this.p
    };
    this.dataService.spinnerShow();
    this.dataService.getCountries().subscribe((response: HttpResponse<any>) => {
      this.countries = response.body.data;
    });
    this.dataService.getPracticeArea().subscribe((response: HttpResponse<any>) => {
      this.practice_areas = response.body.data.practice_areas;

    });
    this.dataService.lawyerSearchNewAll(data).subscribe((response: HttpResponse<any>) => {
      this.dataService.spinnerHide();
      this.searchResult = response.body.data;
      this.pagination_data1 = response.body.data.length;
      console.log(this.searchResult);
      
    });

    // this.dataService.lawyerSearchGold(data).subscribe((response: HttpResponse<any>) => {
    //   this.dataService.spinnerHide();
    //   console.log("2nd searchResult-->", response.body.data);
    //   this.searchResult2 = response.body.data;
    // });

    // this.dataService.lawyerSearchSilver(data).subscribe((response: HttpResponse<any>) => {
    //   this.dataService.spinnerHide();
    //   console.log("3rd searchResult-->", response.body.data);
    //   this.searchResult3 = response.body.data;
    // });

    $(document).ready(function () {
      $('.filter_sec ul li span').on('click', function () {
        $('.filter_sec ul li span').removeClass('active');
        $(this).addClass('active');
      })
    })

  }

  viewProfile(event, lawyer) {
    // this.router.navigate(['/lawyer-profile', lawyer]);
    this.router.navigate([]).then((result) => {
      window.open('/lawyer-profile/' + lawyer, '_blank');
    });
  }

  onSearch(event) {
    let data = {
      search: this.name,
      city: this.city,
      current_page: 1
    };
    this.dataService
      .lawyerSearchNewAll(data)
      .subscribe((response: HttpResponse<any>) => {
        if (response.body.data) {
          this.searchResult = response.body.data;
          this.pagination_data1 = response.body.data.length;
          console.log(this.searchResult);
        }
      });
  }

  onLawyerFilter(data) {
    this.pagination_value = true;
    this.pagination_value_key=false;
    console.log(data, 'data');
    this.dataService
      .getLawyerByFilters(data)
      .subscribe((response: HttpResponse<any>) => {
        console.log(response.body.data);
        if (response.body.data) this.searchResult = response.body.data;
        this.pagination_data1 = response.body.data.length;
      });
  }

  onChange(event) {
    // console.log(event)
    this.onLawyerFilter(event);
  }

  onPageChange(page: number) {
    this.dataService.spinnerShow();
    this.p = page;
    let data = {
      search: this.name,
      city: this.city,
      current_page: page
    };
    this.dataService.lawyerSearchNewAll(data).subscribe((response: HttpResponse<any>) => {
      this.dataService.spinnerHide();
      this.searchResult = response.body.data;
      this.pagination_data1 = response.body.data.length;
      console.log(this.searchResult);
      
    });
    window.scrollTo({
      top: 600,
      behavior: 'smooth',
    });
  }



}
