import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './core-module/home/home.component';
// import { LawyerSearchComponent } from './lawyer-search/lawyer-search.component';
// import { RegisterUserComponent } from './register-user/register-user.component';
// import { SigninUserComponent } from './signin-user/signin-user.component';
// import { ForgotUserComponent } from './forgot-user/forgot-user.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
// import { ChangepasswordComponent } from './changepassword/changepassword.component';
import { VerifyUserComponent } from './verify-user/verify-user.component';
// import { AboutUsComponent } from './about-us/about-us.component';
// import { OurThinkingComponent } from './our-thinking/our-thinking.component';
import { LawyerProfileComponent } from './lawyer-profile/lawyer-profile.component';
// import { MembershipComponent } from './membership/membership.component';
import { MembershipUpgradeComponent } from './membership-upgrade/membership-upgrade.component';
// import { AddLawyerComponent } from './add-lawyer/add-lawyer.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
// import { DisclaimerComponent } from './disclaimer/disclaimer.component';
// import { FaqComponent } from './faq/faq.component';
// import { TestimonyComponent } from './testimony/testimony.component';
// import { LawyerSearchResultComponent } from './lawyer-search-result/lawyer-search-result.component';
import { TopLawyersComponent } from './top-lawyers/top-lawyers.component';
// import { SignUpComponent } from './sign-up/sign-up.component';
import { AuthGuardService } from './services/auth-guard.service';
import { LawyerLoginComponent } from './lawyer-login/lawyer-login.component';
// import { SigninComponent } from './signin/signin.component';
// import { ContactUsComponent } from './contact-us/contact-us.component';
// import { DescribeLegalProblemComponent } from './describe-legal-problem/describe-legal-problem.component';
// import { CreateBlogComponent } from './create-blog/create-blog.component';
import { EditBlogComponent } from './edit-blog/edit-blog.component';
// import { BlogManagementComponent } from './blog-management/blog-management.component';
// import { DashboardComponent } from './dashboard/dashboard.component';
import { MobileLawyerPageComponent } from './mobile-lawyer-page/mobile-lawyer-page.component';
import { UserDashboardComponent } from './user-dashboard/user-dashboard.component';
// import { BlogListComponent } from './blog-list/blog-list.component';
// import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { MobileSuccessPageComponent } from './mobile-success-page/mobile-success-page.component';
import { OfficeCollegueListComponent } from './office-collegue-list/office-collegue-list.component';
// import { AddCollegueComponent } from './add-collegue/add-collegue.component';
import { EditCollegueComponent } from './edit-collegue/edit-collegue.component';
// import { AddOfficeComponent } from './add-office/add-office.component';
import { EditOfficeComponent } from './edit-office/edit-office.component';
import { ViewCollegueComponent } from './view-collegue/view-collegue.component';
import { ViewOfficeComponent } from './view-office/view-office.component';
import { ViewOffice2Component } from './view-office-2/view-office-2.component';
import { InvoiceComponent } from './invoice/invoice.component';

import { PaymentCheckoutComponent } from './payment-checkout/payment-checkout.component';
import { PaymentCheckoutMobileComponent } from './payment-checkout-mobile/payment-checkout-mobile.component';
// import { FeaturedLawyersComponent } from './featured-lawyers/featured-lawyers.component';
import { ErrorComponent } from './error/error.component';
import { ViewFeedbacksComponent } from './view-feedbacks/view-feedbacks.component';
// import { AdvanceSearchComponent } from './advance-search/advance-search.component';
// import { AdvanceSearchNewComponent } from './advance-search-new/advance-search-new.component';
import { SucessfullComponent } from './sucessfull/sucessfull.component';
import { sucessfull_memberComponent } from './sucessfull_member/sucessfull_member.component';
import { sucessfull_dcComponent } from './sucessfull_dc/sucessfull_dc.component';
import { DC_PaymentCheckoutComponent } from './payment-checkout-dc/payment-checkout-dc.component';

const routes: Routes = [
  // { path: 'wrapper', loadChildren: () => import('./shared-module/shared-module.module').then(m => m.SharedModuleModule) },
  // { path: 'sign-up', component: SignUpComponent },
  // { path: 'sign-in', component: SigninComponent },
  // { path: 'contact-us', loadChildren: './lazy/lazy.module#LazyModule' },
  {path: 'contact-us', loadChildren:()=>import("./lazy/lazy.module").then(m=>m.LazyModule)},
  // { path: 'contact-us/:type', loadChildren: './lazy/lazy.module#LazyModule' },
  { path: 'contact-us/:type', loadChildren:()=>import('./lazy/lazy.module').then(m=>m.LazyModule)},
  // { path: 'sign-up', loadChildren: './membership/membership.module#MembershipModule' },
  { path: 'sign-up', loadChildren:()=>import('./membership/membership.module').then(m=>m.MembershipModule)},
  { path: 'sign-in', component: LawyerLoginComponent },
  { path: 'sucessfull', component: SucessfullComponent },
  { path: 'sucessfull_member', component: sucessfull_memberComponent },
  { path: 'sucessfull_dc', component: sucessfull_dcComponent },
  { path: 'DC_PaymentCheckout', component: DC_PaymentCheckoutComponent },
  // { path: 'lawyer-search', loadChildren: './lawyer-search/lawyer-search.module#LawyerSearchModule' },
  { path: 'lawyer-search', loadChildren:()=>import('./lawyer-search/lawyer-search.module').then(m=>m.LawyerSearchModule)},
  // { path: 'register-user', component: RegisterUserComponent }, commented as per client request
  // { path: 'signin-user', component: SigninUserComponent },commented as per client request
  // { path: 'forgotpassword-user', loadChildren: './forgot-user/forgot-user.module#ForgotUserModule' },
  { path: 'forgotpassword-user', loadChildren:()=>import('./forgot-user/forgot-user.module').then(m=>m.ForgotUserModule)},
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  // { path: 'changePassword', loadChildren: './changepassword/changepassword.module#ChangepasswordModule' },
  { path: 'changePassword', loadChildren: ()=>import('./changepassword/changepassword.module').then(m=>m.ChangepasswordModule) },
  { path: 'verify/:token', component: VerifyUserComponent },
  // { path: 'about-us', loadChildren: './about-us-route/about-us-route.module#AboutUsRouteModule' },
  { path: 'about-us',loadChildren:()=>import('./about-us-route/about-us-route.module').then(m=>m.AboutUsRouteModule)},
  // { path: 'our-thinking', loadChildren: './our-thinking/our-thinking.module#OurThinkingModule' },
  { path:'our-thinking', loadChildren: ()=> import('./our-thinking/our-thinking.module').then(m=>m.OurThinkingModule)},
  { path: 'lawyer-profile/:id', component: LawyerProfileComponent },
  {
    path: 'membership',
    // loadChildren: './membership/membership.module#MembershipModule'
    loadChildren:()=>import('./membership/membership.module').then(m=>m.MembershipModule)
  },
  {
    path: 'membership-upgrade',
    component: MembershipUpgradeComponent,
    canActivate: [AuthGuardService]
  },

  // { path: 'register-lawyer/:id/:free', loadChildren: './lawyer-signup/lawyer-signup.module#LawyerSignupModule' },
  { path: 'register-lawyer/:id/:free', loadChildren:()=>import('./lawyer-signup/lawyer-signup.module').then(m=>m.LawyerSignupModule)},
  // { path: 'register-lawyer/:id', loadChildren: './lawyer-signup/lawyer-signup.module#LawyerSignupModule' },
  { path: 'register-lawyer/:id', loadChildren:()=>import('./lawyer-signup/lawyer-signup.module').then(m=>m.LawyerSignupModule)},
  { path: 'signin-lawyer', component: LawyerLoginComponent },
  {
    path: 'user-profile',
    component: UserProfileComponent,
    canActivate: [AuthGuardService]
  },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  // { path: 'contact-us', component: ContactUsComponent },

  {
    path: 'create-article',
    // loadChildren: './create-blog/create-blog.module#CreateBlogModule',
    loadChildren: ()=>import('./create-blog/create-blog.module').then(m=>m.CreateBlogModule),
    canActivate: [AuthGuardService]
  },

  { path: 'edit-article/:id', component: EditBlogComponent, canActivate: [AuthGuardService] },
  {
    path: 'legal-article',
    // loadChildren: './blog-management/blog-management.module#BlogManagementModule',
    loadChildren:()=>import('./blog-management/blog-management.module').then(m=>m.BlogManagementModule),
    canActivate: [AuthGuardService]
  },
  // { path: 'contact-us/:type', component: ContactUsComponent },
  // { path: 'describe-legal-problem', loadChildren: './describe-legal-problem/describe-legal-problem.module#DescribeLegalProblemModule' },
  {path: 'describe-legal-problem/',loadChildren:()=>import('./describe-legal-problem/describe-legal-problem.module').then(m=>m.DescribeLegalProblemModule)},
  // { path: 'describe-legal-problem/:type', loadChildren: './describe-legal-problem/describe-legal-problem.module#DescribeLegalProblemModule', },
  {path: 'describe-legal-problem/:type',loadChildren:()=>import('./describe-legal-problem/describe-legal-problem.module').then(m=>m.DescribeLegalProblemModule)},
  { path: 'terms-and-conditions', component: TermsOfUseComponent },
  // { path: 'disclaimer', loadChildren: './disclaimer/disclaimer.module#DisclaimerModule' },
  {path:'disclaimer', loadChildren:()=>import("./disclaimer/disclaimer.module").then(m=>m.DisclaimerModule)},
  // { path: 'faq', loadChildren: './faq/faq.module#FaqModule' },
  {path:'faq', loadChildren:()=>import("./faq/faq.module").then(m=>m.FaqModule)},
  // { path: 'testimony', loadChildren: './testimony/testimony.module#TestimonyModule' },
  {path:'testimony', loadChildren:()=>import("./testimony/testimony.module").then(m=>m.TestimonyModule)},
  {
    path: 'lawyer-search-result/:type/:country',
    // loadChildren: './lawyer-search-result/lawyer-search-result.module#LawyerSearchResultModule'
    loadChildren:()=>import("./lawyer-search-result/lawyer-search-result.module").then(m=>m.LawyerSearchResultModule),
  },
  {
    path: 'top-lawyers',
    component: TopLawyersComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'dashboard',
    // loadChildren: './dashboard/dashboard.module#DashboardModule',
    loadChildren:()=>import("./dashboard/dashboard.module").then(m=>m.DashboardModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'user-dashboard',
    component: UserDashboardComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'register-lawyer-mobile/:id/:language',
    component: MobileLawyerPageComponent
  },
  {
    path: 'register-lawyer-mobile/:id',
    component: MobileLawyerPageComponent
  },
  {
    path: 'blogs',
    // loadChildren: './blog-list/blog-list.module#BlogListModule'
    loadChildren:()=>import('./blog-list/blog-list.module').then(m=>m.BlogListModule)
  },
  {
    path: 'blogs/:id',
    // loadChildren: './blog-details/blog-details.module#BlogDetailsModule'
    loadChildren:()=>import('./blog-details/blog-details.module').then(m=>m.BlogDetailsModule)
  },
  {
    path: 'mobile-success',
    component: MobileSuccessPageComponent
  },
  {
    path: 'view-office-collegues',
    component: OfficeCollegueListComponent
  },
  {
    path: 'add-collegue',
    // loadChildren: './add-collegue/add-collegue.module#AddCollegueModule',
    loadChildren:()=>import('./add-collegue/add-collegue.module').then(m=>m.AddCollegueModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'edit-collegue/:id',
    component: EditCollegueComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'view-collegue/:id/:lawyer_id',
    component: ViewCollegueComponent,
  },
  {
    path: 'view-office/:id/:lawyer_id',
    component: ViewOfficeComponent,
  },
  {
    path: 'view-office-2/:id/:lawyer_id',
    component: ViewOffice2Component,
  },
  {
    path: 'add-office',
    // loadChildren: './add-office/add-office.module#AddOfficeModule',
    loadChildren:()=>import('./add-office/add-office.module').then(m=>m.AddOfficeModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'edit-office/:id',
    component: EditOfficeComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'payment-checkout/:planId/:apiToken',
    component: PaymentCheckoutComponent
  },
  {
    path: 'payment-checkout-dc',
    component: DC_PaymentCheckoutComponent
  },
  {
    path: 'payment-checkout-mobile/:planId/:apiToken',
    component: PaymentCheckoutMobileComponent
  },
  {
    path: 'invoice/:id',
    component: InvoiceComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'featured-lawyers',
    // loadChildren: './featured-lawyers/featured-lawyers.module#FeaturedLawyersModule'
    loadChildren:()=>import('./featured-lawyers/featured-lawyers.module').then(m=>m.FeaturedLawyersModule)
  },
  {
    path: 'view-feedbacks/:id',
    component: ViewFeedbacksComponent
  },
  // {
  //   path: 'advance-search/:text',
  //   component: AdvanceSearchComponent
  // },
  {
    path: 'advance-search/:text',
    // loadChildren: './advance-search-new/advance-search-new.module#AdvanceSearchNewModule'
    loadChildren:()=>import('./advance-search-new/advance-search-new.module').then(m=>m.AdvanceSearchNewModule)
  },
  {
    path: 'advance-search',
    // loadChildren: './advance-search-new/advance-search-new.module#AdvanceSearchNewModule'
    loadChildren:()=>import('./advance-search-new/advance-search-new.module').then(m=>m.AdvanceSearchNewModule)
  },
  {
    path: 'not-found',
    component: ErrorComponent
  },
  { path: '', component: HomeComponent, pathMatch: 'full' },
  {
    path: '**', redirectTo: '/not-found', runGuardsAndResolvers: 'always',
  }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  // imports: [RouterModule.forRoot(routes,{ useHash: true })],
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
