import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'lawyer-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(private dataService: DataService, private title: Title, private meta: Meta) {
    this.dataService.getLanguageSignal.subscribe(response => {
      console.log("Privacy  ne sun liya malik")
      this.getContent(response);
    });
  }
  myContent;
  ngOnInit() {
    let lang = localStorage.getItem('prefered_language');
    this.title.setTitle("Jurislex - Privacy Policy");
    this.meta.updateTag({ name: 'description', content: ' View jurislex website privacy policy here..' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal,Privacy Policy' });
    this.getContent(lang);
  }
  getContent(lang) {
    this.dataService.getContent(lang).subscribe((response: HttpResponse<any>) => {
      this.myContent = response.body.data[2].description;
    });
  }
}
