import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// import { AuthServices } from 'src/app/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from '../../services/data.service';
import { HttpResponse } from '@angular/common/http';

declare let $: any;

@Component({
  selector: 'lawyer-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  serch_icon = '../../../assets/images/serch_icon.jpeg';
  // link_text = 'switch to attorney';  
  link_text = 'List Your Firm';
  // link = '/signin-lawyer'
  link = '/membership'
  searchLocation: string = '';
  languages = null;
  selected_lang = 'en';
  constructor(private dataService: DataService,
    private router: Router,
    // private authService: AuthServices,
    // private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) { }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 50) {
      let element = document.getElementById('header');
      element.classList.add('fixed');
    } else {
      let element = document.getElementById('header');
      element.classList.remove('fixed');
    }
  }

  ngOnInit() {
    if (localStorage.getItem('prefered_language')) {
      this.selected_lang = localStorage.getItem('prefered_language');
    }

    this.getLanguages();

    // $('select.select').each(function () {
    //   console.log('test', $(this));
    //   var title = $(this).attr('title');
    //   if ($('option:selected', this).val() != '')
    //     title = $('option:selected', this).text();
    //   $(this)
    //     .css({
    //       'z-index': 10,
    //       opacity: 0,
    //       '-khtml-appearance': 'none'
    //     })
    //     .after('<span class="select">' + title + '</span>')
    //     .change(function () {
    //       var val = $('option:selected', this).text();
    //       $(this)
    //         .next()
    //         .text(val);
    //     });
    // });


    //Comment as per client feedback

    // this.router.events.subscribe((val) => {      
    //   if ((<NavigationEnd>val).url === '/signin-lawyer') {
    //     this.link_text = 'switch to customer'
    //     this.link = '/signin-user'
    //   }
    //   else if ((<NavigationEnd>val).url === '/signin-user') {
    //     this.link_text = 'switch to attorney'
    //     this.link = '/signin-lawyer'
    //   }
    // });


    this.router.events.subscribe((val) => {

      if ((<NavigationEnd>val).url === '/signin-lawyer') {
        this.link_text = 'List Your Firm'
        this.link = '/membership'
      }
      else if ((<NavigationEnd>val).url === '/signin-user') {
        this.link_text = 'List Your Firm'
        this.link = '/membership'
      }
    });



    // document.getElementById('search').innerHTML =
    //   '<gcse:search enableAutoComplete="true"></gcse:search>';

    // $(function() {
    //   $('#custom-select').selectric({
    //     disableOnMobile: false,
    //     nativeOnMobile: false,
    //     maxHeight: 150
    //   });
    // });
    // $('select.select').each(function () {
    //   console.log('test', $(this));
    //   var title = $(this).attr('title');
    //   if ($('option:selected', this).val() != '')
    //     title = $('option:selected', this).text();
    //   $(this)
    //     .css({
    //       'z-index': 10,
    //       opacity: 0,
    //       '-khtml-appearance': 'none'
    //     })
    //     .after('<span class="select">' + title + '</span>')
    //     .change(function () {
    //       var val = $('option:selected', this).text();
    //       $(this)
    //         .next()
    //         .text(val);
    //     });
    // });
    // $(window).scroll(function () {
    //   var headerCntr = $('.headerCntr'),
    //     scroll = $(window).scrollTop();
    //   if (scroll >= 40) headerCntr.addClass('fixed');
    //   else headerCntr.removeClass('fixed');
    // });

    $('.mobile_icon').click(function () {
      $('.nav_bar').slideToggle();
    });

    function windowWidth() {
      var windwdth = $(window).width();

      if (windwdth < 767) {
        $('.nav_bar ul li a').on('click', function () {
          $('.nav_bar').slideUp();
          $('.mobile_icon').removeClass('active');
        })

        $('.logo_box a').on('click', function () {
          $('.nav_bar').slideUp();
          $('.mobile_icon').removeClass('active');
        })

        $('.login_user a').on('click', function () {
          $('.nav_bar').slideUp();
          $('.mobile_icon').removeClass('active');
        })



      }
    }

    windowWidth();

    $(window).scroll(function () {
      windowWidth();
    })

    $(window).resize(function () {
      windowWidth();
    })


    $('.mobile_icon').click(function () {
      $('.mobile_icon').toggleClass('active');
    });

    setTimeout(function () {
      $('.find_global').show();
    }, 2800);
    $('.login_user a').click(function () {
      $('.user-detail').slideToggle();
    });

    $(document).click(function (e) {
      if (!$(e.target).is('.login_user, .login_user *')) {
        $('.user-detail').hide();
      }
    });

  }

  getLanguages() {
    this.dataService.spinnerShow();
    this.dataService.getLanguages().subscribe(
      (response: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.languages = response.body.data;

      }
      , (error: any) => {
        this.dataService.spinnerHide();
      });


  }


  copyMessage(val: string) {
    val = window.location.href;
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }


  onLogout() {
    this.router.navigate(['/sign-in']);
    localStorage.removeItem('websiteCurrentUser');
    localStorage.removeItem('websiteToken');
  }


  lawyerSearchRedirect(searchLocation) {
    this.router.navigate(['advance-search', searchLocation]);

  }

  search(event) {
    if (event.key === 'Enter') {
      this.router.navigate(['advance-search', this.searchLocation]);
    }
  }

  searchs() {
    this.router.navigate(['advance-search', this.searchLocation]);
  }

  useLanguage(language) {
    localStorage.setItem('prefered_language', language);
    this.translate.use(language);
    this.dataService.changeLang(language);
  }
}
