import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OurThinkingComponent } from './our-thinking.component';
const routes: Routes = [
  { path: '', component: OurThinkingComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OurThinkingRoutingModule { }
