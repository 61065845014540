import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'lawyer-office-collegue-list',
  templateUrl: './office-collegue-list.component.html',
  styleUrls: ['./office-collegue-list.component.css']
})
export class OfficeCollegueListComponent implements OnInit {

  constructor(private dataService: DataService, private router: Router, private title: Title, private meta: Meta) { }
  offices = [];
  collegue = [];
  max_lawyers: any;
  max_offices: any;
  total_collegue: any;
  total_offices: any;
  user_id = null;
  ngOnInit() {
    this.title.setTitle("Jurislex - Collegue List");
    this.meta.updateTag({ name: 'description', content: 'This page is about viewing all your collegues and offices in your firm .' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal, Office collegue List' });
    let localData = JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data ? JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data : null;
    this.user_id = localData.id;
    this.max_offices = localData.max_offices;
    this.max_lawyers = localData.max_lawyers;

    this.getOfficeList();
    this.getColleagueList();
  }

  getOfficeList() {
    this.dataService.spinnerShow();
    this.dataService.getOffices(this.user_id).subscribe(
      (response: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.offices = response.body.data;
        this.total_offices = this.offices.length;
        // console.log('this.offices', this.offices);
      });
  }

  getColleagueList() {
    this.dataService.spinnerShow();
    this.dataService.getCollegueList(this.user_id).subscribe(
      (response1: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.collegue = response1.body.data;
        this.total_collegue = this.collegue.length;
      });
  }

  openSuccessCancelSwal(data, type) {

    swal.fire({
      title: 'Are you sure?',
      text: 'You not be able to revert this!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      confirmButtonClass: 'btn btn-success',
      cancelButtonClass: 'btn btn-danger mr-sm',
    }).then(result => {
      if (result.value) {
        const req = {
          id: data,
        }
        if (type == "delete_office") {
          this.dataService.deleteOffice(req).subscribe((response: HttpResponse<any>) => {
            // console.log(response)
            // console.log(response.status)
            if (response.status === 200) {
              this.getOfficeList();
              swal.fire('Deleted!', 'You have deleted office successfully.', 'success');
            }
          });
        }
        else if (type == "delete_colleague") {
          this.dataService.deleteColleague(req).subscribe((response1: HttpResponse<any>) => {
            // console.log(response1)
            // console.log(response1.status)
            if (response1.status === 200) {
              this.getColleagueList();
              swal.fire('Deleted!', 'You have deleted profile successfully.', 'success');
            }
          });
        }
      }
      else if (result.dismiss) {
        swal.fire('Cancelled', 'You have cancelled deletion request.', 'error');
      }
    })

  }

  viewOffice(office_id) {
    this.router.navigate([`../view-office`, office_id.toString(), this.user_id.toString()]);
  }

  editOffice(office_id) {
    this.router.navigate([`../edit-office`, office_id.toString()]);
  }

  viewCollegue(collegue_id) {
    this.router.navigate([`../view-collegue`, collegue_id.toString(), this.user_id.toString()]);
  }
  editCollegue(collegue_id) {
    this.router.navigate([`../edit-collegue`, collegue_id.toString()]);
  }

}
