import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LazyRoutingModule } from './lazy-routing.module';
import { ContactUsComponent } from './contact-us.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxCaptchaModule } from 'ngx-captcha';
@NgModule({
  declarations: [ContactUsComponent],
  imports: [
    CommonModule,
    LazyRoutingModule,
    TranslateModule,
    NgxCaptchaModule,
    FormsModule, ReactiveFormsModule

  ],
  exports: [
    CommonModule,
    LazyRoutingModule,
    TranslateModule,
    NgxCaptchaModule,
    FormsModule, ReactiveFormsModule

  ]
})
export class LazyModule { }
