import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LawyerSearchService } from '../services/lawyer-search.service';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'lawyer-lawyer-search-result',
  templateUrl: './lawyer-search-result.component.html',
  styleUrls: ['./lawyer-search-result.component.css']
})
export class LawyerSearchResultComponent implements OnInit {
  type: string;
  country: string;
  searchResult: any = [];
  pagination_data = null;
  // searchResult2: any = [];
  // searchResult3: any = [];
  p: number = 1;
  constructor(
    private currentRoute: ActivatedRoute,
    private lawyerSearchService: LawyerSearchService,
    private router: Router, private title: Title, private meta: Meta
  ) {

    this.currentRoute.params.subscribe(params => {
      this.country = params['country'];
      this.type = params['type'];

      this.searchFirm(this.type, this.country);

      // console.log(this.type)
      // console.log(this.country)
      //this.searchFirm("Lawyer","Israel")
    });

  }

  ngOnInit() {
    this.title.setTitle("Jurislex - Lawyer Search Result");
    this.meta.updateTag({ name: 'description', content: 'Lawyer directory platform. Please browse all lawyers in your area here . Ratings, Experience .' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });
  }

  searchFirm(firmType, firmLocation) {
    this.lawyerSearchService.spinnerShow();
    if (firmType == '' || firmType == undefined) {
      firmType = '';
    }
    if (firmLocation == '' || firmLocation == undefined) {
      firmLocation = '';
    }
    this.lawyerSearchService
      .searchLawyer(firmType, firmLocation, this.p)
      .subscribe((response: HttpResponse<any>) => {
        if (response.body.data) {
          this.searchResult = response.body.data;
          this.pagination_data = response.body.pagination_data
        }

      });

    // this.lawyerSearchService
    //   .searchLawyerGold(firmType, firmLocation)
    //   .subscribe((response: HttpResponse<any>) => {
    //     if (response.body.data) this.searchResult2 = response.body.data;
    //     //console.log(this.searchResult)
    //   });

    // this.lawyerSearchService
    //   .searchLawyerSilver(firmType, firmLocation)
    //   .subscribe((response: HttpResponse<any>) => {
    //     if (response.body.data) this.searchResult3 = response.body.data;
    //     //console.log(this.searchResult)
    //   });


    this.lawyerSearchService.spinnerHide();
  }

  //viewProfile(office, lawyer) {
  //  console.log(lawyer);
  // // this.router.navigate(['/lawyer-profile', lawyer]);
  //    this.router.navigate(['/view-office', office, lawyer]);
  //  }

  viewProfile(event, lawyer) {
    // this.router.navigate(['/lawyer-profile', lawyer]);
    this.router.navigate([]).then((result) => {
      window.open('/lawyer-profile/' + lawyer, '_blank');
    });
  }

  onPageChange(page: number) {
    this.p = page;
    this.searchFirm(this.type, this.country)
    window.scrollTo({
      top: 100,
      behavior: 'smooth',
    });
  }
}
