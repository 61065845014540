import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturedLawyersComponent } from './featured-lawyers.component';
import { FeaturedLawyersRoutingModule } from './featured-lawyers-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModuleModule } from '../shared-module/shared-module.module'
import { NgxPaginationModule } from 'ngx-pagination';
@NgModule({
  declarations: [FeaturedLawyersComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FeaturedLawyersRoutingModule,
    SharedModuleModule,
    NgxPaginationModule
  ]
})
export class FeaturedLawyersModule { }
