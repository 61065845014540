import { Component, OnInit } from '@angular/core';
import { DataService } from '../services/data.service';
import { ActivatedRoute } from '@angular/router';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'lawyer-view-feedbacks',
  templateUrl: './view-feedbacks.component.html',
  styleUrls: ['./view-feedbacks.component.css']
})
export class ViewFeedbacksComponent implements OnInit {
  id;
  feedbacks = [];
  average
  constructor(private dataService: DataService, private currentRoute: ActivatedRoute) {
    this.currentRoute.params.subscribe(params => {
      this.id = params['id'];
      this.getLawyerFeedbacks();
      // this.searchFirm("Lawyer","Israel")
    });
  }

  ngOnInit() {

  }

  getLawyerFeedbacks() {
    this.dataService.getLawyerFeedbacks(this.id).subscribe((response: HttpResponse<any>) => {
      this.feedbacks = response['body'].data;
      
      this.average = (this.feedbacks.reduce(function (acc, obj) { return acc + +obj.rating; }, 0)) / this.feedbacks.length;
      


    }, (error: any) => {
      console.log(error);
    })

  }

}
