import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DataService } from '../../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
// import { AuthServices } from '../../services/auth.service';

declare let jQuery: any;

@Component({
	selector: 'lawyer-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})


export class FooterComponent implements OnInit {

	topscroll_icon = "../../../assets/images/topscroll_icon.png"
	copyright_date = new Date().getFullYear();
	newsletterform: FormGroup;
	submitted = false;
	isLoggedIn;
	constructor(private dataService: DataService, private toastrService: ToastrService, private translate: TranslateService, private router: Router,) { }
	ngOnInit() {
		this.createForm();
		jQuery(document).ready(function () {

			var offset = 250;
			var duration = 500;
			jQuery(window).scroll(function () {
				if (jQuery(this).scrollTop() > offset) {
					jQuery('.back-to-top').fadeIn(duration);
				} else {
					jQuery('.back-to-top').fadeOut(duration);
				}
			});

			jQuery('.back-to-top').click(function (event) {
				event.preventDefault();
				jQuery('html, body').animate({ scrollTop: 0 }, duration);
				return false;
			})
		});
	}

	createForm() {
		this.newsletterform = new FormGroup({

			name: new FormControl(null, Validators.compose([
				Validators.required,
			])),
			email: new FormControl(null, Validators.compose([
				Validators.required,
				Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")
			])
			),
		})
	}

	addEmail() {
		this.submitted = true;
		if (this.newsletterform.invalid) {
			return;
		}
		console.log(this.newsletterform.value)
		this.dataService.spinnerShow();
		this.dataService.subscribeNews(this.newsletterform.value).subscribe(
			(response: HttpResponse<any>) => {
				this.dataService.spinnerHide();
				if (response.body.status_code === 0) {
					this.toastrService.success(response.body.message);
					this.newsletterform.reset()
					this.submitted = false;
				}
				if (response.body.status_code === 1) {
					this.toastrService.error(response.body.message);
				}
			}, error => {
				this.dataService.spinnerHide();
				this.toastrService.error(error.error['message']);
			}
		);

	}

	viewAbout() {

		this.router.navigate(['/about-us']);
	}


}