import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdvanceSearchNewComponent } from './advance-search-new.component';
const routes: Routes = [
  { path: '', component: AdvanceSearchNewComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdvanceSearchNewRoutingModule { }
