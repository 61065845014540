import { Injectable } from '@angular/core';

import { ScriptLoadingService } from './script-loading.service';
import { environment } from 'src/environments/environment';
import { PayPalConfig } from '../models/pay-pal-config';

@Injectable({
    providedIn: 'root'
})
export class PaypalScriptService {
    private baseUrl: string = 'https://www.paypal.com/sdk/js';
    private globalVar: string = 'paypal';

    constructor(
        private scriptLoadingService: ScriptLoadingService,
    ) { }

    registerScript(configuration: PayPalConfig, loaded: (payPalApi: any) => void): void {
        this.scriptLoadingService.registerScript(this.getPayPalUrl(configuration), this.globalVar, loaded);
    }

    getPayPalUrl(configuration: PayPalConfig): string {
        let url: string = `${this.baseUrl}?client-id=${
            environment.payment.paypal.clientId
            }`;
        if (configuration.components) {
            url += `&components=${configuration.components.join(',')}`;
        }

        if (configuration.currency) {
            url += `&currency=${configuration.currency}`;
        }
        return url;
    }
}
