import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

declare var $: any;
@Component({
  selector: 'lawyer-add-office',
  templateUrl: './add-office.component.html',
  styleUrls: ['./add-office.component.css']
})
export class AddOfficeComponent implements OnInit {

  addOfficeForm: FormGroup;
  constructor(private dataService: DataService, private toastrService: ToastrService, private router: Router, private title: Title, private meta: Meta) { }
  countries = null;
  states = null;
  cities = null;
  curr_user = null;
  data = {};
  submitted = false;
  specializations;
  user = null;
  areaRequired = false;
  max_offices: any;
    total_offices: any;
    img = null;
  ngOnInit() {
    let localData = JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data ? JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data : null;
    const user_id = localData.id;
    this.max_offices = localData.max_offices;
    this.dataService.getOffices(user_id).subscribe(
      (response: HttpResponse<any>) => {
        this.total_offices = response.body.data.length;
        if (this.total_offices >= this.max_offices) {
          this.router.navigate(['/'])
        }
      });

    this.getData();
    this.createForm();
    this.title.setTitle("Jurislex - Add Office");
    this.meta.updateTag({ name: 'description', content: 'This page is for adding additional office to your firm.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });

  }

  getData() {
    this.dataService.spinnerShow();
    this.dataService.getSpecializations().subscribe((response: HttpResponse<any>) => {
      this.specializations = response.body.data;
    });
    this.dataService.getCountries().subscribe((response: HttpResponse<any>) => {
      this.dataService.spinnerHide();
      this.countries = response.body.data;
    });

  }
  createForm() {
    this.addOfficeForm = new FormGroup({

      official_name: new FormControl(null, Validators.compose([
        Validators.required,
      ])),

      contact_web_link: new FormControl(null, Validators.compose([
        Validators.required,
      ])),

      main_firm_email: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")
      ])
      ),

      main_contact_name: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.minLength(3),
        Validators.pattern("^[a-zA-Z. ]{3,50}")
      ])
      ),

      main_contact_email: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")
      ])
      ),
    

    
      address_1: new FormControl(null, Validators.compose([
        Validators.required,
      ])),      

      country_id: new FormControl(null, Validators.compose([
        Validators.required,
      ])),

      state_id: new FormControl(null, Validators.compose([])),

      city_id: new FormControl(null, Validators.compose([])),
      

      phone: new FormControl(null, Validators.compose([
        Validators.required,])),
        countrycode: new FormControl(null, Validators.compose([
            Validators.required,])),

        faxcountrycode: new FormControl(null, []),
        fax: new FormControl(null, []),
      total_lawyers: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.min(1),
      ])),
      firm_founded_year: new FormControl(null, Validators.compose([
        Validators.required,
        Validators.pattern("^[0-9]{4}")
      ])),
      languages: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
      firm_practice_description: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
      firm_headline_title: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
      firm_overview: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
      free_consultation: new FormControl(false, Validators.compose([
        Validators.required,
      ])),
      contingency_fee_cases: new FormControl(false, Validators.compose([
        Validators.required,
      ])),
      accepts_creditcard: new FormControl(false, Validators.compose([
        Validators.required,
      ])),
      firm_website: new FormControl(null, Validators.compose([
        Validators.required,
      ])),
      
      firm_affiliation_networks: new FormArray([]),

        //facebook: new FormControl(null, Validators.compose([
        //    Validators.max(200),
        //])),

        //linkedin: new FormControl(null, Validators.compose([
        //    Validators.max(200),
        //])),

        //twitter: new FormControl(null, Validators.compose([
        //    Validators.max(200),
        //])),

        //instagram: new FormControl(null, Validators.compose([

        //    Validators.max(200),
        //])),

        //youtube: new FormControl(null, Validators.compose([

        //    Validators.max(200),
        //])),

        google_map_url: new FormControl(null, Validators.compose([])),

        firm_logo: new FormControl(null, Validators.compose([])),

        firm_video: new FormControl(null, Validators.compose([])),
        firm_video_desc: new FormControl(null, Validators.compose([])),

      
    });
  }

  addOffice() {
    this.submitted = true;
    if (this.addOfficeForm.invalid) {
      return;
    }
    this.user = JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id ? JSON.parse(localStorage.getItem(`websiteCurrentUser`)).data.id : null;
    //let practice_area = '';
    //$('.practice_area .row .check-box').each(function () {
    //  if (
    //    $(this)
    //      .find('input')
    //      .is(':checked')
    //  ) {
    //    var text = $(this).find('p').context.innerText;
    //    practice_area += text + ',';
    //  }
    //});

    //const prac_area = Object.assign(this.addOfficeForm.value, {
    //  areas_of_practice: practice_area
    //})

    //if (this.addOfficeForm.value.areas_of_practice.length == 0) {
    //  this.areaRequired = true;
    //  return;
    //}

    this.data = {
      "user_id": this.user,
      "official_name": this.addOfficeForm.value.official_name,
      "contact_web_link": this.addOfficeForm.value.contact_web_link,
      "main_firm_email": this.addOfficeForm.value.main_firm_email,
      "main_contact_name": this.addOfficeForm.value.main_contact_name,
      "main_contact_email": this.addOfficeForm.value.main_contact_email,
     
      "address": this.addOfficeForm.value.address_1,
      "country_id": this.addOfficeForm.value.country_id,
      "state_id": this.addOfficeForm.value.state_id,
      "city_id": this.addOfficeForm.value.city_id,
     
        "phone": this.addOfficeForm.value.phone,
        "countrycode": this.addOfficeForm.value.countrycode,
        "faxcountrycode": this.addOfficeForm.value.faxcountrycode,
      
      "fax": this.addOfficeForm.value.fax,
      "firm_founded_year": parseInt(this.addOfficeForm.value.firm_founded_year),
      "languages": this.addOfficeForm.value.languages,
      "total_lawyers": parseInt(this.addOfficeForm.value.total_lawyers),
      "firm_headline_title": this.addOfficeForm.value.firm_headline_title,
      "firm_overview": this.addOfficeForm.value.firm_overview,
      "free_consultation": this.addOfficeForm.value.free_consultation,
      "contingency_fee_cases": this.addOfficeForm.value.contingency_fee_cases,
      "accepts_creditcard": this.addOfficeForm.value.accepts_creditcard,
      "firm_website": this.addOfficeForm.value.firm_website,
      
        "firm_affiliation_networks": this.addOfficeForm.value.firm_affiliation_networks,
        //"facebook": this.addOfficeForm.value.facebook,
        //"linkedin": this.addOfficeForm.value.linkedin,
        //"twitter": this.addOfficeForm.value.twitter,
        //"instagram": this.addOfficeForm.value.instagram,
        //"youtube": this.addOfficeForm.value.youtube,
        "google_map_url": this.addOfficeForm.value.google_map_url,
        "firm_logo": this.img,
        "firm_video": this.addOfficeForm.value.firm_video,
        "firm_video_desc": this.addOfficeForm.value.firm_video_desc,
    }
    this.dataService.spinnerShow();
    this.dataService.addOfficeStep1(this.data).subscribe(
      (response: HttpResponse<any>) => {
        if (response.body.status_code === 0) {
          const step2 = {
            "id": response.body.data[0].id,
            "firm_practice_description": this.addOfficeForm.value.firm_practice_description,
            "areas_of_practice": '',
          }
          this.dataService.addOfficeStep2(step2).subscribe(
            (response2: HttpResponse<any>) => {
              this.dataService.spinnerHide();
              if (response2.body.status_code === 0) {
                this.toastrService.success(response.body.message);
              } else if (response2.body.status_code === 1) {
                this.toastrService.error(response.body.message);
              } else {
                this.toastrService.error(response.body.message);
              }
              this.router.navigate([`view-office-collegues`])
            }, error => {
              this.dataService.spinnerHide();
              this.toastrService.error(error.error['message']);
              this.router.navigate([`view-office-collegues`])
            }
          );

        } else if (response.body.status_code === 1) {
          this.dataService.spinnerHide();
          this.toastrService.error(response.body.message);
        } else {
          this.dataService.spinnerHide();
          this.toastrService.error(response.body.message);
        }
      }, error => {
        this.dataService.spinnerHide();
        this.toastrService.error(error.error['message']);
      }
    );
  }

  addAffilationItem() {
    const control = new FormControl(null);
    (<FormArray>this.addOfficeForm.get('firm_affiliation_networks')).push(control);

  }

  onCountryChange(event) {
    this.dataService.spinnerShow();
    this.states = null;
    this.dataService
      .getStates(this.addOfficeForm.value.country_id)
      .subscribe((response: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.states = response.body.data;
      });
  }

  onStateChange(event) {
    this.dataService.spinnerShow();
    this.cities = null;
    this.dataService
      .getCities(this.addOfficeForm.value.state_id)
      .subscribe((response: HttpResponse<any>) => {
        this.dataService.spinnerHide();
        this.cities = response.body.data;
      });
  }

    changeListener($event): void {
        this.readThis($event.target);
    }



    readThis(inputValue: any): void {
        var file: File = inputValue.files[0];
        var myReader: FileReader = new FileReader();
        myReader.onloadend = (e) => {
            Object.assign(this.addOfficeForm.value, {
                firm_logo: myReader.result
            })
            this.img = myReader.result;

        }
        myReader.readAsDataURL(file);
    }
}


