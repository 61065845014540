import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthServices } from '../services/auth.service';
import { HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { Meta, Title } from '@angular/platform-browser';
declare var hljs: any;

import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  LinkedInLoginProvider
} from 'angularx-social-login';

@Component({
  selector: 'lawyer-signin-user',
  templateUrl: './signin-user.component.html',
  styleUrls: ['./signin-user.component.css']
})
export class SigninUserComponent implements OnInit {
    public readonly siteKey = '6LfGxPEUAAAAAAHTrNk8UsRtdrGVzRY6AAIgKrNW';
  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';
  public useGlobalDomain: boolean = false;

  @ViewChild('captchaElem', { static: false }) captchaElem: any;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;

  signinForm: FormGroup;
  private socialUser: SocialUser;
  private loggedIn: boolean;
  public href: string = "";
  code: string;
  state: string;
  socialLoginOnPageLoadFlag = false;
  constructor(
    private authService: AuthServices,
    private tostrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private socialLoginService: AuthService, private title: Title, private meta: Meta
  ) { }

  ngOnInit() {
    console.log(window.location.host)

    let code = this.route.snapshot.queryParams['code']
    // let state = this.route.snapshot.queryParams['state']
    if (code) {
      console.log("code---", code);
      this.getAccessToken(code)
    }
    // console.log(state);

    this.title.setTitle("Jurislex - User SignIn");
    this.meta.updateTag({ name: 'description', content: ' This page is used for customer login.' });
    this.meta.updateTag({ name: 'keywords', content: 'Jurislex, Lawyer, Office, Collegue, Member, Search Lawyer, Top Lawyer, Lawyer Website, Lawyer Portal' });


    this.createForm();
    // localStorage.clear();
    localStorage.removeItem('websiteCurrentUser');
    localStorage.removeItem('websiteToken');
    this.socialLoginService.authState.subscribe((user: any) => {

      this.socialUser = user;
      if (user && this.socialLoginOnPageLoadFlag) {
        this.socialLogin(user);
      }
    });
  }

  getAccessToken(code) {
    let grantType = "authorization_code";
    // let redirectURL = "http://40.121.65.234:83/signin-user";
     let redirectURL = "https://jurislex.org/signin-user";
     //let redirectURL = "http://localhost:4200/signin-user";
    let body = new URLSearchParams();
    body.set('client_id', '789f7f14ahoa8m');
    body.set('client_secret', '07EhBhBmc1gvHX0i');
    body.set('code', code);
    body.set('grant_type', grantType);
    body.set('redirect_uri', redirectURL);

    this.authService.linkedInAuth(body).subscribe(
      (response: HttpResponse<any>) => {
        console.log("Acces-token response---> ", response);

        if (response.status == 200) {
          let access_key = response.body.access_token;
          this.getLinkedInProfile(access_key);
        }
        if (response.status == 400) {
          this.tostrService.error("Something went wrong! ");
        }

      },
      error => {
        console.log("error in getting access token--->", error)
        this.tostrService.error("Something went wrong! ");
      }
    );

  }


  getLinkedInProfile(key) {

    this.authService.getLinkedInProfile(key).subscribe(
      (response: HttpResponse<any>) => {
        console.log("profile---> ", response);
        if (response.status == 200 && response.body.status != 'failed') {
          // localStorage.clear();
          localStorage.removeItem('websiteCurrentUser');
          localStorage.removeItem('websiteToken');
          localStorage.setItem('websiteToken', response.body.data.api_token);
          localStorage.setItem('websiteCurrentUser', JSON.stringify(response.body));
          this.tostrService.success('You have successfully Logged In');
          this.router.navigate(['/lawyer-search']);
        }
        else {
          this.tostrService.error(response.body.message);
        }


      },
      error => {
        console.log("error in profile--->", error)
        this.tostrService.error("Something went wrong! ");
      }
    );
  }


  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
  }

  handleSuccess(captchaResponse: string): void {
    this.captchaSuccess = true;
    this.captchaResponse = captchaResponse;
    this.captchaIsExpired = false;
  }

  handleLoad(): void {
    this.captchaIsLoaded = true;
    this.captchaIsExpired = false;
  }

  handleExpire(): void {
    this.captchaSuccess = false;
    this.captchaIsExpired = true;
  }

  changeTheme(theme: 'light' | 'dark'): void {
    this.theme = theme;
  }

  changeSize(size: 'compact' | 'normal'): void {
    this.size = size;
  }

  changeType(type: 'image' | 'audio'): void {
    this.type = type;
  }

  setLanguage(): void {
    this.lang = this.langInput.nativeElement.value;
  }

  setUseGlobalDomain(use: boolean): void {
    this.useGlobalDomain = use;
  }

  getCurrentResponse(): void {
    const currentResponse = this.captchaElem.getCurrentResponse();
    if (!currentResponse) {
      alert('There is no current response - have you submitted captcha?');
    } else {
      alert(currentResponse);
    }
  }
  ngAfterViewInit(): void {
    this.highlight();
  }

  getResponse(): void {
    const response = this.captchaElem.getResponse();
    if (!response) {
      alert('There is no response - have you submitted captcha?');
    } else {
      alert(response);
    }
  }

  reload(): void {
    this.captchaElem.reloadCaptcha();
  }

  getCaptchaId(): void {
    alert(this.captchaElem.getCaptchaId());
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }

  private highlight(): void {
    const highlightBlocks = document.getElementsByTagName('code');
    for (let i = 0; i < highlightBlocks.length; i++) {
      const block = highlightBlocks[i];
      hljs.highlightBlock(block);
    }
  }

  createForm() {
    this.signinForm = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(6)
      ]),
       recaptcha: new FormControl('', [Validators.required]),

    });
  }
  onLogin() {
    const email = this.signinForm.value.email;
    const password = this.signinForm.value.password;
    console.log(email, password);
    if (this.signinForm.valid) {
      console.log(email, password);
      this.authService.loginUser(email, password).subscribe(
        (response: HttpResponse<any>) => {
          console.log(response);
          if (response.body.status_code == 0) {
            // localStorage.clear();
            
            localStorage.removeItem('websiteCurrentUser');
            localStorage.removeItem('websiteToken');
            console.log("B");
            localStorage.setItem('websiteToken', response.body.data.api_token);
            localStorage.setItem('websiteCurrentUser', JSON.stringify(response.body));
            // this.tostrService.success('You have successfully Logged In', 'Successful',
            // { timeOut: 200000, positionClass: 'toast-top-center', closeButton: true, toastClass: 'toast' })
            this.tostrService.success('You have successfully Logged In');
            this.router.navigate(['/lawyer-search']);
          } else if (response.body.status_code == 1) {
            this.tostrService.error(response.body.message);
          }
        },
        error => {

          if (error.status == 422) {
            this.tostrService.error(error.error.errors.email);
          } else {
            this.tostrService.error(error.error.error);
          }
        }
      );
    }
  }

  signInWithFB(event): void {
    event.stopPropagation();
    event.preventDefault();
    this.socialLoginOnPageLoadFlag = true;
    this.socialLoginService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  signInWithLinkedIn(event): void {
    event.stopPropagation();
    event.preventDefault();
    // window.location.href="https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=789f7f14ahoa8m&redirect_uri=http://localhost:4200/signin-user&state=sfooobar&scope=r_liteprofile%20r_emailaddress%20w_member_social"
    // window.location.href = "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=789f7f14ahoa8m&redirect_uri=http://40.121.65.234:83/signin-user&state=sfooobar&scope=r_liteprofile%20r_emailaddress%20w_member_social"
    window.location.href = "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=789f7f14ahoa8m&redirect_uri=https://jurislex.org/signin-user&state=sfooobar&scope=r_liteprofile%20r_emailaddress%20w_member_social"

    // event.stopPropagation();
    // event.preventDefault();
    // this.socialLoginOnPageLoadFlag = true;
    // console.log("1")
    // // console.log(LinkedInLoginProvider.PROVIDER_ID);
    // this.socialLoginService.signIn(LinkedInLoginProvider.PROVIDER_ID.toLowerCase());
    // console.log("2")
  }


  socialLogin(user: SocialUser) {
    console.log("-------....uuiii")
    const data = {
      _token: 'base64:VdVI6iQ51l9qsN0sc1Yhc0uQC4/IRDcHUte6B/Fp3fY=',
      social_provider: user.provider,
      social_provider_id: user.id,
      email: user.email,
      name: user.name
    };
    console.log("Social response---", user)
    console.log("Payload---", data)
    this.authService.socialLogin(data).subscribe(
      (response: HttpResponse<any>) => {
        console.log("Bakend API response", response)
        if (response.status == 200 && response.body.status != 'failed') {
          // localStorage.clear();
          localStorage.removeItem('websiteCurrentUser');
          localStorage.removeItem('websiteToken');
          console.log("SocialData--2", response)
          localStorage.setItem('websiteToken', response.body.data.api_token);
          localStorage.setItem('websiteCurrentUser', JSON.stringify(response.body));
          // this.tostrService.success('You have successfully Logged In', 'Successful',
          // { timeOut: 200000, positionClass: 'toast-top-center', closeButton: true, toastClass: 'toast' })
          this.tostrService.success('You have successfully Logged In');
          this.router.navigate(['/lawyer-search']);
        }
        else {
          this.tostrService.error(response.body.message);
        }
      },
      error => {

        if (error.status == 422) {
          this.tostrService.error(error.error.errors.email);
        }
      }
    );
  }

  signOut(): void {
    this.socialLoginService.signOut();
  }
}
